import qz from "qz-tray";
import { Dialog, Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TimerModal from "../HeadlineTimer/TimerModal";
import { useLocation } from "react-router-dom";
import { redirect } from "../../utils/helpers";

function ModalSuccess({ idOperation, totalAmount }) {
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const [date, setDate] = useState("");
  const [printerStatus, setPrinterStatus] = useState(undefined);

  useEffect(() => {
    const fechaActual = new Date();

    const formatoFecha = new Intl.DateTimeFormat("es-ES", {
      weekday: "long",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });

    const fechaFormateada = formatoFecha.format(fechaActual);
    setDate(fechaFormateada);
  }, []);

  useEffect(() => {
    qz.printers.setPrinterCallbacks((evt) => {
      console.log(evt.statusText);
      setPrinterStatus(evt.statusText);
    });
  });

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <Box bgcolor="success.light" textAlign="center" color="white">
        <Stack
          margin={3}
          marginTop={10}
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <CheckCircleOutlineTwoToneIcon sx={{ fontSize: "10rem" }} />
          <Typography fontSize="1.8rem">¡La compra fue un éxito!</Typography>
        </Stack>
      </Box>
      <Box textAlign="center">
        <Stack direction="column" margin={3} spacing={3} alignItems="center">
          <Stack direction="column" spacing={2}>
            <Typography fontSize="1.3rem">
              ¡Te invitamos a disfrutar de la función!
            </Typography>
            <Stack direction="column">
              <Typography fontSize="1.3rem" fontWeight="bold">
                {`Numero de operación: ${idOperation}`}
              </Typography>
              <Typography fontSize="1.3rem">
                {`Su compra se realizó: ${date}.`}
              </Typography>
            </Stack>
            <Typography fontSize="1.3rem" color="success.light">
              {`  Total de la compra: $${totalAmount}`}
            </Typography>
          </Stack>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            marginTop={2}
            sx={{
              padding: "1rem",
              width: "70%",
              fontSize: "1.3rem",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            onClick={() => {
              redirect(
                datosComplejo.ip_privada,
                datosComplejo.version_boleteria,
                device_id
              );
            }}
            loading={printerStatus === "OK" ? false : true}
          >
            VOLVER AL INICIO
          </LoadingButton>
        </Stack>
        <TimerModal time={15} />
      </Box>
    </Dialog>
  );
}

export default ModalSuccess;
