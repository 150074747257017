import {
  AGREGAR_BUTACA,
  AGREGAR_CANDY_CARRITO,
  AGREGAR_CODIGO_VENTA,
  AGREGAR_DATA_USUARIO,
  AGREGAR_TARIFAS_CARRITO,
  BORRAR_DATA_USUARIO,
  CARRITO_CLEAR_BUTACAS,
  CARRITO_LOCK_BTN_CONTINUE,
  CARRITO_UPDATE_SUBTOTAL,
  ELIMINAR_BUTACA,
  ELIMINAR_CANDY_CARRITO,
  ELIMINAR_CODIGO_VENTA,
  ELIMINAR_TARIFAS_CARRITO,
  LIMPIAR_CARRITO,
  RESET_REDUX_STATE,
  SET_ERROR_WS,
  SET_NEXT_PAGE,
  SET_ONLY_CANDY,
  UNSET_ONLY_CANDY,
} from "../actionTypes/actionTypes";

const initialState = {
  dataUsuario: {},
  tarifas: [],
  candy: [],
  codigosCanjeadosVenta: [],
  descuentoVenta: 0,
  subtotal: 0,
  lockBtnContinue: false,
  errorWs: false,
  nextPage: "",
  onlyCandy: false,
};

const carritoReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGREGAR_TARIFAS_CARRITO:
      let tarifaAgregar = action.payload.tarifa;
      let precioAgregar = Number(action.payload.tarifa.precio);
      return {
        ...state,
        tarifas: [...state.tarifas, tarifaAgregar],
        subtotal: state.subtotal + precioAgregar,
      };
    case ELIMINAR_TARIFAS_CARRITO:
      let tarifaEliminar = state.tarifas.find(
        (tarifa) => tarifa.idCarrito === action.payload.idTarifa
      );
      let nuevoCarritoTarifas = state.tarifas.filter(
        (tarifa) => tarifa.idCarrito !== action.payload.idTarifa
      );
      let cantidad = action.payload.cantidad;
      if (cantidad > 1) {
        const tarifaEliminarDoble = nuevoCarritoTarifas.find(
          (item) => item.idKey === tarifaEliminar.idKey
        );
        nuevoCarritoTarifas = nuevoCarritoTarifas.filter(
          (item) => item.idCarrito !== tarifaEliminarDoble.idCarrito
        );
      }
      return {
        ...state,
        tarifas: nuevoCarritoTarifas,
        subtotal: state.subtotal - tarifaEliminar.precio * cantidad,
      };
    case AGREGAR_BUTACA:
      const but = action.payload.butaca;
      const butacaId = Number(but.tipo_butaca_id);
      if (state.tarifas.length > 0) {
        let result = state.tarifas.filter((item) => {
          if (item.id) {
            return Number(item.id) === butacaId && item.butaca === "";
          } else {
            return item.butaca === "";
          }
        });
        let nuevaTarifa = {};
        if (result !== "") {
          nuevaTarifa = { ...result[0], butaca: but };
        }
        let nuevoCarritoButacas = state.tarifas.filter(
          (item) => item.idCarrito !== nuevaTarifa.idCarrito
        );
        return {
          ...state,
          tarifas: nuevoCarritoButacas.concat(nuevaTarifa),
        };
      }
      return {
        ...state,
      };
    case ELIMINAR_BUTACA:
      const butEliminar = action.payload.butaca;
      if (state.tarifas.length > 0) {
        let tarifaEliminarButaca = state.tarifas.find((item) => {
          return item.butaca === butEliminar;
        });
        let tarifaSinButaca = { ...tarifaEliminarButaca, butaca: "" };
        let nuevoCarritoButacasEliminar = state.tarifas.filter(
          (item) => item !== tarifaEliminarButaca
        );
        return {
          ...state,
          tarifas: nuevoCarritoButacasEliminar.concat(tarifaSinButaca),
        };
      }
      return {
        ...state,
      };
    case AGREGAR_CANDY_CARRITO:
      let itemAgregar = action.payload.item;
      let precioItem = Number(itemAgregar.subTotal);
      return {
        ...state,
        candy: [...state.candy, itemAgregar],
        subtotal: state.subtotal + precioItem,
      };
    case ELIMINAR_CANDY_CARRITO:
      let itemEliminar = action.payload.item;
      let precioItemEliminar = Number(itemEliminar.subTotal);
      let nuevoCarritoCandy = state.candy.filter(
        (item) => item.idCarrito !== itemEliminar.idCarrito
      );
      return {
        ...state,
        candy: nuevoCarritoCandy,
        subtotal: state.subtotal - precioItemEliminar,
      };
    case AGREGAR_DATA_USUARIO:
      let data = action.payload.data;
      return {
        ...state,
        dataUsuario: data,
      };
    case BORRAR_DATA_USUARIO:
      return {
        ...state,
        dataUsuario: [],
      };
    case AGREGAR_CODIGO_VENTA:
      return {
        ...state,
        codigosCanjeadosVenta: state.codigosCanjeadosVenta.concat(
          action.payload.data
        ),
        subtotal: state.subtotal - action.payload.data.precioDescuento,
        descuentoVenta: action.payload.data.precioDescuento,
      };
    case ELIMINAR_CODIGO_VENTA:
      let newCodigosCanjeados = state.codigosCanjeadosVenta.filter(
        (cod) => cod.id !== action.payload.data.id
      );
      return {
        ...state,
        codigosCanjeadosVenta: newCodigosCanjeados,
        subtotal: state.subtotal + action.payload.data.precioDescuento,
        descuentoVenta:
          state.descuentoVenta - action.payload.data.precioDescuento,
      };
    case SET_NEXT_PAGE:
      return {
        ...state,
        nextPage: action.payload.nextPage,
      };
    case LIMPIAR_CARRITO:
      return {
        dataUsuario: {},
        tarifas: [],
        candy: [],
        codigosCanjeadosVenta: [],
        subtotal: 0,
        nextPage: "",
      };
    case CARRITO_LOCK_BTN_CONTINUE:
      return {
        ...state,
        lockBtnContinue: action.payload.bool,
      };
    case SET_ONLY_CANDY:
      return {
        ...state,
        onlyCandy: true,
      };
    case UNSET_ONLY_CANDY:
      return {
        ...state,
        onlyCandy: false,
      };
    case SET_ERROR_WS:
      return {
        ...state,
        errorWs: true,
      };
    case CARRITO_CLEAR_BUTACAS:
      return {
        ...state,
        tarifas: state.tarifas.map((t) => {
          t.butaca = {};
          return t;
        }),
      };
    case RESET_REDUX_STATE:
      return initialState;
    case CARRITO_UPDATE_SUBTOTAL:
      return {
        ...state,
        subtotal: action.payload.subtotal,
      };
    default:
      return state;
  }
};

export default carritoReducer;
