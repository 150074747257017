import { Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  agregarProdComboDinamico,
  setItemProdsComboDinamico,
  setObligatoriasComboDinamico,
} from "../../../../actions/candyActions";
import ProductoCard from "./ProductoCard";

function SelectComboDinamico({ productos, setSeleccionProdCombo }) {
  const dispatch = useDispatch();
  const [productosSelec, setProductosSelec] = useState([]);
  const [countTotal, setCountTotal] = useState(0);

  return (
    <Stack
      direction="column"
      marginTop={2}
      spacing={1}
      alignItems="center"
      overflow="auto"
    >
      <Button
        id="btn-confirmar-productos"
        disabled={countTotal < 3 ? true : false}
        variant="contained"
        color="secondary"
        type="submit"
        size="small"
        sx={{
          padding: "0.5rem",
          fontSize: "1.2rem",
          fontWeight: "700",
          width: "35%",
        }}
        onClick={() => {
          dispatch(agregarProdComboDinamico(productosSelec));
          dispatch(setItemProdsComboDinamico(productosSelec));
          dispatch(setObligatoriasComboDinamico(productosSelec));
          setSeleccionProdCombo(false);
        }}
      >
        Confirmar Productos
      </Button>

      <Stack direction="column" spacing={0.5} marginTop={2} maxHeight="80%">
        {productos &&
          productos?.map((producto) => {
            return (
              <ProductoCard
                key={producto.id}
                producto={producto}
                productosSelec={productosSelec}
                setProductosSelec={setProductosSelec}
                countTotal={countTotal}
                setCountTotal={setCountTotal}
              />
            );
          })}
      </Stack>
    </Stack>
  );
}

export default SelectComboDinamico;
