import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";

function ItemCarrito({ bgColor = "white" }) {
  const item = useSelector((state) => state.DatosCandy.item);
  const subTotal = useSelector((state) => state.DatosCandy.subtotalModal);
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          padding: "0.8rem",
          marginY: "0.25rem",
          border: "1px solid #dee2e6",
          bgcolor: bgColor,
        }}
      >
        <Grid item xs={9}>
          <Typography
            fontSize="1rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            {item.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <>
            {datosComplejo.tasaConversion ? (
              <Typography
                fontSize="1rem"
                textTransform="uppercase"
                fontWeight="700"
              >
                Bs. {subTotal}
              </Typography>
            ) : (
              <Typography
                fontSize="1rem"
                textTransform="uppercase"
                fontWeight="700"
              >
                ${subTotal}
              </Typography>
            )}
          </>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ItemCarrito;
