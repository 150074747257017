import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CandyContainer from "../Components/Candy/CandyContainer";
import Carrito from "../Components/Carrito/Carrito";
import PeliculaSimple from "../Components/Entradas/Pelicula/PeliculaSimple";
import Headline from "../Components/HeadlineTimer/Headline";
import { useEffect, useState } from "react";
import { cargarCandy } from "../actions/candyActions";
import { useLocation } from "react-router-dom";
import useCurrentWindow from "../hooks/useCurrentWindow";
import apihost from "../config/apiHost.json";

function Candy() {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useCurrentWindow();
  const titleHeading = "Elige tu combo";
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const datosCarrito = useSelector((state) => state.DatosCarrito.candy);
  const onlyCandy = useSelector((state) => state.DatosCarrito.onlyCandy);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPage, setNextPage] = useState(`${location.search}`);
  const [isVertical, setIsVertical] = useState(false);
  const [disabledContinue, setDisabledContinue] = useState(false);
  const datosPelicula = useSelector((state) => state.DatosPelicula.datos);

  useEffect(() => {
    if (isLoading) {
      dispatch(cargarCandy(apihost.REACT_APP_URL_CINEXO, datosFuncion.cine_id));
      setIsLoading(false);
    }
  }, [dispatch, isLoading, datosFuncion]);

  useEffect(() => {
    if (onlyCandy) {
      if (datosCarrito.length > 0) {
        setDisabledContinue(false);
        setNextPage(`/payment-methods${location.search}`);
      } else {
        setDisabledContinue(true);
      }
    } else {
      if (datosCarrito.length > 0) {
        setNextPage(`/payment-methods${location.search}`);
      } else {
        setNextPage(`/payment-methods${location.search}`);
      }
    }
  }, [datosCarrito, location, onlyCandy]);

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  return isLoading ? (
    ""
  ) : (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "10px", md: "5px" },
      }}
    >
      <Grid container spacing="2rem">
        <Grid
          item
          xs={isVertical ? 12 : 8}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <PeliculaSimple />
          <Headline title={titleHeading} />
          <CandyContainer />
        </Grid>
        <Grid
          item
          xs={isVertical ? 12 : 4}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <Carrito
            nextPage={nextPage}
            isVertical={isVertical}
            disabledCancel={false}
            disabledContinue={disabledContinue}
            step={"candy"}
            to={
              onlyCandy
                ? `/${location.search}`
                : datosPelicula.pelicula.vendeNumeradas === "0"
                ? `/tarifas${location.search}`
                : `/butacas${location.search}`
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Candy;
