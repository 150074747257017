import React from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  Stack,
  Typography,
  InputLabel,
} from "@mui/material";
import { useSelector } from "react-redux";

function SelectCandy({ label, data, estado, setEstado }) {
  const handleChange = (evt) => {
    const value = evt.target.value;
    setEstado(value);
  };
  const imgEmpresa = useSelector(
    (state) => state.DatosComplejo.datosComplejo.logo
  );

  const handleImageError = (e) => {
    e.target.src = imgEmpresa;
  };

  return (
    <Box minWidth="200px" maxWidth="250px">
      <FormControl fullWidth>
        <InputLabel
          id={`candySelectLabel${label}`}
          sx={{
            fontSize: "1.4rem",
          }}
        >
          {label}
        </InputLabel>
        <Select
          value={estado}
          id={`candySelect${label}`}
          variant="outlined"
          labelId={`candySelectLabel${label}`}
          label={label}
          onChange={handleChange}
          displayEmpty
          sx={{
            fontSize: "1.4rem",
          }}
        >
          {label === "Categoría" && (
            <MenuItem value="TODOS">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  padding={0}
                  display="flex"
                  alignItems="center"
                  justifyItems="center"
                >
                  <Box
                    component="img"
                    alt="logo empresa" //PONER GENERICA
                    src={imgEmpresa}
                    sx={{
                      height: "3rem",
                      width: "5rem",
                      margin: 0,
                      padding: 0,
                      objectFit: "scale-down",
                    }}
                    onError={handleImageError}
                  />
                </Box>
                <Typography fontSize="1.4rem">TODOS</Typography>
              </Stack>
            </MenuItem>
          )}
          {data &&
            data?.map((item) => {
              return (
                <MenuItem
                  id={item.id}
                  value={
                    label === "Empresa" ? item.nombre_empresa : item.nombre
                  }
                  key={item.id}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                      padding={0}
                      display="flex"
                      alignItems="center"
                      justifyItems="center"
                    >
                      <Box
                        component="img"
                        alt={item.nombre_empresa} //PONER GENERICA
                        src={item.logo ? item.logo : imgEmpresa}
                        sx={{
                          height: "3rem",
                          width: "5rem",
                          margin: 0,
                          padding: 0,
                          objectFit: "scale-down",
                        }}
                        onError={handleImageError}
                      />
                    </Box>
                    <Typography fontSize="1.4rem">
                      {label === "Empresa" ? item.nombre_empresa : item.nombre}
                    </Typography>
                  </Stack>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectCandy;
