import {
  AGREGAR_CANDY_CARRITO,
  ELIMINAR_CANDY_CARRITO,
  AGREGAR_PROMOCION_CANDY,
  LIMPIAR_PROMOCIONES,
  CARGAR_CANDY,
  CANDY_MODAL_INCREMENTAR_ITEM,
  CANDY_MODAL_DISMINUIR_ITEM,
  CANDY__MODAL_RESET_PREFERENCIAS,
  CANDY_MODAL_INIT_CARRITO,
  CANDY_MODAL_ADD_ITEM_CARRITO,
  CANDY_MODAL_REMOVER_ITEM_CARRITO,
  CANDY_MODAL_ADD_PREFERENCIA_CARRITO,
  CANDY_MODAL_REMOVER_PREFERENCIA_CARRITO,
  CANDY_MODAL_LIMPIAR_PREFERENCIAS,
  CANDY_MODAL_RESET_COUNT_ITEM,
  CANDY_MODAL_INCREMENTAR_SUBTOTAL,
  CANDY_MODAL_SET_SUBTOTAL,
  CANDY_MODAL_DISMINUIR_SUBTOTAL,
  CANDY_MODAL_RESET_SUBTOTAL,
  CANDY_MODAL_LOCK_SELECT_ITEM,
  CANDY_MODAL_SET_ITEM_DATA,
  CANDY_MODAL_RESET_LOCK_ITEM,
  CANDY_MODAL_INCREMENTAR_SUMATORIA_OBLIGATORIAS,
  CANDY_MODAL_DISMINUIR_SUMATORIA_OBLIGATORIAS,
  CANDY_MODAL_RESET_OBLIGATORIAS,
  AGREGAR_CODIGO,
  AGREGAR_PROD_COMBO_DINAMICO,
  CANDY_MODAL_SET_ITEM_PRODS_COMBO_DINAMICO,
  CANDY_MODAL_SET_OBLIGATORIAS_COMBO_DINAMICO,
  CANDY_MODAL_CLEAR_PROD_COMBO_DINAMICO,
  AGREGAR_CATEGORIAS,
  BORRAR_CATEGORIAS,
  CANDY_SET_UNIDAD_NEGOCIO,
  CANDY_MODAL_ADD_PROMOTION_TO_ITEM,
  CANDY_MODAL_RESET_PROMOCIONES,
  CLEAR_ITEM_DATA,
  RESET_REDUX_STATE_CANDY,
  SET_ERROR_WS,
} from "../actionTypes/actionTypes";

const cargarCandy = (url, cine) => {
  return async function (dispatch) {
    await fetch(`${url}complejo/${cine}/candy`)
      .then((response) => response.json())
      .then((respuesta) => {
        let unidades = [];
        if (respuesta.tiene_unidades_negocios) {
          unidades = respuesta.unidades_negocios.map((u) => {
            return {
              business_unit_code: u.id,
              total: 0,
              items: [],
            };
          });
        }
        return dispatch({
          type: CARGAR_CANDY,
          payload: {
            candy: respuesta,
            unidades,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        return dispatch({ type: SET_ERROR_WS });
      });
  };
};

const setUnidadNegocio = (unidad) => {
  return {
    type: CANDY_SET_UNIDAD_NEGOCIO,
    payload: {
      unidad,
    },
  };
};

const incrementItem = () => {
  return {
    type: CANDY_MODAL_INCREMENTAR_ITEM,
  };
};

const decrementItem = () => {
  return {
    type: CANDY_MODAL_DISMINUIR_ITEM,
  };
};

const resetPreferencias = (bool) => {
  return {
    type: CANDY__MODAL_RESET_PREFERENCIAS,
    payload: {
      bool,
    },
  };
};

const setItemProdsComboDinamico = (productos) => {
  let products = [];
  for (let i = 0; i < productos.length; i++) {
    if (productos[i].cantidad > 1) {
      for (let j = 0; j < productos[i].cantidad; j++) {
        products.push({
          id: `${productos[i].id}-${j}`,
          code: productos[i].id,
          name: productos[i].nombre,
          quantity: Number(productos[i].cantidad),
          preferences: [],
          selected: 0,
          producto: productos[i],
        });
      }
    } else {
      products.push({
        id: `${productos[i].id}`,
        code: productos[i].id,
        name: productos[i].nombre,
        quantity: Number(productos[i].cantidad),
        preferences: [],
        selected: 0,
        producto: productos[i],
      });
    }
  }
  return {
    type: CANDY_MODAL_SET_ITEM_PRODS_COMBO_DINAMICO,
    payload: {
      products,
    },
  };
};

const initCarritoModal = (data, prodComboDinamico) => {
  let products = [];
  if (prodComboDinamico && prodComboDinamico.length > 0) {
    products = prodComboDinamico;
  } else {
    for (let i = 0; i < data.productos.length; i++) {
      if (data.productos[i].cantidad > 1) {
        for (let j = 0; j < data.productos[i].cantidad; j++) {
          products.push({
            id: `${data.productos[i].id}-${j}`,
            code: data.productos[i].id,
            name: data.productos[i].nombre,
            quantity: 1,
            preferences: [],
            selected: 0,
            producto: data.productos[i],
          });
        }
      } else {
        products.push({
          id: `${data.productos[i].id}`,
          code: data.productos[i].id,
          name: data.productos[i].nombre,
          quantity: Number(data.productos[i].cantidad),
          preferences: [],
          selected: 0,
          producto: data.productos[i],
        });
      }
    }
  }

  let item = {
    code: data.id,
    name: data.nombre,
    price: data.precio,
    quantity: 0,
    type: data.tipo,
    products,
    promotion: [],
    tax: data.tax ? data.tax : 0,
  };
  return {
    type: CANDY_MODAL_INIT_CARRITO,
    payload: {
      item,
    },
  };
};

const addItemCarritoModal = (data, counter) => {
  let item = {
    code: data.code,
    name: data.name,
    price: data.price,
    quantity: Number(data.quantity) + 1,
    type: data.type,
    products: data.products,
    promotion: data.promotion,
    tax: data.tax,
  };
  return {
    type: CANDY_MODAL_ADD_ITEM_CARRITO,
    payload: { item },
  };
};

const agregarCandyCarritoGeneral = (item, subTotal, unidad) => {
  let productos = item.products.map((prod) => {
    return {
      code: prod.code,
      name: prod.name,
      preferences: prod.preferences,
      quantity: prod.quantity,
    };
  });

  return {
    type: AGREGAR_CANDY_CARRITO,
    payload: {
      item: {
        unidad,
        code: item.code,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        type: item.type,
        tax: item.tax,
        promotion: item.promotion,
        products: productos,
        subTotal,
        idCarrito: Math.random().toString(30).substring(2),
      },
    },
  };
};

const removerItemCarritoModal = (data, counter) => {
  var quantity = 0;
  if (data.quantity === 0) {
    quantity = 0;
  } else {
    quantity = data.quantity - 1;
  }
  let item = {
    code: data.code,
    name: data.name,
    price: data.price,
    quantity: quantity,
    type: data.type,
    promotion: data.promotion,
    products: data.products,
  };
  return {
    type: CANDY_MODAL_REMOVER_ITEM_CARRITO,
    payload: {
      item,
    },
  };
};

const eliminarCandyCarritoGeneral = (item) => {
  return {
    type: ELIMINAR_CANDY_CARRITO,
    payload: {
      item,
    },
  };
};

const addPref = (preference) => {
  let pref = {
    key: preference.key,
    key_product: preference.key_product,
    name: preference.preferencia_nombre,
    id: preference.preferencia_id,
    product_id: preference.producto_id,
    quantity: null,
    increment: preference.incremento,
  };
  return {
    type: CANDY_MODAL_ADD_PREFERENCIA_CARRITO,
    payload: {
      pref,
    },
  };
};

const removePref = (preference) => {
  return {
    type: CANDY_MODAL_REMOVER_PREFERENCIA_CARRITO,
    payload: {
      id: preference.preferencia_id,
      product_id: preference.producto_id,
    },
  };
};

const clearPref = () => {
  return {
    type: CANDY_MODAL_LIMPIAR_PREFERENCIAS,
  };
};

const resetCountItem = () => {
  return {
    type: CANDY_MODAL_RESET_COUNT_ITEM,
  };
};

const addSubtotal = (monto) => {
  monto = Number(monto);
  return {
    type: CANDY_MODAL_INCREMENTAR_SUBTOTAL,
    payload: {
      monto,
    },
  };
};

const setSubtotalCandyModal = (monto) => {
  monto = Number(monto);
  return {
    type: CANDY_MODAL_SET_SUBTOTAL,
    payload: {
      monto,
    },
  };
};

const decrementSubtotalCandyModal = (monto) => {
  monto = Number(monto);
  return {
    type: CANDY_MODAL_DISMINUIR_SUBTOTAL,
    payload: {
      monto,
    },
  };
};

const resetSubtotalCandyModal = () => {
  return {
    type: CANDY_MODAL_RESET_SUBTOTAL,
  };
};

const lockSelectItem = () => {
  return {
    type: CANDY_MODAL_LOCK_SELECT_ITEM,
  };
};

const setObligatoriasComboDinamico = (productos) => {
  let preferenciasObligatorias = 0;
  for (let i = 0; i < productos.length; i++) {
    if (productos[i].preferencias && productos[i].preferencias.length > 0) {
      for (let j = 0; j < productos[i].preferencias.length; j++) {
        if (productos[i].preferencias[j].obligatorio === "1") {
          preferenciasObligatorias = preferenciasObligatorias + 1;
          break;
        }
      }
    }
  }
  return {
    type: CANDY_MODAL_SET_OBLIGATORIAS_COMBO_DINAMICO,
    payload: {
      preferenciasObligatorias,
    },
  };
};

const setItemData = (item) => {
  let productos = [];
  for (let i = 0; i < item.productos.length; i++) {
    if (item.productos[i].cantidad > 1) {
      for (let j = 0; j < item.productos[i].cantidad; j++) {
        let preferencias = [];
        if (
          item.productos[i].preferencias &&
          item.productos[i].preferencias.length > 0
        ) {
          for (let k = 0; k < item.productos[i].preferencias.length; k++) {
            let pref = {
              key: `${item.productos[i].preferencias[k].preferencia_id}`,
              key_product: `${item.productos[i].id}`,
              preferencia_id: `${item.productos[i].preferencias[k].preferencia_id}-${k}`,
              preferencia_nombre:
                item.productos[i].preferencias[k].preferencia_nombre,
              producto_id: `${item.productos[i].id}-${j}`,
              cantidad: 1,
              incremento: item.productos[i].preferencias[k].incremento,
              obligatorio: item.productos[i].preferencias[k].obligatorio,
            };
            preferencias.push(pref);
          }
        } else {
          preferencias = item.productos[i].preferencias;
        }
        productos.push({
          id: `${item.productos[i].id}-${j}`,
          code: item.productos[i].id,
          nombre: item.productos[i].nombre,
          cantidad: item.productos[i].cantidad,
          preferencias,
        });
      }
    } else {
      productos.push({
        id: `${item.productos[i].id}`,
        code: item.productos[i].id,
        nombre: item.productos[i].nombre,
        preferencias: item.productos[i].preferencias,
        cantidad: item.productos[i].cantidad,
      });
    }
  }
  let data = {
    code: item.id,
    name: item.nombre,
    price: item.precio,
    quantity: 0,
    type: item.tipo,
    productos,
  };
  let preferenciasObligatorias = 0;
  for (let i = 0; i < data.productos.length; i++) {
    if (
      data.productos[i].preferencias &&
      data.productos[i].preferencias.length > 0
    ) {
      for (let j = 0; j < data.productos[i].preferencias.length; j++) {
        if (data.productos[i].preferencias[j].obligatorio === "1") {
          preferenciasObligatorias = preferenciasObligatorias + 1;
          break;
        }
      }
    }
  }
  return {
    type: CANDY_MODAL_SET_ITEM_DATA,
    payload: {
      data,
      preferenciasObligatorias,
    },
  };
};

const resetLockItem = () => {
  return {
    type: CANDY_MODAL_RESET_LOCK_ITEM,
  };
};

const incrementarObligatorias = () => {
  return {
    type: CANDY_MODAL_INCREMENTAR_SUMATORIA_OBLIGATORIAS,
  };
};

const disminuirObligatorias = () => {
  return {
    type: CANDY_MODAL_DISMINUIR_SUMATORIA_OBLIGATORIAS,
  };
};

const resetObligatorias = () => {
  return {
    type: CANDY_MODAL_RESET_OBLIGATORIAS,
  };
};

const agregarPromocion = (promocion) => {
  return {
    type: AGREGAR_PROMOCION_CANDY,
    payload: {
      promocion,
      idPromocion: Math.random().toString(30).substring(2),
    },
  };
};

const limpiarPromociones = () => {
  return {
    type: LIMPIAR_PROMOCIONES,
  };
};

const agregarCodigo = (codigo) => {
  return {
    type: AGREGAR_CODIGO,
    payload: {
      codigo,
    },
  };
};

const agregarProdComboDinamico = (productos) => {
  return {
    type: AGREGAR_PROD_COMBO_DINAMICO,
    payload: {
      productos,
    },
  };
};

const clearComboDinamicoProds = () => {
  return {
    type: CANDY_MODAL_CLEAR_PROD_COMBO_DINAMICO,
  };
};

const agregarCategorias = (nombre, id) => {
  return {
    type: AGREGAR_CATEGORIAS,
    payload: {
      nombre,
      id,
    },
  };
};

const borrarCategorias = () => {
  return {
    type: BORRAR_CATEGORIAS,
  };
};

const addPromotionToItem = (data) => {
  return {
    type: CANDY_MODAL_ADD_PROMOTION_TO_ITEM,
    payload: {
      data,
    },
  };
};
const resetPromocionesCandyModal = (bool) => {
  return {
    type: CANDY_MODAL_RESET_PROMOCIONES,
    payload: {
      bool,
    },
  };
};

const clearItemData = () => {
  return {
    type: CLEAR_ITEM_DATA,
  };
};

const candyResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE_CANDY,
  };
};

const addItemUnidadNegocio = (item, unidad) => {};

export {
  cargarCandy,
  incrementItem,
  decrementItem,
  resetPreferencias,
  initCarritoModal,
  addItemCarritoModal,
  removerItemCarritoModal,
  addPref,
  removePref,
  clearPref,
  resetCountItem,
  agregarCandyCarritoGeneral,
  eliminarCandyCarritoGeneral,
  addSubtotal,
  setSubtotalCandyModal,
  decrementSubtotalCandyModal,
  resetSubtotalCandyModal,
  lockSelectItem,
  setItemData,
  resetLockItem,
  incrementarObligatorias,
  disminuirObligatorias,
  resetObligatorias,
  agregarPromocion,
  limpiarPromociones,
  agregarCodigo,
  agregarProdComboDinamico,
  setItemProdsComboDinamico,
  setObligatoriasComboDinamico,
  clearComboDinamicoProds,
  agregarCategorias,
  borrarCategorias,
  setUnidadNegocio,
  addItemUnidadNegocio,
  addPromotionToItem,
  resetPromocionesCandyModal,
  clearItemData,
  candyResetReduxState,
};
