export const banksData = [
  {
    name: "Banco Central de Venezuela",
    code: "001",
  },
  {
    name: "Banco de Venezuela, S.A. Banco Universal",
    code: "102",
  },
  {
    name: "Banco Venezolano de Crédito, S.A. Banco Universal",
    code: "104",
  },
  {
    name: "Banco Mercantil C.A., Banco Universal",
    code: "105",
  },
  {
    name: "Banco Provincial, S.A. Banco Universal",
    code: "108",
  },
  {
    name: "Banco del Caribe C.A., Banco Universal",
    code: "114",
  },
  {
    name: "Banco Exterior C.A., Banco Universal",
    code: "115",
  },
  {
    name: "Banco Caroní C.A., Banco Universal",
    code: "128",
  },
  {
    name: "Banesco Banco Universal, C.A.",
    code: "134",
  },
  {
    name: "Banco Sofitasa Banco Universal, C.A.",
    code: "137",
  },
  {
    name: "Banco Plaza, Banco Universal",
    code: "138",
  },
  {
    name: "Banco de la Gente Emprendedora C.A.",
    code: "146",
  },
  {
    name: "Banco Fondo Común, C.A. Banco Universal",
    code: "151",
  },
  {
    name: "100% Banco, Banco Comercial, C.A.",
    code: "156",
  },
  {
    name: "DelSur, Banco Universal C.A.",
    code: "157",
  },
  {
    name: "Banco del Tesoro C.A., Banco Universal",
    code: "163",
  },
  {
    name: "Banco Agrícola de Venezuela C.A., Banco Universal",
    code: "166",
  },
  {
    name: "Bancrecer S.A., Banco Microfinanciero",
    code: "168",
  },
  {
    name: "Mi Banco, Banco Microfinanciero, C.A.",
    code: "169",
  },
  {
    name: "Banco Activo C.A., Banco Universal",
    code: "171",
  },
  {
    name: "Bancamiga Banco Universal, C.A.",
    code: "172",
  },
  {
    name: "Banco Internacional de Desarrollo C.A., Banco Universal",
    code: "173",
  },
  {
    name: "Banplus Banco Universidad, C.A.",
    code: "174",
  },
  {
    name: "Banco Bicentenario del Pueblo, Banco Universal C.A.",
    code: "175",
  },
  {
    name: "Banco de la Fuerza Armada Nacional Bolivariana, B.U.",
    code: "177",
  },
  {
    name: "Banco Nacional de Crédito C.A., Banco Universal",
    code: "191",
  },
  {
    name: "Instituto Municipal de Crédito Popular",
    code: "601",
  },
];
