import { Button } from "@mui/material";

export default function NumpadButton({ children, handleClick }) {
  return (
    <Button
      variant="contained"
      fullWidth
      color="secondary"
      sx={{ fontSize: "2rem" }}
      disableElevation
      onClick={() => handleClick(children)}
    >
      {children}
    </Button>
  );
}
