import { Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { TimerContext } from "../HeadlineTimer/Timer";
import { useLocation } from "react-router-dom";

function TimerCanje({ title }) {
  const location = useLocation();
  const { contador, redirectToHome } = useContext(TimerContext);

  const handleContador = () => {
    if (location.pathname !== "/payment") {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="40%"
        >
          <Typography
            bgcolor="red"
            paddingY="0.3rem"
            paddingX="1.5rem"
            color="white"
            fontSize="1.6rem"
            fontWeight="700"
            borderRadius={1}
          >
            {Math.floor(contador / 60)}:
            {contador % 60 < 10 ? `0${contador % 60}` : contador % 60}
          </Typography>
        </Stack>
      );
    }
  };
  useEffect(() => {
    const minutos = Math.floor(contador / 60);
    const segundos = contador % 60;
    if (minutos === 0 && segundos === 0) {
      console.log("El temporizador ha llegado a cero");
      redirectToHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contador]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="cards.gray"
      borderRadius={1}
    >
      <Typography fontSize={12} textAlign="right" lineHeight="1.3rem">
        Finalizado el tiempo se retornará a la vista principal
      </Typography>
      {handleContador()}
    </Stack>
  );
}

export default TimerCanje;
