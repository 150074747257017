import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function PreferenciasCarrito({ bgColor = "white" }) {
  const item = useSelector((state) => state.DatosCandy.item);

  const renderizarPreferencias = (item) => {
    if (item.products && item.products.length > 0) {
      const preferencias = item.products.map((prod) => {
        return prod.preferences.map((pref) => pref);
      });
      return preferencias.map((pref) => {
        return pref.map((p) => {
          return (
            <Grid
              key={p.id}
              container
              alignItems="center"
              sx={{
                padding: "0.5rem",
                marginY: "0.25rem",
                border: "1px solid #dee2e6",
                bgcolor: bgColor,
              }}
            >
              <Grid item xs={8}>
                <Typography fontSize="1rem">{p.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  fontSize="1rem"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  Unidades: {p.quantity}
                </Typography>
              </Grid>
            </Grid>
          );
        });
      });
    }
  };

  return renderizarPreferencias(item);
}

export default PreferenciasCarrito;
