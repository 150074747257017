import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { agregarButaca, eliminarButaca } from "../../../actions/butacasActions";
import Butaca from "./Butaca";
import {
  crearSalaBase,
  crearSalaCine,
  obtenerButacasSeleccionadasPorTipo,
  obtenerCantidadEntradasPorTipo,
} from "./utils";

function Sala({
  butacas,
  filas,
  columnas,
  butacasSeleccionadas,
  setButacasSeleccionadas,
  tiposDeEntradas,
  tipoFila,
  tipoColumna,
}) {
  const dispatch = useDispatch();
  const [salaBase, setSalaBase] = useState([]);
  const [salaCine, setSalaCine] = useState([]);

  useEffect(() => {
    if (filas !== 0 && columnas !== 0) {
      crearSalaBase(filas, tipoFila, columnas, tipoColumna, setSalaBase);
    }
  }, [filas, tipoFila, columnas, tipoColumna]);

  useEffect(() => {
    if (salaBase !== [] && butacas) {
      crearSalaCine(salaBase, tipoColumna, butacas, setSalaCine);
    }
  }, [salaBase, butacas, tipoColumna]);

  const obtenerEstadoDeButaca = (butaca, tiposDeEntradas) => {
    if (!butaca.pasillo) {
      if (butacasSeleccionadas?.includes(butaca)) {
        return "seleccionada";
      } else if (Number(butaca.libre) === 1) {
        if (
          tiposDeEntradas?.includes(Number(butaca.tipo_butaca_id)) ||
          tiposDeEntradas?.includes("no-id")
        ) {
          return "libre";
        } else {
          return "no disponible";
        }
      } else if (Number(butaca.libre) === 0) {
        return "ocupada";
      }
    }
  };

  // SELECCION DE BUTACAS
  const handleSeleccionarButaca = (butaca, tiposDeEntradas, estado) => {
    if (
      estado === "libre" &&
      tiposDeEntradas.includes(Number(butaca.tipo_butaca_id))
    ) {
      if (
        obtenerButacasSeleccionadasPorTipo(
          Number(butaca.tipo_butaca_id),
          butacasSeleccionadas
        ) <
        obtenerCantidadEntradasPorTipo(
          Number(butaca.tipo_butaca_id),
          tiposDeEntradas
        )
      ) {
        setButacasSeleccionadas((state) => [...state, butaca]);
        dispatch(agregarButaca(butaca));
      }
    } else if (estado === "libre" && tiposDeEntradas?.includes("no-id")) {
      if (
        obtenerButacasSeleccionadasPorTipo("no-id", butacasSeleccionadas) <
        obtenerCantidadEntradasPorTipo("no-id", tiposDeEntradas)
      ) {
        setButacasSeleccionadas((state) => [...state, butaca]);
        dispatch(agregarButaca(butaca));
      }
    } else if (estado === "seleccionada") {
      setButacasSeleccionadas((state) => state.filter((but) => but !== butaca));
      dispatch(eliminarButaca(butaca));
    }
  };

  return (
    <Grid
      container
      columns={Number(columnas)}
      spacing="0.3rem"
      alignContent="center"
    >
      {butacas &&
        salaCine.map((butaca) => {
          return (
            <Butaca
              butaca={butaca}
              estadoButaca={obtenerEstadoDeButaca(butaca, tiposDeEntradas)}
              handleSeleccionarButaca={handleSeleccionarButaca}
              tiposDeEntradas={tiposDeEntradas}
              key={butaca.butaca}
            />
          );
        })}
    </Grid>
  );
}

export default Sala;
