import {
  BORRAR_DATOS_FUNCION,
  CARGAR_DATOS_FUNCION,
  RESET_REDUX_STATE,
} from "../actionTypes/actionTypes";

const initialState = {
  cine_id: "",
  funcion_id: "",
};

const funcionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARGAR_DATOS_FUNCION:
      return {
        ...state,
        cine_id: action.payload.cine,
        funcion_id: action.payload.funcion,
      };
    case BORRAR_DATOS_FUNCION:
      return {
        ...state,
        cine_id: "",
        funcion_id: "",
      };
    case RESET_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
};

export default funcionReducer;
