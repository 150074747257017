import { configureStore } from "@reduxjs/toolkit";
import butacaReducer from "../reducers/butacaReducer";
import candyReducer from "../reducers/candyReducer";
import carritoReducer from "../reducers/carritoReducer";
import funcionReducer from "../reducers/funcionReducer";
import paymentReducer from "../reducers/paymentReducer";
import peliculaReducer from "../reducers/peliculaReducer";
import tarifaReducer from "../reducers/tarifaReducer";
import complejoReducer from "../reducers/complejoReducer";

export const store = configureStore({
  reducer: {
    DatosFuncion: funcionReducer,
    DatosComplejo: complejoReducer,
    DatosPelicula: peliculaReducer,
    DatosTarifa: tarifaReducer,
    DatosButaca: butacaReducer,
    DatosCandy: candyReducer,
    DatosCarrito: carritoReducer,
    DatosPayment: paymentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
