export const CARGAR_DATOS_FUNCION = "CARGAR_DATOS_FUNCION";
export const BORRAR_DATOS_FUNCION = "BORRAR_DATOS_FUNCION";
export const CARGAR_DATOS_PELICULA = "CARGAR_DATOS_PELICULA";
export const CARGAR_TARIFAS = "CARGAR_TARIFAS";
export const CARGAR_PRODUCTOS = "CARGAR_PRODUCTOS";
export const AGREGAR_TARIFAS = "AGREGAR_TARIFAS";
export const AGREGAR_TARIFAS_CARRITO = "AGREGAR_TARIFAS_CARRITO";
export const AGREGAR_PRODUCTOS = "AGREGAR_PRODUCTOS";
export const AGREGAR_CANDY_CARRITO = "AGREGAR_CANDY_CARRITO";
export const ELIMINAR_CANDY_CARRITO = "ELIMINAR_CANDY_CARRITO";
export const ELIMINAR_TARIFAS = "ELIMINAR_TARIFAS";
export const ELIMINAR_TARIFAS_CARRITO = "ELIMINAR_TARIFAS_CARRITO";
export const LIMPIAR_CARRITO = "LIMPIAR_CARRITO";
export const ELIMINAR_PRODUCTOS = "ELIMINAR_PRODUCTOS";
export const ACTUALIZAR_TOTAL_ENTRADAS = "ACTUALIZAR_TOTAL_ENTRADAS";
export const ACTUALIZAR_TOTAL_PRODUCTOS = "ACTUALIZAR_TOTAL_PRODUCTOS";
export const CARGAR_BUTACAS = "CARGAR_BUTACAS";
export const AGREGAR_BUTACA = "AGREGAR_BUTACA";
export const ELIMINAR_BUTACA = "ELIMINAR_BUTACA";
export const CARGAR_CANDY = "CARGAR_CANDY";
export const CANDY_MODAL_INCREMENTAR_ITEM = "CANDY_MODAL_INCREMENTAR_ITEM";
export const CANDY_MODAL_DISMINUIR_ITEM = "CANDY_MODAL_DISMINUIR_ITEM";
export const CANDY__MODAL_RESET_PREFERENCIAS =
  "CANDY__MODAL_RESET_PREFERENCIAS";
export const CANDY_MODAL_INIT_CARRITO = "CANDY_MODAL_INIT_CARRITO";
export const CANDY_MODAL_ADD_ITEM_CARRITO = "CANDY_MODAL_ADD_ITEM_CARRITO";
export const CANDY_MODAL_REMOVER_ITEM_CARRITO =
  "CANDY_MODAL_REMOVER_ITEM_CARRITO";
export const CANDY_MODAL_ADD_PREFERENCIA_CARRITO =
  "CANDY_MODAL_ADD_PREFERENCIA_CARRITO";
export const CANDY_MODAL_REMOVER_PREFERENCIA_CARRITO =
  "CANDY_MODAL_REMOVER_PREFERENCIA_CARRITO";
export const CANDY_MODAL_LIMPIAR_PREFERENCIAS =
  "CANDY_MODAL_LIMPIAR_PREFERENCIAS";
export const CANDY_MODAL_RESET_COUNT_ITEM = "CANDY_MODAL_RESET_COUNT_ITEM";
export const CANDY_MODAL_INCREMENTAR_SUBTOTAL =
  "CANDY_MODAL_INCREMENTAR_SUBTOTAL";
export const CANDY_MODAL_SET_SUBTOTAL = "CANDY_MODAL_SET_SUBTOTAL";
export const CANDY_MODAL_DISMINUIR_SUBTOTAL = "CANDY_MODAL_DISMINUIR_SUBTOTAL";
export const CANDY_MODAL_RESET_SUBTOTAL = "CANDY_MODAL_RESET_SUBTOTAL";
export const CANDY_MODAL_LOCK_SELECT_ITEM = "CANDY_MODAL_LOCK_SELECT_ITEM";
export const CANDY_MODAL_SET_ITEM_DATA = "CANDY_MODAL_SET_ITEM_DATA";
export const CANDY_MODAL_RESET_LOCK_ITEM = "CANDY_MODAL_RESET_LOCK_ITEM";
export const CANDY_MODAL_INCREMENTAR_SUMATORIA_OBLIGATORIAS =
  "CANDY_MODAL_INCREMENTAR_SUMATORIA_OBLIGATORIAS";
export const CANDY_MODAL_DISMINUIR_SUMATORIA_OBLIGATORIAS =
  "CANDY_DISMINUIR_SUMATORIA_OBLIGATORIAS";
export const CANDY_RESET_OBLIGATORIAS = "CANDY_RESET_OBLIGATORIAS";
export const AGREGAR_PROMOCION_CANDY = "AGREGAR_PROMOCION_CANDY";
export const LIMPIAR_PROMOCIONES = "LIMPIAR_PROMOCIONES";
export const CANDY_MODAL_RESET_OBLIGATORIAS = "CANDY_RESET_OBLIGATORIAS";
export const AGREGAR_CODIGO = "AGREGAR_CODIGO";
export const AGREGAR_PROD_COMBO_DINAMICO = "AGREGAR_PROD_COMBO_DINAMICO";
export const CANDY_MODAL_SET_ITEM_PRODS_COMBO_DINAMICO =
  "CANDY_MODAL_SET_ITEM_PRODS_COMBO_DINAMICO";

export const CANDY_MODAL_SET_OBLIGATORIAS_COMBO_DINAMICO =
  "CANDY_MODAL_SET_OBLIGATORIAS_COMBO_DINAMICO";
export const AGREGAR_CODIGO_TARIFAS = "AGREGAR_CODIGO_TARIFAS";
export const CANDY_MODAL_CLEAR_PROD_COMBO_DINAMICO =
  "CANDY_MODAL_CLEAR_PROD_COMBO_DINAMICO";
export const SET_NEXT_PAGE = "SET_NEXT_PAGE";
export const AGREGAR_CATEGORIAS = "AGREGAR_CATEGORIAS";
export const BORRAR_CATEGORIAS = "BORRAR_CATEGORIAS";
export const AGREGAR_DATA_USUARIO = "AGREGAR_DATA_USUARIO";
export const BORRAR_DATA_USUARIO = "BORRAR_DATA_USUARIO";
export const CANDY_SET_UNIDAD_NEGOCIO = "CANDY_SET_UNIDAD_NEGOCIO";
export const CANDY_ADD_ITEM_UNIDAD_NEGOCIO = "CANDY_ADD_ITEM_UNIDAD_NEGOCIO";
export const CARRITO_LOCK_BTN_CONTINUE = "CARRITO_LOCK_BTN_CONTINUE";
export const PAYMENT_SET_DATA_TO_SEND = "PAYMENT_SET_DATA_TO_SEND";
export const PAYMENT_POINT_OR_QR_POST = "PAYMENT_POINT_OR_QR_POST";
export const PAYMENT_SET_METHOD = "PAYMENT_SET_METHOD";
export const AGREGAR_CODIGO_VENTA = "AGREGAR_CODIGO_VENTA";
export const ELIMINAR_CODIGO_VENTA = "ELIMINAR_CODIGO_VENTA";
export const PAYMENT_GENERAR_INTENCION_DE_PAGO =
  "PAYMENT_GENERAR_INTENCION_DE_PAGO";
export const PAYMENT_GET_STATUS_PAYMENT_INTENT =
  "PAYMENT_GET_STATUS_PAYMENT_INTENT";
export const CANDY_MODAL_ADD_PROMOTION_TO_ITEM =
  "CANDY_MODAL_ADD_PROMOTION_TO_ITEM";
export const CANDY_MODAL_RESET_PROMOCIONES = "CANDY_MODAL_RESET_PROMOCIONES";
export const STATUS_PAYMENT = "STATUS_PAYMENT";
export const CARGAR_INFO_PELICULA = "CARGAR_INFO_PELICULA";
export const PAYMENT_SET_QR_DATA = "PAYMENT_SET_QR_DATA";
export const CLEAR_ITEM_DATA = "CLEAR_ITEM_DATA";
export const RESET_REDUX_STATE = "RESET_REDUX_STATE";
export const RESET_REDUX_STATE_CANDY = "RESET_REDUX_STATE_CANDY";
export const SET_ERROR_WS = "SET_ERROR_WS";
export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const PAYMENT_POINT_RETRY = "PAYMENT_POINT_RETRY";
export const RESET_PAYMENT_ID = "RESET_PAYMENT_ID";
export const PAYMENT_INCREMENT_RETRY = "PAYMENT_INCREMENT_RETRY";
export const RESET_QR_DATA = "RESET_QR_DATA";
export const PAYMENT_RETRY_QR = "PAYMENT_RETRY_QR";
export const CARGAR_CONFIGURACION_COMPLEJO = "CARGAR_CONFIGURACION_COMPLEJO";
export const CARGAR_DATOS_EMPRESA = "CARGAR_DATOS_EMPRESA";
export const PAYMENT_CANCEL_INTENTION = "PAYMENT_CANCEL_INTENTION";
export const SET_ONLY_CANDY = "SET_ONLY_CANDY";
export const UNSET_ONLY_CANDY = "UNSET_ONLY_CANDY";
export const CARRITO_CLEAR_BUTACAS = "CARRITO_CLEAR_BUTACAS";
export const PAYMENT_ERROR_VENTAS_TEMPORALES =
  "PAYMENT_ERROR_VENTAS_TEMPORALES";
export const TARIFAS_LIMPIAR_CUPONES = "TARIFAS_LIMPIAR_CUPONES";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_DALBE_ERROR = "PAYMENT_DALBE_ERROR";
export const CARRITO_UPDATE_SUBTOTAL = "CARRITO_UPDATE_SUBTOTAL";
export const PAYMENT_SET_RESPONSE_VT = "PAYMENT_SET_RESPONSE_VT";
export const SET_STATUS_SETP3 = "SET_STATUS_SETP3";
