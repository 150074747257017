import {
  AGREGAR_CODIGO_VENTA,
  AGREGAR_DATA_USUARIO,
  BORRAR_DATA_USUARIO,
  ELIMINAR_CODIGO_VENTA,
  RESET_REDUX_STATE,
} from "../actionTypes/actionTypes";

const agregarDataUsuario = (data) => {
  return {
    type: AGREGAR_DATA_USUARIO,
    payload: {
      data,
    },
  };
};

const borrarDataUsuario = () => {
  return {
    type: BORRAR_DATA_USUARIO,
  };
};

const agregarCodigoCanjeadoVenta = (data) => {
  return {
    type: AGREGAR_CODIGO_VENTA,
    payload: {
      data,
    },
  };
};

const eliminarCodigoCanjeadoVenta = (data) => {
  return {
    type: ELIMINAR_CODIGO_VENTA,
    payload: {
      data,
    },
  };
};

const fidelizacionResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

export {
  agregarDataUsuario,
  borrarDataUsuario,
  agregarCodigoCanjeadoVenta,
  eliminarCodigoCanjeadoVenta,
  fidelizacionResetReduxState,
};
