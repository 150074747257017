import {
  CARGAR_CONFIGURACION_COMPLEJO,
  CARGAR_DATOS_EMPRESA,
  RESET_REDUX_STATE,
} from "../actionTypes/actionTypes";
import axios from "axios";
import apihost from "../config/apiHost.json";

const cargarConfiguracionComplejo = (complejo_id) => {
  return async function (dispatch) {
    const response = await axios.get(
      `${apihost.REACT_APP_URL_CINEXO}complejo/${complejo_id}/configuration`
    );
    return dispatch({
      type: CARGAR_CONFIGURACION_COMPLEJO,
      payload: {
        url_api_complejo: response.data.datos.ip_api_complejo,
        url_api_front: response.data.datos.url,
        payment_gateways: response.data.datos.payment_gateways,
        datosComplejo: response.data,
      },
    });
  };
};

const cargarDatosEmpresa = (idComplejo, deviceId) => {
  return async function (dispatch) {
    const response = await axios.get(
      `${apihost.REACT_APP_URL_CINEXO}complejo/${idComplejo}/obtenerDatosATM/${deviceId}`
    );
    return dispatch({
      type: CARGAR_DATOS_EMPRESA,
      payload: response.data.datos,
    });
  };
};

const complejoResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

export {
  cargarConfiguracionComplejo,
  cargarDatosEmpresa,
  complejoResetReduxState,
};
