import { Typography, Button, ButtonGroup, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPref,
  addSubtotal,
  decrementSubtotalCandyModal,
  disminuirObligatorias,
  incrementarObligatorias,
  removePref,
} from "../../../../actions/candyActions";
import { setColorByIndex } from "../../../../utils/helpers";

function SelectorCard({
  nombre,
  index,
  selected,
  setSelected,
  limit,
  setLimit,
  preferencia,
  bgColor = "white",
}) {
  const [count, setCount] = useState(0);
  const reset = useSelector((state) => state.DatosCandy.resetPreferencias);
  const counter = useSelector((state) => state.DatosCandy.countItem);
  const lock = useSelector((state) => state.DatosCandy.lockSelectItem);
  const [locked, setLocked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!lock) {
      setLocked(false);
    }
  }, [lock]);

  useEffect(() => {
    if (counter === 0) {
      setLimit(0);
    } else {
      setLimit(preferencia.cantidad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    if (reset) {
      setCount(0);
      setSelected(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, reset]);

  const handleActive = () => {
    if (locked) {
      return true;
    }
    if (lock) {
      if (preferencia.obligatorio === "1" && selected < limit) {
        return false;
      } else {
        if (preferencia.obligatorio === "0") {
          return false;
        }
        return true;
      }
    } else {
      return true;
    }
  };

  const lockRemove = () => {
    if (lock) {
      if (count === 0) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const handleAdd = () => {
    if (preferencia.obligatorio === "1") {
      setCount(count + 1);
      setSelected(selected + 1);
      dispatch(addPref(preferencia));
      if (preferencia.incremento > 0) {
        dispatch(addSubtotal(preferencia.incremento));
      }
      dispatch(incrementarObligatorias());
    } else {
      setCount(count + 1);
      dispatch(addPref(preferencia));
      if (preferencia.incremento > 0) {
        dispatch(addSubtotal(preferencia.incremento));
      }
      setLocked(true);
    }
  };
  const handleRemove = () => {
    if (preferencia.obligatorio === "1") {
      setCount(count - 1);
      setSelected(selected - 1);
      dispatch(removePref(preferencia));
      if (preferencia.incremento > 0) {
        dispatch(decrementSubtotalCandyModal(preferencia.incremento));
      }
      dispatch(disminuirObligatorias());
    } else {
      setCount(count - 1);
      dispatch(removePref(preferencia));
      if (preferencia.incremento > 0) {
        dispatch(decrementSubtotalCandyModal(preferencia.incremento));
      }
      setLocked(false);
    }
  };

  const handleIncrement = (preferencia) => {
    if (preferencia.incremento > 0) {
      return `($${preferencia.incremento})`;
    } else {
      return;
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      minWidth={false}
      margin={0}
      sx={{
        paddingY: "0.8rem",
        paddingX: "1rem",
        border: "1px solid #dee2e6",
        bgcolor: setColorByIndex(index),
      }}
    >
      <Typography fontSize="1rem" textTransform="uppercase" fontWeight="700">
        {nombre} {handleIncrement(preferencia)}
      </Typography>
      <ButtonGroup>
        <Button
          disabled={lockRemove()}
          variant="contained"
          color="secondary"
          border="1px transparent"
          size="small"
          margin={0}
          disableElevation
          sx={{ padding: 0, width: "auto" }}
          onClick={handleRemove}
        >
          <Typography
            fontSize="1.6rem"
            fontWeight="500"
            textTransform="uppercase"
            paddingX="-1rem"
          >
            -
          </Typography>
        </Button>
        <Stack
          component="span"
          borderRadius={0}
          border="1px solid #ced4da"
          width="3rem"
          bgcolor="white"
        >
          <Typography fontSize="1rem" textAlign="center" margin="auto">
            {count}
          </Typography>
        </Stack>
        <Button
          id={`add-${preferencia.preferencia_id}`}
          disabled={handleActive()}
          variant="contained"
          color="secondary"
          border="1px transparent"
          size="small"
          margin={0}
          width="0.25rem"
          disableElevation
          sx={{ padding: 0, width: "auto" }}
          onClick={handleAdd}
        >
          <Typography
            fontSize="1.6rem"
            fontWeight="500"
            textTransform="uppercase"
          >
            +
          </Typography>
        </Button>
      </ButtonGroup>
    </Stack>
  );
}

export default SelectorCard;
