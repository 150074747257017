import { Box, Button, Stack, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TarifaCarritoCard from "./TarifaCarritoCard";
import InfoIcon from "@mui/icons-material/Info";
import {
  eliminarTarifasCarrito,
  limpiarCarrito,
  limpiarCupones,
} from "../../actions/tarifasAction";
import { useContext, useEffect, useState } from "react";
import CandyCarritoCard from "./CandyCarritoCard";
import useCurrentWindow from "../../hooks/useCurrentWindow";
import PromocionCarritoCard from "./PromocionCarritoCard";
import { clearAllReduxState } from "../../utils/resetReduxState";
import ModalErrorWs from "../ModalErrorWs";
import { cancelPayment } from "../../actions/paymentActions";
import { TimerContext } from "../HeadlineTimer/Timer";
import { redirect } from "../../utils/helpers";
import { eliminarButaca } from "../../actions/butacasActions";
import { lockBtnContinue } from "../../actions/carritoActions";

function Carrito({
  nextPage,
  isVertical,
  disabledCancel,
  disabledContinue,
  step,
  to,
}) {
  const state = useSelector((state) => state.DatosCarrito);
  const deviceId = useSelector((state) => state.DatosPayment.deviceId);
  const complexCode = useSelector((state) => state.DatosFuncion.cine_id);
  const paymentID = useSelector((state) => state.DatosPayment.paymentID);
  const { resetContador } = useContext(TimerContext);
  const datosCarrito = useSelector((state) => state.DatosCarrito.tarifas);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const windowSize = useCurrentWindow();
  const [productos, setProductos] = useState([]);
  const lockedBtnContinue = useSelector(
    (state) => state.DatosCarrito.lockBtnContinue
  );
  const errorWs = useSelector((state) => state.DatosCarrito.errorWs);
  const [descuentos, setDescuentos] = useState([]);

  const limpiarTarifas = (event) => {
    if (datosCarrito && datosCarrito.length > 0) {
      for (let i = 0; i < datosCarrito.length; i++) {
        if (datosCarrito[i].idCarrito !== "") {
          dispatch(eliminarTarifasCarrito(datosCarrito[i].idCarrito, 1));
        }
      }
    }
  };

  const limpiarButacas = (event) => {
    if (datosCarrito && datosCarrito.length > 0) {
      for (let i = 0; i < datosCarrito.length; i++) {
        if (datosCarrito[i].butaca !== "") {
          dispatch(eliminarButaca(datosCarrito[i].butaca));
        }
      }
    }
  };

  const handleCancelPayment = () => {
    if (paymentID) {
      dispatch(cancelPayment(deviceId, paymentID, complexCode));
    }
  };

  useEffect(() => {
    setProductos(state.candy);
    setDescuentos(state.codigosCanjeadosVenta);
  }, [state]);

  return (
    <Box
      bgcolor="cards.gray"
      borderRadius="4px"
      id="test"
      padding={2}
      maxHeight={isVertical ? "30vh" : "90%"}
      sx={
        isVertical
          ? {
              position: "fixed",
              bottom: 0,
              left: "10px",
              right: "10px",
            }
          : null
      }
    >
      <Stack direction="column" height="inherit">
        <Typography
          variant="h2"
          marginBottom="15px"
          fontSize="1.5rem"
          fontWeight="500"
        >
          Carrito de compra
        </Typography>

        <Stack
          direction="column"
          overflow="auto"
          maxHeight={isVertical ? "10vh" : "40vh"}
        >
          <Grid container gap="1rem">
            {state.tarifas?.length > 0
              ? state.tarifas.map((tarifa) => {
                  return (
                    <Grid item xs={12} key={tarifa.idCarrito}>
                      <TarifaCarritoCard tarifa={tarifa} />
                    </Grid>
                  );
                })
              : ""}
          </Grid>

          <Grid container gap="1rem" mt="1rem">
            {productos?.length > 0
              ? productos.map((producto) => {
                  return (
                    <Grid item xs={12} key={producto.idCarrito}>
                      <CandyCarritoCard producto={producto} />
                    </Grid>
                  );
                })
              : ""}
          </Grid>

          <Grid container gap="1rem" mt="1rem">
            {descuentos?.length > 0
              ? descuentos.map((descuento) => {
                  return (
                    <Grid item xs={12} key={descuento.id}>
                      <PromocionCarritoCard descuento={descuento} />
                    </Grid>
                  );
                })
              : ""}
          </Grid>
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing={1}
          marginTop="1rem"
          marginBottom="3rem"
        >
          <InfoIcon color="success" />
          <Typography fontSize="1.2rem">
            {state.onlyCandy
              ? "Por favor, antes de continuar, verifique la cantidad de productos seleccionados"
              : "Por favor, antes de continuar, verifique la cantidad de entradas seleccionadas"}
          </Typography>
        </Stack>

        <Box
          bgcolor="primary.main"
          padding="1.5rem"
          borderRadius="0.3rem"
          marginBottom="1.5rem"
        >
          <Stack direction="column">
            <Typography
              fontSize="1.1rem"
              textTransform="uppercase"
              fontWeight="700"
              color="black"
              padding={0}
              margin={0}
            >
              TU COMPRA
            </Typography>
            {state.subtotal === null ? (
              <>
                {datosComplejo.tasaConversion ? (
                  <Typography
                    id="subtotal-carrito"
                    fontSize="2.8rem"
                    textTransform="uppercase"
                    fontWeight="700"
                    color="white"
                    padding={0}
                    margin={0}
                  >
                    Bs. 0
                  </Typography>
                ) : (
                  <Typography
                    id="subtotal-carrito"
                    fontSize="2.8rem"
                    textTransform="uppercase"
                    fontWeight="700"
                    color="white"
                    padding={0}
                    margin={0}
                  >
                    $0
                  </Typography>
                )}
              </>
            ) : (
              <>
                {datosComplejo.tasaConversion && state.subtotal > 0 ? (
                  <Typography
                    id="subtotal-carrito"
                    fontSize="2.8rem"
                    textTransform="uppercase"
                    fontWeight="700"
                    color="white"
                    padding={0}
                    margin={0}
                  >
                    Bs.{" "}
                    {Number.isInteger(state.subtotal)
                      ? Number(state.subtotal).toFixed(2)
                      : Number(state.subtotal).toFixed(2)}
                  </Typography>
                ) : (
                  <>
                    {datosComplejo.tasaConversion ? (
                      <Typography
                        id="subtotal-carrito"
                        fontSize="2.8rem"
                        textTransform="uppercase"
                        fontWeight="700"
                        color="white"
                        padding={0}
                        margin={0}
                      >
                        Bs.{" "}
                        {Number.isInteger(state.subtotal)
                          ? Number(state.subtotal).toFixed(2)
                          : Number(state.subtotal).toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography
                        id="subtotal-carrito"
                        fontSize="2.8rem"
                        textTransform="uppercase"
                        fontWeight="700"
                        color="white"
                        padding={0}
                        margin={0}
                      >
                        ${" "}
                        {Number.isInteger(state.subtotal)
                          ? Number(state.subtotal).toFixed(2)
                          : Number(state.subtotal).toFixed(2)}
                      </Typography>
                    )}
                  </>
                )}

                {datosComplejo.tasaConversion && state.subtotal > 0 ? (
                  <Typography
                    id="subtotal-carrito"
                    fontSize="2.8rem"
                    textTransform="uppercase"
                    fontWeight="700"
                    color="white"
                    padding={0}
                    margin={0}
                  >
                    REF{" "}
                    {Number.isInteger(state.subtotal)
                      ? Number(
                          state.subtotal / datosComplejo.tasaConversion
                        ).toFixed(2)
                      : Number(
                          state.subtotal / datosComplejo.tasaConversion
                        ).toFixed(2)}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            )}
            <Typography
              fontSize="1.1rem"
              fontWeight="500"
              color="white"
              padding={0}
              margin={0}
            >
              Precio total de la selección momentánea, esta no incluye gastos de
              plataforma.
            </Typography>
          </Stack>
        </Box>
        <Stack
          direction={
            windowSize.width < windowSize.height ? "row-reverse" : "column"
          }
          spacing={1}
        >
          <Button
            disabled={lockedBtnContinue || disabledContinue}
            id="btn-continue-cart"
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            fullWidth
            sx={{
              padding: "1.5rem",
              fontSize: "1.4rem",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            onClick={() => {
              navigate(nextPage);
            }}
          >
            Continuar
          </Button>
          {step ? (
            <Link
              to={to}
              onClick={() => {
                dispatch(lockBtnContinue(false));
                if (step === "butacas") {
                  limpiarTarifas();
                  dispatch(limpiarCupones());
                  limpiarButacas();
                } else if (step === "tarifas") {
                  limpiarTarifas();
                  dispatch(limpiarCupones());
                }
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth
                sx={{
                  padding: "1.5rem",
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                Atras
              </Button>
            </Link>
          ) : (
            ""
          )}

          <Button
            variant="outlined"
            color="secondary"
            size="large"
            fullWidth
            sx={{
              padding: "1.5rem",
              fontSize: "1.4rem",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            disabled={
              disabledCancel || lockedBtnContinue || !datosComplejo?.ip_privada
            }
            onClick={() => {
              handleCancelPayment();
              redirect(
                datosComplejo.ip_privada,
                datosComplejo.version_boleteria,
                device_id
              );
              dispatch(limpiarCarrito());
              clearAllReduxState(dispatch);
              resetContador();
            }}
          >
            Cancelar
          </Button>
        </Stack>
      </Stack>
      {errorWs ? <ModalErrorWs /> : ""}
    </Box>
  );
}

export default Carrito;
