import { useState, useEffect } from "react";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export default function useCurrentWindow() {
  let [windowSize, setWindowSize] = useState({
    width: getWidth(),
    height: getHeight(),
  });

  useEffect(() => {
    const resizeListener = () => {
      setWindowSize({
        width: getWidth(),
        height: getHeight(),
      });
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return windowSize;
}
