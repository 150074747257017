import React from "react";
import { IconButton, Stack, Typography, Grid } from "@mui/material";
import { Cancel, ConfirmationNumberOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { eliminarCandyCarritoGeneral } from "../../actions/candyActions";
import { useLocation } from "react-router-dom";

function CandyCarritoCard({ producto }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      border="1px solid #dee2e6"
      bgcolor="white"
      padding="0.8rem"
      borderRadius="0.3rem"
    >
      <Grid item xs={6} justifyItems="center">
        <Stack
          direction="row"
          spacing="0.5rem"
          paddingLeft="8px"
          alignItems="center"
        >
          <ConfirmationNumberOutlined />
          <Typography
            fontSize="1.1rem"
            fontWeight="700"
            textTransform="uppercase"
          >
            {producto.name}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2} textAlign="center">
        <Typography fontSize="1.2rem" textTransform="uppercase">
          CANT: {producto.quantity}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="center">
        {datosComplejo.tasaConversion ? (
          <Typography
            fontSize="1.2rem"
            fontWeight="700"
            textTransform="uppercase"
          >
            Bs.
            {Number.isInteger(producto.subTotal)
              ? producto.subTotal
              : producto.subTotal.toFixed(2)}
          </Typography>
        ) : (
          <Typography
            fontSize="1.2rem"
            fontWeight="700"
            textTransform="uppercase"
          >
            $
            {Number.isInteger(producto.subTotal)
              ? producto.subTotal
              : producto.subTotal.toFixed(2)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1} textAlign="center">
        <IconButton
          sx={{
            padding: 0,
          }}
          disabled={location && location.pathname === "/candy" ? false : true}
          color="warning"
          onClick={() => dispatch(eliminarCandyCarritoGeneral(producto))}
        >
          <Cancel fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default CandyCarritoCard;
