import {
  AGREGAR_PROMOCION_CANDY,
  LIMPIAR_PROMOCIONES,
  CARGAR_CANDY,
  CANDY_MODAL_INCREMENTAR_ITEM,
  CANDY_MODAL_DISMINUIR_ITEM,
  CANDY__MODAL_RESET_PREFERENCIAS,
  CANDY_MODAL_INIT_CARRITO,
  CANDY_MODAL_ADD_ITEM_CARRITO,
  CANDY_MODAL_REMOVER_ITEM_CARRITO,
  CANDY_MODAL_ADD_PREFERENCIA_CARRITO,
  CANDY_MODAL_REMOVER_PREFERENCIA_CARRITO,
  CANDY_MODAL_LIMPIAR_PREFERENCIAS,
  CANDY_MODAL_RESET_COUNT_ITEM,
  CANDY_MODAL_INCREMENTAR_SUBTOTAL,
  CANDY_MODAL_SET_SUBTOTAL,
  CANDY_MODAL_DISMINUIR_SUBTOTAL,
  CANDY_MODAL_RESET_SUBTOTAL,
  CANDY_MODAL_LOCK_SELECT_ITEM,
  CANDY_MODAL_SET_ITEM_DATA,
  CANDY_MODAL_RESET_LOCK_ITEM,
  CANDY_MODAL_INCREMENTAR_SUMATORIA_OBLIGATORIAS,
  CANDY_MODAL_DISMINUIR_SUMATORIA_OBLIGATORIAS,
  CANDY_MODAL_RESET_OBLIGATORIAS,
  AGREGAR_CODIGO,
  AGREGAR_PROD_COMBO_DINAMICO,
  CANDY_MODAL_SET_ITEM_PRODS_COMBO_DINAMICO,
  CANDY_MODAL_SET_OBLIGATORIAS_COMBO_DINAMICO,
  CANDY_MODAL_CLEAR_PROD_COMBO_DINAMICO,
  AGREGAR_CATEGORIAS,
  BORRAR_CATEGORIAS,
  CANDY_SET_UNIDAD_NEGOCIO,
  CANDY_MODAL_ADD_PROMOTION_TO_ITEM,
  CANDY_MODAL_RESET_PROMOCIONES,
  CLEAR_ITEM_DATA,
  RESET_REDUX_STATE,
  RESET_REDUX_STATE_CANDY,
} from "../actionTypes/actionTypes";

const initialState = {
  datosCandy: [],
  item: {},
  itemData: {},
  promociones: [],
  codigosCanjeados: [],
  productosComboDinamico: [],
  countItem: 0,
  subtotalModal: 0,
  cantidadObligatorias: 0,
  sumatoriaObligatorias: 0,
  test: [],
  lockSelectItem: false,
  resetPreferencias: false,
  prodComboDinamico: [],
  categoriasSeleccionadas: [],
  unidades: [],
  unidad: 0,
  resetPromociones: false,
};
const candyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARGAR_CANDY:
      return {
        ...state,
        datosCandy: action.payload.candy,
        unidades: action.payload.unidades,
      };
    case CANDY_MODAL_INCREMENTAR_ITEM:
      return {
        ...state,
        countItem: state.countItem + 1,
      };
    case CANDY_MODAL_DISMINUIR_ITEM:
      if (state.countItem > 0) {
        return {
          ...state,
          countItem: state.countItem - 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case CANDY__MODAL_RESET_PREFERENCIAS:
      return {
        ...state,
        resetPreferencias: action.payload.bool,
      };
    case CANDY_MODAL_INIT_CARRITO:
      return {
        ...state,
        item: action.payload.item,
      };
    case CANDY_MODAL_ADD_ITEM_CARRITO:
      return {
        ...state,
        item: action.payload.item,
      };
    case CANDY_MODAL_REMOVER_ITEM_CARRITO:
      return {
        ...state,
        item: action.payload.item,
      };
    case CANDY_MODAL_ADD_PREFERENCIA_CARRITO:
      let preferencia = action.payload.pref;
      let bool = false;
      //Verifico si es una preferencia nueva o ya esta agregada
      state.item.products.forEach((prod) => {
        if (prod.id === preferencia.product_id) {
          for (let i = 0; i < prod.preferences.length; i++) {
            if (prod.preferences[i].id === preferencia.id) {
              bool = true;
              break;
            }
          }
        }
      });
      if (!bool) {
        preferencia.quantity = 1;
      }
      return bool
        ? {
            ...state,
            item: {
              ...state.item,
              products: state.item.products.map((prod) =>
                prod.id === preferencia.product_id
                  ? {
                      ...prod,
                      selected: prod.selected + 1,
                      preferences: prod.preferences.map((pref) =>
                        pref.id === preferencia.id
                          ? {
                              ...pref,
                              quantity: pref.quantity + 1,
                            }
                          : pref
                      ),
                    }
                  : prod
              ),
            },
          }
        : {
            ...state,
            item: {
              ...state.item,
              products: state.item.products.map((prod) =>
                prod.id === preferencia.product_id
                  ? {
                      ...prod,
                      selected: prod.selected + 1,
                      preferences: [...prod.preferences, preferencia],
                    }
                  : prod
              ),
            },
          };
    case CANDY_MODAL_REMOVER_PREFERENCIA_CARRITO:
      return {
        ...state,
        item: {
          ...state.item,
          products: state.item.products.map((prod) =>
            prod.id === action.payload.product_id
              ? {
                  ...prod,
                  selected: prod.selected - 1,
                  preferences: prod.preferences
                    .map((pref) =>
                      pref.id === action.payload.id
                        ? {
                            ...pref,
                            quantity: pref.quantity - 1,
                          }
                        : pref
                    )
                    .filter((pref) => pref.quantity > 0),
                }
              : prod
          ),
        },
      };
    case CANDY_MODAL_LIMPIAR_PREFERENCIAS:
      return {
        ...state,
        sumatoriaObligatorias: 0,
        item: {
          ...state.item,
          products: state.item.products.map((prod) => {
            return {
              ...prod,
              preferences: [],
            };
          }),
        },
      };
    case CANDY_MODAL_RESET_COUNT_ITEM:
      return {
        ...state,
        countItem: 0,
      };
    case CANDY_MODAL_INCREMENTAR_SUBTOTAL:
      return {
        ...state,
        subtotalModal: state.subtotalModal + action.payload.monto,
      };
    case CANDY_MODAL_DISMINUIR_SUBTOTAL: {
      return {
        ...state,
        subtotalModal: state.subtotalModal - action.payload.monto,
      };
    }
    case CANDY_MODAL_SET_SUBTOTAL:
      return {
        ...state,
        subtotalModal: action.payload.monto * state.countItem,
      };
    case CANDY_MODAL_RESET_SUBTOTAL:
      return {
        ...state,
        subtotalModal: 0,
      };
    case CANDY_MODAL_LOCK_SELECT_ITEM:
      return {
        ...state,
        lockSelectItem: !state.lockSelectItem,
      };
    case CANDY_MODAL_SET_ITEM_DATA:
      return {
        ...state,
        itemData: action.payload.data,
        cantidadObligatorias: action.payload.preferenciasObligatorias,
      };
    case CANDY_MODAL_RESET_LOCK_ITEM:
      return {
        ...state,
        lockSelectItem: false,
      };
    case CANDY_MODAL_INCREMENTAR_SUMATORIA_OBLIGATORIAS:
      return {
        ...state,
        sumatoriaObligatorias: state.sumatoriaObligatorias + 1,
      };
    case CANDY_MODAL_DISMINUIR_SUMATORIA_OBLIGATORIAS:
      return {
        ...state,
        sumatoriaObligatorias: state.sumatoriaObligatorias - 1,
      };
    case CANDY_MODAL_RESET_OBLIGATORIAS:
      return {
        ...state,
        sumatoriaObligatorias: 0,
        cantidadObligatorias: 0,
      };
    case AGREGAR_PROMOCION_CANDY:
      let nuevaPromocion = action.payload.promocion;
      return {
        ...state,
        promociones: state.promociones.concat(nuevaPromocion),
      };
    case LIMPIAR_PROMOCIONES:
      return {
        ...state,
        promociones: [],
      };
    case AGREGAR_CODIGO:
      return {
        ...state,
        codigosCanjeados: state.codigosCanjeados.concat(action.payload.codigo),
      };
    case AGREGAR_PROD_COMBO_DINAMICO:
      let prods = action.payload.productos;
      return {
        ...state,
        itemData: { ...state.itemData, productos: prods },
      };
    case CANDY_MODAL_SET_ITEM_PRODS_COMBO_DINAMICO:
      return {
        ...state,
        prodComboDinamico: action.payload.products,
      };
    case CANDY_MODAL_SET_OBLIGATORIAS_COMBO_DINAMICO:
      return {
        ...state,
        cantidadObligatorias: action.payload.preferenciasObligatorias,
      };
    case CANDY_MODAL_CLEAR_PROD_COMBO_DINAMICO:
      return {
        ...state,
        prodComboDinamico: [],
      };
    case AGREGAR_CATEGORIAS:
      let categoria = {
        nombre: action.payload.nombre,
        id: action.payload.id,
      };
      return {
        ...state,
        categoriasSeleccionadas:
          state.categoriasSeleccionadas.concat(categoria),
      };
    case BORRAR_CATEGORIAS:
      return {
        ...state,
        categoriasSeleccionadas: [],
      };
    case CANDY_SET_UNIDAD_NEGOCIO:
      return {
        ...state,
        unidad: action.payload.unidad,
      };
    case CANDY_MODAL_ADD_PROMOTION_TO_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          promotion: [...state.item.promotion, action.payload.data],
        },
      };
    case CANDY_MODAL_RESET_PROMOCIONES:
      if (action.payload.bool) {
        return {
          ...state,
          promociones: [],
          item: {
            ...state.item,
            promotion: [],
          },
          resetPromociones: action.payload.bool,
        };
      } else {
        return {
          ...state,
          resetPromociones: action.payload.bool,
        };
      }
    case CLEAR_ITEM_DATA:
      return {
        ...state,
        item: {},
        itemData: {},
      };
    case RESET_REDUX_STATE:
      return initialState;

    case RESET_REDUX_STATE_CANDY:
      let stateReset = Object.assign(initialState, {
        datosCandy: state.datosCandy,
        unidad: state.unidad,
      });
      return stateReset;

    default:
      return state;
  }
};
export default candyReducer;
