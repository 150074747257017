import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

function FormEntidad({
  entidades,
  entidadSelec,
  setEntidadSelec,
  setFormError,
  setFormSuccess,
}) {
  const handleChange = (evt) => {
    setFormError({
      status: false,
      mensaje: "",
    });
    setFormSuccess({
      status: false,
      mensaje: "",
    });
    const value = evt.target.value;
    setEntidadSelec(value);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography fontSize="1.2rem">Entidad:</Typography>
      <FormControl>
        <Select
          size="small"
          sx={{
            fontSize: "1.2rem",
            width: "140px",
          }}
          name="entidadSelect"
          variant="outlined"
          onChange={handleChange}
          value={entidadSelec}
          color="secondary"
        >
          {entidades &&
            entidades.map((e) => {
              return (
                <MenuItem key={e.id} value={e.nombre}>
                  {e.nombre}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default FormEntidad;
