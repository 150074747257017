import React from "react";
import { Grid, Box, Typography } from "@mui/material";

function ComboCard({ item }) {
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          padding: "1rem",
          marginY: "0.25rem",
          width: 550,
          border: "1px solid #dee2e6",
        }}
      >
        <Grid item xs={10}>
          <Typography
            fontSize="1.2rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            {item.nombre}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            fontSize="1.3rem"
            textTransform="uppercase"
            fontWeight="500"
          >
            ${item.precio}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ComboCard;
