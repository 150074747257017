import { CARGAR_BUTACAS, RESET_REDUX_STATE } from "../actionTypes/actionTypes";

const initialState = {
  datosButaca: [],
};

const butacaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARGAR_BUTACAS:
      return {
        ...state,
        datosButaca: [action.payload.butaca],
      };
    case RESET_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
};

export default butacaReducer;
