import {
  Button,
  ButtonGroup,
  Grid,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  agregarTarifasCarrito,
  eliminarTarifasCarrito,
} from "../../../actions/tarifasAction";
import { setColorByIndex } from "../../../utils/helpers";
import DialogPromocionales from "./DialogPromocionales";

function TarifaCard({
  tarifa,
  index,
  cantidadDisponibles,
  setCantidadDisponibles,
}) {
  const dispatch = useDispatch();
  const carrito = useSelector((state) => state.DatosCarrito.tarifas);

  const [counter, setCounter] = useState(0);
  const [cantidadTotal, setCantidadTotal] = useState(Number(tarifa.tot));
  const [openDialog, setOpenDialog] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );

  useEffect(() => {
    setCantidadTotal(cantidadDisponibles);
    // if (tarifa.id === undefined) {
    // } else {
    //   setCantidadTotal(Number(tarifa.tot));
    // }
    setIsLoading(false);
  }, [tarifa, cantidadDisponibles]);

  const handleAgregarTarifas = (tarifa, promotion = "") => {
    if (cantidadDisponibles > 0) {
      if (Number(tarifa.cantidadMinima) > 1) {
        let i = 0;
        while (i < tarifa.cantidadMinima) {
          dispatch(
            agregarTarifasCarrito(
              tarifa,
              Number(tarifa.cantidadMinima),
              promotion
            )
          );
          setCounter((state) => state + 1);
          setCantidadTotal((state) => state - 1);
          setCantidadDisponibles((state) => state - 1);
          i++;
        }
      } else {
        dispatch(agregarTarifasCarrito(tarifa, 1, promotion));
        setCounter((state) => state + 1);
        setCantidadTotal((state) => state - 1);
        setCantidadDisponibles((state) => state - 1);
      }
    }
    // if (tarifa.id === undefined) {
    // } else {
    //   if (cantidadDisponibles[tarifa.id] > 0) {
    //     if (Number(tarifa.cantidadMinima) > 1) {
    //       let i = 0;
    //       while (i < tarifa.cantidadMinima) {
    //         dispatch(
    //           agregarTarifasCarrito(
    //             tarifa,
    //             Number(tarifa.cantidadMinima),
    //             promotion
    //           )
    //         );
    //         setCounter((state) => state + 1);
    //         setCantidadTotal((state) => state - 1);
    //         setCantidadDisponibles({
    //           ...cantidadDisponibles,
    //           [tarifa.id]:
    //             cantidadDisponibles[tarifa.id] - Number(tarifa.cantidadMinima),
    //         });
    //         i++;
    //       }
    //     } else {
    //       dispatch(agregarTarifasCarrito(tarifa, 1, promotion));
    //       setCounter((state) => state + 1);
    //       setCantidadTotal((state) => state - 1);
    //       setCantidadDisponibles({
    //         ...cantidadDisponibles,
    //         [tarifa.id]: cantidadDisponibles[tarifa.id] - 1,
    //       });
    //     }
    //   }
    // }
  };

  const handleEliminarTarifa = (tarifa) => {
    let tarifaEliminar = carrito.find((item) => item.idKey === tarifa.idKey);
    if (tarifaEliminar) {
      if (Number(tarifa.cantidadMinima) > 1) {
        dispatch(
          eliminarTarifasCarrito(
            tarifaEliminar.idCarrito,
            Number(tarifaEliminar.cantidadMinima)
          )
        );
        setCounter((state) => state - Number(tarifaEliminar.cantidadMinima));
        setCantidadTotal(
          (state) => state + Number(tarifaEliminar.cantidadMinima)
        );
        setCantidadDisponibles(
          (state) => state + Number(tarifaEliminar.cantidadMinima)
        );
      } else {
        dispatch(eliminarTarifasCarrito(tarifaEliminar.idCarrito, 1));
        setCounter((state) => state - 1);
        setCantidadTotal((state) => state + 1);
        setCantidadDisponibles((state) => state + 1);
      }
      // if (tarifaEliminar.id === undefined) {
      // } else {
      //   if (Number(tarifa.cantidadMinima) > 1) {
      //     dispatch(
      //       eliminarTarifasCarrito(
      //         tarifaEliminar.idCarrito,
      //         Number(tarifaEliminar.cantidadMinima)
      //       )
      //     );
      //     setCounter((state) => state - Number(tarifaEliminar.cantidadMinima));
      //     setCantidadTotal(
      //       (state) => state + Number(tarifaEliminar.cantidadMinima)
      //     );
      //     setCantidadDisponibles({
      //       ...cantidadDisponibles,
      //       [tarifaEliminar.id]:
      //         cantidadDisponibles[tarifaEliminar.id] +
      //         Number(tarifaEliminar.cantidadMinima),
      //     });
      //   } else {
      //     dispatch(eliminarTarifasCarrito(tarifaEliminar.idCarrito, 1));
      //     setCounter((state) => state - 1);
      //     setCantidadTotal((state) => state + 1);
      //     setCantidadDisponibles({
      //       ...cantidadDisponibles,
      //       [tarifa.id]: cantidadDisponibles[tarifa.id] + 1,
      //     });
      //   }
      // }
    }
  };

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        bgcolor={setColorByIndex(index)}
        sx={{
          padding: "1rem",
          marginY: "0.25rem",
          border: "1px solid #dee2e6",
        }}
      >
        <Grid item xs={4}>
          <Typography
            fontSize="1.2rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            {tarifa.descripcion}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="center">
          <Typography
            fontSize="1.3rem"
            textTransform="uppercase"
            fontWeight="500"
          >
            {!isLoading ? (
              <>
                {cantidadDisponibles}
                {/* {tarifa.id === undefined
                  ? 
                  : cantidadDisponibles[tarifa.id]} */}
              </>
            ) : (
              ""
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="center">
          {datosComplejo.tasaConversion ? (
            <Typography
              fontSize="1.3rem"
              textTransform="uppercase"
              fontWeight="500"
            >
              {Number(tarifa.precio).toFixed(2)}
            </Typography>
          ) : (
            <Typography
              fontSize="1.3rem"
              textTransform="uppercase"
              fontWeight="500"
            >
              ${Number(tarifa.precio).toFixed(2)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={3} textAlign="right">
          <ButtonGroup>
            <Button
              id={`remove-${tarifa.idKey}`}
              variant="contained"
              color="secondary"
              border="1px transparent"
              size="small"
              margin={0}
              disableElevation
              sx={{ padding: 0, width: "auto" }}
              onClick={() => handleEliminarTarifa(tarifa)}
            >
              <Typography
                fontSize="1.6rem"
                fontWeight="500"
                textTransform="uppercase"
              >
                -
              </Typography>
            </Button>
            <Stack
              component="span"
              borderRadius={0}
              border="1px solid #ced4da"
              width="3rem"
              bgcolor="white"
            >
              <Typography fontSize="1rem" textAlign="center" margin="auto">
                {counter}
              </Typography>
            </Stack>
            <Button
              id={`add-${tarifa.idKey}`}
              variant="contained"
              color="secondary"
              border="1px transparent"
              size="small"
              margin={0}
              disableElevation
              sx={{ padding: 0, width: "auto" }}
              onClick={() => {
                if (tarifa.esPromocional === "1" && cantidadTotal > 0) {
                  setOpenDialog(true);
                  setShowForm(true);
                } else {
                  handleAgregarTarifas(tarifa);
                }
              }}
            >
              <Typography
                fontSize="1.6rem"
                fontWeight="500"
                textTransform="uppercase"
              >
                +
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <DialogPromocionales
        open={openDialog}
        setOpen={setOpenDialog}
        tarifa={tarifa}
        handleAgregarTarifas={handleAgregarTarifas}
        showForm={showForm}
        setShowForm={setShowForm}
      />
    </Box>
  );
}

export default TarifaCard;
