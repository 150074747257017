import { Stack, Dialog, DialogContent, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import FormContainer from "./FormPromocional/FormContainer";
import CarritoContainer from "./Carrito/CarritoContainer";
import ItemContainer from "./Item/ItemContainer";
import { useSelector } from "react-redux";

function CandyModal({ item, open, handleClose }) {
  const [codigosCanjeadosModal, setCodigosCanjeadosModal] = useState([]);

  const resetPromociones = useSelector(
    (state) => state.DatosCandy.resetPromociones
  );
  useEffect(() => {
    if (resetPromociones) {
      setCodigosCanjeadosModal([]);
    }
  }, [resetPromociones]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogContent>
        <Grid container direction="row" spacing={2} overflow="hidden">
          {item && item.es_promocional === 1 ? (
            <Grid item xs={8}>
              <Stack direction="column" spacing={3}>
                <ItemContainer item={item} />
                <FormContainer
                  item={item}
                  codigosCanjeadosModal={codigosCanjeadosModal}
                  setCodigosCanjeadosModal={setCodigosCanjeadosModal}
                />
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={8}>
              <ItemContainer item={item} />
            </Grid>
          )}
          <Grid item xs={4} justifySelf="flex-end">
            <CarritoContainer
              item={item}
              handleCloseModal={handleClose}
              codigosCanjeadosModal={codigosCanjeadosModal}
              setCodigosCanjeadosModal={setCodigosCanjeadosModal}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CandyModal;
