import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import useCurrentWindow from "../../hooks/useCurrentWindow";
import QR from "./images/img_qr_mercadopago.png";
import MP from "./images/point-landing2.png";
import CASH from "./images/chash.png";
import SITEF from "./images/sitef-logo-png.png";
import { useDispatch, useSelector } from "react-redux";
import { lockBtnContinue } from "../../actions/carritoActions";
import { setDataToSend, setPaymentMethod } from "../../actions/paymentActions";
import { useLocation, useNavigate } from "react-router-dom";

function PaymentMethods() {
  const windowSize = useCurrentWindow();
  const dispatch = useDispatch();
  const dataUsuario = useSelector((state) => state.DatosCarrito.dataUsuario);
  const dataTarifas = useSelector((state) => state.DatosCarrito.tarifas);
  const dataCandy = useSelector((state) => state.DatosCarrito.candy);
  const dataFuncion = useSelector((state) => state.DatosFuncion);
  const datosCandy = useSelector((state) => state.DatosCandy.datosCandy);
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const datosComplejo = useSelector((state) => state.DatosComplejo);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const onlyQr = urlParams.get("onlyQr");
  const codigo_pais = useSelector((state) => state.DatosPayment.codigo_pais);

  const cuponesVenta = useSelector(
    (state) => state.DatosCarrito.codigosCanjeadosVenta
  );
  const descuentoVenta = useSelector(
    (state) => state.DatosCarrito.descuentoVenta
  );
  const selected = useSelector((state) => state.DatosPayment.methodSelected);

  useEffect(() => {
    if (selected === "") {
      dispatch(lockBtnContinue(true));
    } else {
      dispatch(lockBtnContinue(false));
    }
  }, [selected, dispatch]);

  useEffect(() => {
    dispatch(
      setDataToSend(
        cuponesVenta,
        descuentoVenta,
        dataUsuario,
        datosCandy,
        dataCandy,
        dataFuncion,
        dataTarifas,
        false,
        datosComplejo
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelected = (method) => {
    dispatch(setPaymentMethod(method));
  };

  const renderMethods = () => {
    if (Object.keys(dataToSend).length !== 0 && dataToSend.amount.total === 0) {
      return (
        <div>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
            {setSelected("CASH")} {navigate(`/payment${location.search}`)}
          </Box>
        </div>
      );
    } else if (Object.keys(dataToSend).length !== 0) {
      if (dataTarifas && dataTarifas.length > 0) {
        return (
          <>
            {codigo_pais === "MX" ? (
              <Box
                sx={{
                  maxWidth: "1024px",
                  height: "auto",
                  marginX: "auto",
                  marginTop: "4rem",
                }}
              >
                <Grid container marginTop="4rem" margin={0}>
                  <Grid
                    id="MP"
                    item
                    xs={windowSize.width < windowSize.height ? 12 : 12}
                    alignContent="center"
                    textAlign={"center"}
                  >
                    <Typography textAlign={"center"} fontSize={"12px"}>
                      Tarjeta de crédito/débito
                    </Typography>
                    <Button
                      sx={
                        selected === "POINT"
                          ? {
                              padding: "2rem",
                              height: "100px",
                              width: "100px",
                              border: "solid 1px",
                              borderColor: "green",
                            }
                          : {
                              padding: "2rem",
                              height: "100px",
                              width: "100px",
                            }
                      }
                      onClick={() => setSelected("POINT")}
                    >
                      <Box
                        component="img"
                        width={"100px"}
                        height={"100px"}
                        alt={`payment`}
                        src={MP}
                        alignItems="center"
                        textAlign="center"
                        borderRadius="0.3rem"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  maxWidth: "1024px",
                  height: "auto",
                  marginX: "auto",
                  marginTop: "4rem",
                }}
              >
                {onlyQr === "1" ? (
                  <Grid container marginTop="4rem" margin={0}>
                    <Grid
                      id="QR"
                      item
                      xs={windowSize.width < windowSize.height ? 12 : 12}
                      alignContent="center"
                      textAlign={"center"}
                    >
                      <Typography textAlign={"center"} fontSize={"12px"}>
                        QR Mercado Pago
                      </Typography>
                      <Button
                        sx={
                          selected === "QR"
                            ? {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                                border: "solid 1px",
                                borderColor: "green",
                              }
                            : {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                              }
                        }
                        onClick={() => setSelected("QR")}
                      >
                        <Box
                          component="img"
                          alt={`payment`}
                          src={QR}
                          alignItems="center"
                          textAlign="center"
                          borderRadius="0.3rem"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {datosComplejo.payment_gateways[0].gateway === "sitef" ? (
                      <Grid container marginTop="4rem" margin={0}>
                        <Grid
                          id="SITEF"
                          item
                          xs={windowSize.width < windowSize.height ? 6 : 6}
                          alignContent="center"
                          textAlign={"center"}
                          sx={{ margin: "auto" }}
                        >
                          <Typography textAlign={"center"} fontSize={"12px"}>
                            Pago móvil
                          </Typography>
                          <Button
                            sx={
                              selected === "SITEF"
                                ? {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                    border: "solid 1px",
                                    borderColor: "green",
                                  }
                                : {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                  }
                            }
                            onClick={() => setSelected("SITEF")}
                          >
                            <Box
                              component="img"
                              alt={`payment`}
                              src={SITEF}
                              alignItems="center"
                              textAlign="center"
                              borderRadius="0.3rem"
                            />
                          </Button>
                        </Grid>
                        <Grid
                          id="SITEF_C2P"
                          item
                          xs={windowSize.width < windowSize.height ? 6 : 6}
                          alignContent="center"
                          textAlign={"center"}
                          sx={{ margin: "auto" }}
                        >
                          <Typography textAlign={"center"} fontSize={"12px"}>
                            Pago Móvil C2P
                          </Typography>
                          <Button
                            sx={
                              selected === "SITEF_C2P"
                                ? {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                    border: "solid 1px",
                                    borderColor: "green",
                                  }
                                : {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                  }
                            }
                            onClick={() => setSelected("SITEF_C2P")}
                          >
                            <Box
                              component="img"
                              alt={`payment`}
                              src={SITEF}
                              alignItems="center"
                              textAlign="center"
                              borderRadius="0.3rem"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container marginTop="4rem" margin={0}>
                        <Grid
                          id="MP"
                          item
                          xs={windowSize.width < windowSize.height ? 6 : 6}
                          alignContent="center"
                          textAlign={"center"}
                        >
                          <Typography textAlign={"center"} fontSize={"12px"}>
                            Tarjeta de crédito/débito
                          </Typography>
                          <Button
                            sx={
                              selected === "POINT"
                                ? {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                    border: "solid 1px",
                                    borderColor: "green",
                                  }
                                : {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                  }
                            }
                            onClick={() => setSelected("POINT")}
                          >
                            <Box
                              component="img"
                              width={"100px"}
                              height={"100px"}
                              alt={`payment`}
                              src={MP}
                              alignItems="center"
                              textAlign="center"
                              borderRadius="0.3rem"
                            />
                          </Button>
                        </Grid>
                        <Grid
                          id="QR"
                          item
                          xs={windowSize.width < windowSize.height ? 6 : 6}
                          alignContent="center"
                          textAlign={"center"}
                        >
                          <Typography textAlign={"center"} fontSize={"12px"}>
                            QR Mercado Pago
                          </Typography>
                          <Button
                            sx={
                              selected === "QR"
                                ? {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                    border: "solid 1px",
                                    borderColor: "green",
                                  }
                                : {
                                    padding: "2rem",
                                    height: "100px",
                                    width: "100px",
                                  }
                            }
                            onClick={() => setSelected("QR")}
                          >
                            <Box
                              component="img"
                              alt={`payment`}
                              src={QR}
                              alignItems="center"
                              textAlign="center"
                              borderRadius="0.3rem"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Box>
            )}
          </>
        );
      } else {
        return (
          <>
            {codigo_pais === "MX" ? (
              <Box
                sx={{
                  maxWidth: "1024px",
                  height: "auto",
                  marginX: "auto",
                  marginTop: "4rem",
                }}
              >
                <Grid container marginTop="4rem" margin={0}>
                  <Grid
                    id="MP"
                    item
                    xs={windowSize.width < windowSize.height ? 6 : 6}
                    alignContent="center"
                    textAlign={"center"}
                  >
                    <Typography textAlign={"center"} fontSize={"12px"}>
                      Tarjeta de crédito/débito
                    </Typography>
                    <Button
                      sx={
                        selected === "POINT"
                          ? {
                              padding: "2rem",
                              height: "100px",
                              width: "100px",
                              border: "solid 1px",
                              borderColor: "green",
                            }
                          : {
                              padding: "2rem",
                              height: "100px",
                              width: "100px",
                            }
                      }
                      onClick={() => setSelected("POINT")}
                    >
                      <Box
                        component="img"
                        width={"100px"}
                        height={"100px"}
                        alt={`payment`}
                        src={MP}
                        alignItems="center"
                        textAlign="center"
                        borderRadius="0.3rem"
                      />
                    </Button>
                  </Grid>
                  <Grid
                    id="CASH"
                    item
                    xs={windowSize.width < windowSize.height ? 6 : 6}
                    alignContent="center"
                    textAlign={"center"}
                  >
                    <Typography textAlign={"center"} fontSize={"12px"}>
                      Efectivo
                    </Typography>
                    <Button
                      sx={
                        selected === "CASH"
                          ? {
                              padding: "2rem",
                              height: "100px",
                              width: "100px",
                              border: "solid 1px",
                              borderColor: "green",
                            }
                          : {
                              padding: "2rem",
                              height: "100px",
                              width: "100px",
                            }
                      }
                      onClick={() => setSelected("CASH")}
                    >
                      <Box
                        sx={{ height: "80px", width: "80px" }}
                        component="img"
                        alt={`payment`}
                        src={CASH}
                        alignItems="center"
                        textAlign="center"
                        borderRadius="0.3rem"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  maxWidth: "1024px",
                  height: "auto",
                  marginX: "auto",
                  marginTop: "4rem",
                }}
              >
                {onlyQr === "1" ? (
                  <Grid container marginTop="4rem" margin={0}>
                    <Grid
                      id="QR"
                      item
                      xs={windowSize.width < windowSize.height ? 6 : 6}
                      alignContent="center"
                      textAlign={"center"}
                    >
                      <Typography textAlign={"center"} fontSize={"12px"}>
                        QR Mercado Pago
                      </Typography>
                      <Button
                        sx={
                          selected === "QR"
                            ? {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                                border: "solid 1px",
                                borderColor: "green",
                              }
                            : {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                              }
                        }
                        onClick={() => setSelected("QR")}
                      >
                        <Box
                          component="img"
                          alt={`payment`}
                          src={QR}
                          alignItems="center"
                          textAlign="center"
                          borderRadius="0.3rem"
                        />
                      </Button>
                    </Grid>
                    <Grid
                      id="CASH"
                      item
                      xs={windowSize.width < windowSize.height ? 6 : 6}
                      alignContent="center"
                      textAlign={"center"}
                    >
                      <Typography textAlign={"center"} fontSize={"12px"}>
                        Efectivo
                      </Typography>
                      <Button
                        sx={
                          selected === "CASH"
                            ? {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                                border: "solid 1px",
                                borderColor: "green",
                              }
                            : {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                              }
                        }
                        onClick={() => setSelected("CASH")}
                      >
                        <Box
                          sx={{ height: "80px", width: "80px" }}
                          component="img"
                          alt={`payment`}
                          src={CASH}
                          alignItems="center"
                          textAlign="center"
                          borderRadius="0.3rem"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container marginTop="4rem" margin={0}>
                    <Grid
                      id="MP"
                      item
                      xs={windowSize.width < windowSize.height ? 4 : 4}
                      alignContent="center"
                      textAlign={"center"}
                    >
                      <Typography textAlign={"center"} fontSize={"12px"}>
                        Tarjeta de crédito/débito
                      </Typography>
                      <Button
                        sx={
                          selected === "POINT"
                            ? {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                                border: "solid 1px",
                                borderColor: "green",
                              }
                            : {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                              }
                        }
                        onClick={() => setSelected("POINT")}
                      >
                        <Box
                          component="img"
                          width={"100px"}
                          height={"100px"}
                          alt={`payment`}
                          src={MP}
                          alignItems="center"
                          textAlign="center"
                          borderRadius="0.3rem"
                        />
                      </Button>
                    </Grid>
                    <Grid
                      id="QR"
                      item
                      xs={windowSize.width < windowSize.height ? 4 : 4}
                      alignContent="center"
                      textAlign={"center"}
                    >
                      <Typography textAlign={"center"} fontSize={"12px"}>
                        QR Mercado Pago
                      </Typography>
                      <Button
                        sx={
                          selected === "QR"
                            ? {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                                border: "solid 1px",
                                borderColor: "green",
                              }
                            : {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                              }
                        }
                        onClick={() => setSelected("QR")}
                      >
                        <Box
                          component="img"
                          alt={`payment`}
                          src={QR}
                          alignItems="center"
                          textAlign="center"
                          borderRadius="0.3rem"
                        />
                      </Button>
                    </Grid>
                    <Grid
                      id="CASH"
                      item
                      xs={windowSize.width < windowSize.height ? 4 : 4}
                      alignContent="center"
                      textAlign={"center"}
                    >
                      <Typography textAlign={"center"} fontSize={"12px"}>
                        Efectivo
                      </Typography>
                      <Button
                        sx={
                          selected === "CASH"
                            ? {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                                border: "solid 1px",
                                borderColor: "green",
                              }
                            : {
                                padding: "2rem",
                                height: "100px",
                                width: "100px",
                              }
                        }
                        onClick={() => setSelected("CASH")}
                      >
                        <Box
                          sx={{ height: "80px", width: "80px" }}
                          component="img"
                          alt={`payment`}
                          src={CASH}
                          alignItems="center"
                          textAlign="center"
                          borderRadius="0.3rem"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </>
        );
      }
    }
  };

  return renderMethods();
}

export default PaymentMethods;
