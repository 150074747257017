import { Stack, Typography } from "@mui/material";

export default function Entradas({ data }) {
  return (
    <Stack direction="column" width="auto" minWidth="40%" marginRight={3}>
      <Typography fontSize={18} marginBottom={1}>
        Entradas:
      </Typography>
      <Typography fontSize={14}>
        <b>Nombre Pelicula: </b>
        {data.pelicula}
      </Typography>
      <Typography fontSize={14}>
        <b>Tipo: </b>
        {data.tipoPelicula}
      </Typography>
      <Typography fontSize={14}>
        <b>Sala: </b>
        {data.sala}
      </Typography>
      <Typography fontSize={14}>
        <b>Fecha y Hora: </b>
        {data.horario}
      </Typography>
      <Typography fontSize={14}>
        <b>Cantidad de Entradas: </b>
        {data.cantidadEntradas}
      </Typography>
      <Typography fontSize={14}>
        <b>Fecha de Compra: </b>
        {data.fechaCreacion}
      </Typography>
      <Typography fontSize={14}>
        <b>Fecha de Pago: </b>
        {data.fechaAprobacion ? data.fechaAprobacion : data.fechaCreacion}
      </Typography>
      <Typography fontSize={14}>
        <b>Email Comprador: </b>
        {data.emailComprador}
      </Typography>
      <Typography fontSize={14}>
        <b>Monto Transacción: </b>${data.montoTotalPagado}
      </Typography>
      <Typography fontSize={14}>
        <b>Estado: </b>
        {data.estado}
      </Typography>
      <Typography fontSize={14}>
        <b>Tipo Pago: </b>
        {data.tipoPago}
      </Typography>
      <Typography fontSize={14}>
        <b>Tipo de Operación: </b>
        {data.tipoOperacion ? data.tipoOperacion : "-"}
      </Typography>
    </Stack>
  );
}
