import { Stack, Fade, Paper, Button, ClickAwayListener } from "@mui/material";
import KeyboardReact from "react-simple-keyboard";

export default function ModalKeyboard({
  open,
  setOpen,
  keyboard,
  inputName,
  onKeyPress,
  onChangeAll,
  layoutName,
}) {
  const handleClose = () => setOpen(false);

  return (
    <ClickAwayListener>
      <Fade appear={true} in={open}>
        <Paper
          role="dialog"
          variant="outlined"
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            pt: 0,
            borderWidth: 0,
            borderTopWidth: 1,
            zIndex: "50",
          }}
        >
          <Stack direction="column" gap={1} my={1}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                height: "100%",
                paddingX: "10px",
                fontSize: "1.2rem",
                fontWeight: "600",
                mx: "auto",
              }}
              disableElevation
              onClick={handleClose}
            >
              Cerrar teclado
            </Button>
            <KeyboardReact
              className="keyboard"
              keyboardRef={(r) => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
            />
          </Stack>
        </Paper>
      </Fade>
    </ClickAwayListener>
  );
}
