import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import Entradas from "./Entradas";
import Candy from "./Candy";
import { useState } from "react";
import axios from "axios";
import { printQrPointPayment } from "../../utils/impresion";
import { redirect } from "../../utils/helpers";
import apihost from "../../config/apiHost.json";

export default function ModalCanje({
  open,
  setOpen,
  data,
  setRespuestaCanje,
  complejoId,
  setCodigo,
  datosComplejo,
  deviceId,
}) {
  console.log(data);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [canjeSinStock, setCanjeSinStock] = useState(false);
  const [success, setSuccess] = useState({
    estado: false,
    mensaje: "",
  });
  const [error, setError] = useState({
    estado: false,
    mensaje: "",
  });

  const handleSubmit = async (sinStock) => {
    setIsLoading(true);

    if (sinStock) setCanjeSinStock(false);

    const dataToSend = {
      collectionId: data.collectionId,
      complejo_id: complejoId,
      idFuncion: data.boleteria ? data.idFuncion : null,
      forzarSinStock: sinStock,
      idATM: deviceId,
    };

    await axios({
      method: "POST",
      url: `${apihost.REACT_APP_URL_CINEXO}impresiones/TramaCanje`,
      data: JSON.stringify(dataToSend),
      responseType: "json",
    }).then((res) => {
      console.log(res.data);
      if (!res.data.error) {
        if (
          res.data.datos.tramaBoleteria !== "" ||
          res.data.datos.tramaCandy !== ""
        ) {
          let mensaje = "Código canjeado con éxito!";
          if (res.data?.datos?.data?.venta?.canje?.descripcion)
            mensaje = res.data?.datos?.data?.venta?.canje?.descripcion;
          setSuccess({
            estado: true,
            mensaje,
          });
          setIsLoading(false);
          setIsDisabled(true);
          setTimeout(() => {
            redirect(
              datosComplejo.ip_privada,
              datosComplejo.version_boleteria,
              deviceId
            );
          }, 10000);
          printQrPointPayment(res.data);
          setCodigo("");
        } else {
          console.log("Error al obtener la trama");
          // const mensaje = res.data.datos?.data?.venta?.canje?.descripcion;
          setError({
            estado: true,
            mensaje:
              "Error al validar compra. Por favor, comuníquese con boletería.",
          });
          setTimeout(() => {
            redirect(
              datosComplejo.ip_privada,
              datosComplejo.version_boleteria,
              deviceId
            );
          }, 5000);
          setCodigo("");
          setIsLoading(false);
        }
      } else {
        console.log("Error al obtener la trama");
        let mensaje =
          "Error al validar compra. Por favor, comuníquese con boletería.";
        if (res.data.datos !== null) mensaje = res.data?.datos?.error?.message;
        if (mensaje.includes("stock")) {
          setError({
            estado: true,
            mensaje,
          });
          setCanjeSinStock(true);
        } else {
          setError({
            estado: true,
            mensaje,
          });
          setTimeout(() => {
            redirect(
              datosComplejo.ip_privada,
              datosComplejo.version_boleteria,
              deviceId
            );
          }, 5000);
          setCodigo("");
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography variant="h3" marginBottom={1}>
          Consulta Venta Web: {data.collectionId}
        </Typography>
        <Divider />
        <Stack
          direction="row"
          marginTop={3}
          marginBottom={3}
          justifyContent="center"
        >
          <Entradas data={data} />
          <Divider orientation="vertical" flexItem />
          <Candy data={data} />
        </Stack>
        {data.canje.canjeado ? (
          <Stack
            width="auto"
            bgcolor="#ffcdd2"
            borderRadius={0.5}
            border="1px solid #d32f2f"
            padding={0.5}
            marginTop={1}
          >
            <Typography fontSize="1.2rem" color="#b71c1c">
              Código canjeado anteriormente
            </Typography>
          </Stack>
        ) : (
          ""
        )}
        {success.estado ? (
          <Stack
            width="auto"
            bgcolor="#E9FBF0"
            borderRadius={0.5}
            border="1px solid #9AEFBC"
            padding={0.5}
            marginTop={1}
          >
            <Typography fontSize="1.2rem" color="#0F5C2E">
              {success.mensaje}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
        {error.estado ? (
          <Stack
            width="auto"
            bgcolor="#ffcdd2"
            borderRadius={0.5}
            border="1px solid #d32f2f"
            padding={0.5}
            marginTop={1}
          >
            <Typography fontSize="1.2rem" color="#b71c1c">
              {error.mensaje}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          marginTop={2}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              height: "100%",
              paddingX: "25px",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
            disableElevation
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setCodigo("");
              setOpen(false);
              setRespuestaCanje(null);
              setSuccess({
                estado: false,
                mensaje: "",
              });
              setError({
                estado: false,
                mensaje: "",
              });
            }}
          >
            Volver
          </Button>
          {!canjeSinStock ? (
            <LoadingButton
              variant="contained"
              loading={isLoading}
              color="secondary"
              sx={{
                height: "100%",
                paddingX: "25px",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
              disableElevation
              startIcon={<PrintIcon />}
              disabled={data.canje.canjeado || isDisabled}
              onClick={() => handleSubmit(false)}
            >
              Validar Compra
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              loading={isLoading}
              color="secondary"
              sx={{
                height: "100%",
                paddingX: "25px",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
              disableElevation
              startIcon={<PrintIcon />}
              disabled={data.canje.canjeado || isDisabled}
              onClick={() => handleSubmit(true)}
            >
              Canjear de todas formas
            </LoadingButton>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
