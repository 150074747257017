import {
  CARRITO_CLEAR_BUTACAS,
  CARRITO_LOCK_BTN_CONTINUE,
  CARRITO_UPDATE_SUBTOTAL,
  RESET_REDUX_STATE,
  SET_NEXT_PAGE,
  SET_ONLY_CANDY,
  UNSET_ONLY_CANDY,
} from "../actionTypes/actionTypes";

const setNextPage = (nextPage) => {
  return {
    type: SET_NEXT_PAGE,
    payload: {
      nextPage,
    },
  };
};

const lockBtnContinue = (bool) => {
  return {
    type: CARRITO_LOCK_BTN_CONTINUE,
    payload: {
      bool,
    },
  };
};

const carritoResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

const setOnlyCandy = () => {
  return {
    type: SET_ONLY_CANDY,
  };
};

const unsetOnlyCandy = () => {
  return {
    type: UNSET_ONLY_CANDY,
  };
};

const resetButacas = () => {
  return {
    type: CARRITO_CLEAR_BUTACAS,
  };
};

const updateSubtotal = (subtotal) => {
  return {
    type: CARRITO_UPDATE_SUBTOTAL,
    payload: {
      subtotal,
    },
  };
};
export {
  setNextPage,
  lockBtnContinue,
  carritoResetReduxState,
  setOnlyCandy,
  unsetOnlyCandy,
  resetButacas,
  updateSubtotal,
};
