import { FormControl, Stack, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPromotionToItem,
  agregarPromocion,
} from "../../../actions/candyActions";
import {
  validarPromocionCombo,
  validarPromocionProducto,
  validarPromocionRubro,
} from "./utilsForm";
import apihost from "../../../config/apiHost.json";

function FormCanje({
  item,
  entidad,
  setNombreEntidad,
  setFormError,
  setFormSuccess,
  setOpenForm,
  codigosCanjeadosModal,
  setCodigosCanjeadosModal,
}) {
  const dispatch = useDispatch();
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const codigosCanjeados = useSelector(
    (state) => state.DatosCandy.codigosCanjeados
  );
  const categoriasSeleccionadas = useSelector(
    (state) => state.DatosCandy.categoriasSeleccionadas
  );

  const precioItem = useSelector((state) =>
    Number(state.DatosCandy.item.price)
  );
  const [inputs, setInputs] = useState(null);

  useEffect(() => {
    if (entidad.inputs.length > 0) setInputs(JSON.parse(entidad.inputs));
  }, [entidad]);

  const [datosPromocion, setDatosPromocion] = useState({
    partner_code: entidad.id,
    partner_name: entidad.nombre,
    complejo_id: datosFuncion.cine_id,
    promotion_code: entidad.idPromocion,
    data: {
      funcion_id: datosFuncion.funcion_id,
      tipo: null,
    },
  });

  const [data, setData] = useState({
    funcion_id: datosFuncion.funcion_id,
    tipo: null,
  });

  const [codigo, setCodigo] = useState("");

  const handleChange = (evt) => {
    const value = evt.target.value;
    setData((state) => {
      return { ...state, [evt.target.name]: value };
    });
    setCodigo(value);
    setFormError({
      status: false,
      mensaje: "",
    });
    setFormSuccess({
      status: false,
      mensaje: "",
    });
  };

  const handleBlur = () => {
    setDatosPromocion((datosPromocion) => {
      return {
        ...datosPromocion,
        data,
      };
    });
  };

  const handleResetInputs = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({
      funcion_id: datosFuncion.funcion_id,
      tipo: null,
    });
  };

  // 24597533439295489
  // 24597533439295490
  // 24597533439295491
  // 24597533439295492

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    await fetch(`${apihost.REACT_APP_URL_CINEXO}promocion/validar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datosPromocion),
    })
      .then((response) => response.json())
      .then((respuesta) => {
        if (respuesta.valido === true) {
          let validarProducto = validarPromocionProducto(
            item.id,
            respuesta.cupon.id_producto,
            setFormError,
            respuesta.cupon.id_rubro,
            respuesta.cupon.id_combo
          );
          let validarRubro = validarPromocionRubro(
            categoriasSeleccionadas,
            respuesta.cupon.id_rubro,
            setFormError,
            respuesta.cupon.id_producto,
            respuesta.cupon.id_combo
          );
          let validarCombo = validarPromocionCombo(
            item.id,
            respuesta.cupon.id_combo,
            setFormError
          );
          if (
            codigosCanjeados.includes(codigo) !== true &&
            codigosCanjeadosModal.includes(codigo) !== true &&
            validarProducto &&
            validarRubro &&
            validarCombo
          ) {
            setFormSuccess({
              status: true,
              mensaje: respuesta.datos.mensaje,
            });
            let precioDescuento = 0;
            if (
              respuesta.cupon.tipoDescuento === "moneda_candy" &&
              respuesta.cupon.descuento !== null
            )
              precioDescuento = Number(respuesta.cupon.descuento);
            if (
              respuesta.cupon.tipoDescuento === "porcentaje_candy" &&
              respuesta.cupon.descuento !== null
            )
              precioDescuento =
                precioItem * (Number(respuesta.cupon.descuento) / 100);
            if (respuesta.cupon.tipoDescuento === null) precioDescuento = 0;
            let promocion = {
              cupon: respuesta.cupon,
              precioDescuento,
            };
            setCodigosCanjeadosModal((state) => state.concat(codigo));
            dispatch(agregarPromocion(promocion));
            let promotionDataForItem = {
              discount: promocion.precioDescuento,
              code: promocion.cupon.codigo,
            };
            dispatch(addPromotionToItem(promotionDataForItem));
            setTimeout(() => {
              setOpenForm(false);
              setNombreEntidad("");
              setFormSuccess({
                status: false,
                mensaje: "",
              });
              handleResetInputs();
            }, 4000);
          } else {
            if (validarProducto && validarRubro && validarCombo) {
              setFormError({
                status: true,
                mensaje: "Código ingresado anteriormente",
              });
            }
            handleResetInputs();
          }
        } else {
          setFormError({
            status: true,
            mensaje: respuesta.mensaje,
          });
          handleResetInputs();
        }
      });
  };

  return (
    <FormControl>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="column" spacing={0.5}>
          {inputs !== null &&
            inputs?.map((input, index) => {
              return (
                <TextField
                  key={input.nombre}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    name: input.nombre,
                    type: input.tipo,
                    style: {
                      fontSize: "1.2rem",
                    },
                  }}
                  label={input.placeholder ? input.placeholder : input.nombre}
                  InputLabelProps={{
                    style: {
                      fontSize: "1.1rem",
                    },
                  }}
                  value={data[index]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="secondary"
                />
              );
            })}
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          size="small"
          sx={{
            padding: "0.5rem",
            fontSize: "1.2rem",
            fontWeight: "700",
            width: "35%",
          }}
          onClick={handleSubmit}
        >
          Validar
        </Button>
      </Stack>
    </FormControl>
  );
}

export default FormCanje;
