const letras = [
  "0",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const crearSalaBase = (
  filas,
  tipoFila,
  columnas,
  tipoColumna,
  setSalaBase
) => {
  setSalaBase([]);

  for (let fila = 1; fila <= filas; fila++) {
    for (let columna = 1; columna <= columnas; columna++) {
      if (tipoFila === "numeros" && tipoColumna === "numeros") {
        setSalaBase((salaBase) => [
          ...salaBase,
          {
            butaca: `${fila}-${columna}-pas`,
            fila,
            columna,
            pasillo: true,
          },
        ]);
      } else if (tipoFila === "letras" && tipoColumna === "letras") {
        setSalaBase((salaBase) => [
          ...salaBase,
          {
            butaca: `${letras[fila]}-${letras[columna]}-pas`,
            fila,
            columna: letras[columna],
            pasillo: true,
          },
        ]);
      } else if (tipoFila === "letras" && tipoColumna === "numeros") {
        setSalaBase((salaBase) => [
          ...salaBase,
          {
            butaca: `${letras[fila]}-${columna}-pas`,
            fila,
            columna,
            pasillo: true,
          },
        ]);
      } else if (tipoFila === "numeros" && tipoColumna === "letras") {
        setSalaBase((salaBase) => [
          ...salaBase,
          {
            butaca: `${fila}-${letras[columna]}-pas`,
            fila,
            columna: letras[columna],
            pasillo: true,
          },
        ]);
      }
    }
  }
};

export const ordenarButacas = (butacas, tipoColumna) => {
  let butacasOrd = butacas.sort((but1, but2) => {
    if (tipoColumna === "numeros") {
      if (butacas[0].invertida === "1") {
        if (Number(but1.columna) > Number(but2.columna)) {
          return -1;
        } else if (Number(but1.columna) < Number(but2.columna)) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (Number(but1.columna) < Number(but2.columna)) {
          return -1;
        } else if (Number(but1.columna) > Number(but2.columna)) {
          return 1;
        } else {
          return 0;
        }
      }
    } else if (tipoColumna === "letras") {
      if (butacas[0].invertida === "1") {
        if (but1.columna > but2.columna) {
          return -1;
        } else if (but1.columna < but2.columna) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (but1.columna < but2.columna) {
          return -1;
        } else if (but1.columna > but2.columna) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  });
  butacasOrd = butacas.sort((but1, but2) => {
    if (butacas[0].invertidaFila === "1") {
      if (Number(but1.fila) < Number(but2.fila)) {
        return -1;
      } else if (Number(but1.fila) > Number(but2.fila)) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (Number(but1.fila) < Number(but2.fila)) {
        return -1;
      } else if (Number(but1.fila) > Number(but2.fila)) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return butacasOrd;
};

export const crearSalaCine = (salaBase, tipoColumna, butacas, setSalaCine) => {
  const sala = salaBase;
  butacas.forEach((but) => {
    sala.forEach((e, index) => {
      if (Number(but.fila) === e.fila && but.columna === e.columna.toString()) {
        sala.splice(index, 1, but);
      }
    });
  });
  setSalaCine(ordenarButacas(sala, tipoColumna));
};

export const obtenerCantidadEntradasPorTipo = (tipoButaca, tiposDeEntradas) => {
  let cantidadEntradasTipo = 0;
  tiposDeEntradas.forEach((tipoSeleccionadaActual) => {
    if (tipoButaca === tipoSeleccionadaActual) {
      cantidadEntradasTipo++;
    }
  });
  return cantidadEntradasTipo;
};

export const obtenerButacasSeleccionadasPorTipo = (
  tipoButaca,
  butacasSelec
) => {
  let cantidadSeleccionadas = 0;
  butacasSelec.forEach((but) => {
    if (tipoButaca === Number(but.tipo_butaca_id) || tipoButaca === "no-id") {
      cantidadSeleccionadas++;
    }
  });
  return cantidadSeleccionadas;
};

export const obtenerNombreTipoDeEntrada = (entradas, tipoButaca) => {
  let nombre = "";
  if (entradas[0]?.id) {
    entradas.forEach((item) => {
      if (tipoButaca === Number(item.id)) {
        nombre = item.tipoTarifa;
      }
    });
  } else {
    nombre = entradas[0]?.tipoTarifa;
  }
  return nombre;
};
