import { Dialog, Box, Stack, Typography, Button } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { limpiarCarrito } from "../actions/tarifasAction";
import { clearAllReduxState } from "../utils/resetReduxState";

function ModalErrorWs() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <Box bgcolor="error.main" textAlign="center" color="white">
        <Stack
          margin={3}
          marginTop={10}
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <CancelOutlinedIcon sx={{ fontSize: "10rem" }} />
          <Typography fontSize="1.8rem">¡Ups!</Typography>
        </Stack>
      </Box>
      <Box textAlign="center">
        <Stack direction="column" margin={3} spacing={3} alignItems="center">
          <Stack direction="column" spacing={2}>
            <Typography fontSize="1.3rem">Ha ocurrido un error</Typography>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            marginTop={2}
            sx={{
              padding: "1rem",
              width: "70%",
              fontSize: "1.3rem",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            onClick={() => {
              navigate(`/${location.search}`);
              dispatch(limpiarCarrito());
              clearAllReduxState(dispatch);
            }}
          >
            Volver al inicio
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default ModalErrorWs;
