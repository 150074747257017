import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { redirect } from "../../utils/helpers";

function TimerModal({ time }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const [segundosRestantes, setSegundosRestantes] = useState(time);
  const [pausado, setPausado] = useState(false);

  useEffect(() => {
    let intervalId = null;
    if (!pausado) {
      intervalId = setInterval(() => {
        setSegundosRestantes((segundosRestantes) => {
          if (segundosRestantes === 0) {
            clearInterval(intervalId); // Detener el intervalo cuando segundosRestantes es cero
            setPausado(true);
            redirect(
              datosComplejo.ip_privada,
              datosComplejo.version_boleteria,
              device_id
            );
            return segundosRestantes;
          }
          return segundosRestantes - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
    };
  }, [pausado, datosComplejo, device_id]);

  useEffect(() => {
    if (segundosRestantes === 0) {
      setPausado(true);
      redirect(
        datosComplejo.ip_privada,
        datosComplejo.version_boleteria,
        device_id
      );
    }
  }, [segundosRestantes, datosComplejo, device_id]);

  const minutos = String(Math.floor(segundosRestantes / 60)).padStart(2, "0");
  const segundos = String(segundosRestantes % 60).padStart(2, "0");
  const tiempoRestante = `${minutos}:${segundos}`;

  return (
    <Typography fontSize="1.3rem" padding={2}>
      Al finalizar el tiempo te redireccionaremos al inicio: {tiempoRestante}
    </Typography>
  );
}

export default TimerModal;
