import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementRetry,
  resetQRData,
  retryPaymentQR,
} from "../../actions/paymentActions";
import { QRCodeSVG } from "qrcode.react";
import {
  confirmarVentaCheckout,
  getStatusPaymentQR,
  getStatusPayment,
} from "./utils/requests";

import ModalSuccess from "../Carrito/ModalSuccess";
import ModalError from "../Carrito/ModalError";
import {
  getImpresionFacturaQRPoint,
  getImpresionTicketsQRPoint,
} from "../../utils/impresion";
import TimerPayment from "../HeadlineTimer/TimerPayment";
import { useLocation } from "react-router-dom";
import { lockBtnContinue } from "../../actions/carritoActions";
import TimerModal from "../HeadlineTimer/TimerModal";
import { redirect } from "../../utils/helpers";

function PaymentQR() {
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const referenceID = useSelector((state) => state.DatosPayment.referenceID);
  const qrData = useSelector((state) => state.DatosPayment.qrData);
  const complexCode = useSelector((state) => state.DatosFuncion.cine_id);
  const funcion_id = useSelector((state) => state.DatosFuncion.funcion_id);
  const client = useSelector((state) => state.DatosCarrito.dataUsuario);
  const retries = useSelector((state) => state.DatosPayment.retries);
  const responseStep1 = useSelector(
    (state) => state.DatosPayment.responseStep1
  );
  const onlyCandy = useSelector((state) => state.DatosCarrito.onlyCandy);
  const [statusPayment, setStatusPayment] = useState("");
  const [paymentIDMP, setpaymentIDMP] = useState("");
  const [paymentProcessStatus, setpaymentProcessStatus] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [statusQR, setStatusQR] = useState("");
  const [paymentDetail, setPaymentDetail] = useState("");
  const external_id = useSelector((state) => state.DatosPayment.external_id);
  const [visible, setVisible] = useState(true);
  const subtype = useSelector((state) => state.DatosPayment.subtype);
  const hash = useSelector((state) => state.DatosPayment.hash);

  const handleRetry = () => {
    dispatch(incrementRetry());
    dispatch(resetQRData());
    setTimeout(function () {}, 5000);
    dispatch(retryPaymentQR(responseStep1, dataToSend, external_id, hash));
  };

  function redireccionarAURL() {
    redirect(
      datosComplejo.ip_privada,
      datosComplejo.version_boleteria,
      device_id
    );
  }

  const getStatusPWQR = () => {
    let intervalId = setInterval(async function () {
      let responseStatus = await getStatusPaymentQR(referenceID, hash);
      console.log("Payment status", responseStatus);
      if (statusQR === "CANCELED") {
        clearInterval(intervalId);
      }
      if (responseStatus.data.success) {
        if (responseStatus.data.data.rawResponsePayload.approved) {
          dispatch(lockBtnContinue(true));
          clearInterval(intervalId);
          setStatusQR("success");
          let statusPaymentQR = await getStatusPayment(
            complexCode,
            responseStatus.data.data.paymentId,
            subtype,
            hash
          );
          console.log("Status Payment QR", statusPaymentQR);
          setpaymentIDMP(statusPaymentQR.data.data.response.id);
          if (statusPaymentQR.data.data.response.status === "approved") {
            setStatusPayment(statusPaymentQR.data.data.response.status);
            setpaymentProcessStatus("checkout-confirming");
            setTimeout(async function () {
              let confirmarCheckoutResponse = await confirmarVentaCheckout(
                complexCode,
                referenceID,
                "mercadopago"
              );
              console.log(
                "response confirmar Checkout",
                confirmarCheckoutResponse
              );
              if (
                confirmarCheckoutResponse &&
                confirmarCheckoutResponse.data &&
                (confirmarCheckoutResponse.data.success ||
                  confirmarCheckoutResponse.data.status === "procesado")
              ) {
                setpaymentProcessStatus("success");
                getImpresionTicketsQRPoint(
                  statusPaymentQR.data.data.response.id,
                  funcion_id,
                  complexCode,
                  "mercadopago",
                  onlyCandy
                );
                getImpresionFacturaQRPoint(
                  statusPaymentQR.data.data.response.id,
                  client.nombre,
                  complexCode
                );
              } else {
                setpaymentProcessStatus("failed");
              }
              setTotalAmount(
                `${statusPaymentQR.data.data.response.currency_id} ${statusPaymentQR.data.data.response.transaction_amount}`
              );
            }, 15000);
          } else {
            setPaymentDetail(statusPaymentQR.data.data.response.status_detail);
          }
        } else {
          setStatusPayment("rejected");
          setpaymentIDMP(
            responseStatus.data.data.rawResponsePayload.payload.paymentInfo.body
              .id
          );
          setPaymentDetail(
            responseStatus.data.data.rawResponsePayload.payload.paymentInfo.body
              .status_detail
          );
        }
      }
    }, 10000);
  };
  const renderQR = () => {
    if (paymentProcessStatus === "failed") {
      return (
        <ModalError
          error={
            "El sistema ha fallado al confirmar la venta. Con tu Número de operacion podrás realizar el reclamo en caja."
          }
          idOperation={paymentIDMP}
          errorType={"checkout"}
        ></ModalError>
      );
    }
    if (statusQR === "CANCELED" && retries >= 1) {
      return (
        <Grid container marginTop="4rem" marginX={0}>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Typography align="center" fontSize={"2rem"} padding={2}>
              Lo sentimos, has llegado al limite de intentos.
            </Typography>
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <TimerModal time={60} />
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => {
                redireccionarAURL();
              }}
            >
              Volver al inicio
            </Button>
          </Grid>
        </Grid>
      );
    }
    if (statusQR === "CANCELED" && retries < 1) {
      return (
        <Grid container marginTop="4rem" marginX={0}>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <Typography align="center" fontSize={"2rem"} padding={2}>
              Lo sentimos, has llegado al limite de tiempo.
            </Typography>
          </Grid>
          <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
            <TimerModal time={60} />
          </Grid>
          <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => handleRetry()}
            >
              Reintentar
            </Button>
          </Grid>
          <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
            <Button
              variant={"outlined"}
              color="secondary"
              size="large"
              sx={{
                padding: "1.5rem",
                fontSize: "1.4rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              disableElevation
              onClick={() => {
                redireccionarAURL();
              }}
            >
              Volver al inicio
            </Button>
          </Grid>
        </Grid>
      );
    }
    if (statusPayment === "" && statusQR === "") {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"} padding={2}>
            Escanee el siguiente código QR
          </Typography>
          <TimerPayment
            data={{
              QR: true,
              setStatusQR: setStatusQR,
              visible,
            }}
          />
          <Grid container marginTop="4rem" margin={0}>
            <Grid
              id="MP"
              item
              xs={12}
              alignContent="center"
              textAlign={"center"}
            >
              <QRCodeSVG value={qrData} size={300} />
              {getStatusPWQR()}
            </Grid>
          </Grid>
        </div>
      );
    } else if (statusQR === "success" && paymentProcessStatus === "") {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            Estamos comprobando el estado del pago.
          </Typography>
          <Grid
            item
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          ></Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </div>
      );
    } else if (
      statusPayment === "approved" &&
      paymentProcessStatus === "checkout-confirming"
    ) {
      return (
        <div>
          <Typography align="center" fontSize={"2rem"}>
            Su pago ha sido aprobado, estamos confirmando su compra.
          </Typography>
          <Grid
            item
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          ></Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={10}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </div>
      );
    } else if (
      statusPayment === "approved" &&
      paymentProcessStatus === "success"
    ) {
      return (
        <ModalSuccess
          idOperation={paymentIDMP}
          totalAmount={totalAmount}
        ></ModalSuccess>
      );
    } else if (statusPayment === "rejected") {
      return (
        <ModalError
          error={paymentDetail}
          idOperation={paymentIDMP}
        ></ModalError>
      );
    }
  };
  return (
    <Box
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        marginTop: "4rem",
      }}
    >
      {renderQR()}
    </Box>
  );
}

export default PaymentQR;
