import { Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import SelectorCard from "./SelectorCard";

function ProductoCard({ producto }) {
  const [limit, setLimit] = useState(0);
  const [selected, setSelected] = useState(0);

  const renderizarPreferencias = () => {
    if (producto.preferencias && producto.preferencias.length > 0) {
      return producto.preferencias.map((p, index) => {
        return (
          <SelectorCard
            index={index}
            key={p.preferencia_id}
            nombre={p.preferencia_nombre}
            selected={selected}
            setSelected={setSelected}
            limit={limit}
            setLimit={setLimit}
            preferencia={p}
          />
        );
      });
    }
  };

  return (
    <Stack direction="column" spacing={0.5}>
      <Typography
        fontSize="1rem"
        textAlign="left"
        sx={{
          marginTop: "1.3rem",
        }}
      >
        {producto.preferencias.length > 0 ? producto.nombre : ""}
      </Typography>
      {renderizarPreferencias()}
    </Stack>
  );
}

export default ProductoCard;
