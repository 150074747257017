import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { redirect } from "../../utils/helpers";
export const TimerContext = createContext();

export function TimerProvider({ children }) {
  const [contador, setContador] = useState(10 * 60);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );

  useEffect(() => {
    let intervalId = null;
    if (contador > 0) {
      intervalId = setInterval(() => {
        setContador((prevContador) => prevContador - 1);
      }, 1000);
    } else {
      redirect(
        datosComplejo.ip_privada,
        datosComplejo.version_boleteria,
        device_id
      );
    }

    return () => clearInterval(intervalId);
  }, [contador, datosComplejo, device_id]);

  function resetContador() {
    setContador(10 * 60);
  }

  function redirectToHome() {
    redirect(
      datosComplejo.ip_privada,
      datosComplejo.version_boleteria,
      device_id
    );
  }

  return (
    <TimerContext.Provider value={{ contador, redirectToHome, resetContador }}>
      {children}
    </TimerContext.Provider>
  );
}
