import { Grid, Typography } from "@mui/material";
import { setColorByIndex } from "../../utils/helpers";

export default function CandyCard({ item, index }) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        bgcolor={setColorByIndex(index)}
        sx={{
          padding: "1rem",
          borderTop: "1px solid #dee2e6",
        }}
      >
        <Grid item xs={3}>
          <Typography fontSize={12}>{item.cantidad}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={12}>{item.producto_nombre}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
