import {
  CARGAR_TARIFAS,
  ACTUALIZAR_TOTAL_ENTRADAS,
  AGREGAR_CODIGO_TARIFAS,
  RESET_REDUX_STATE,
  TARIFAS_LIMPIAR_CUPONES,
} from "../actionTypes/actionTypes";

const initialState = {
  counter: 0,
  subtotal: 0,
  datosTarifa: [],
  codigosCanjeados: [],
};

const tarifaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARGAR_TARIFAS:
      let dataTarifas = action.payload.tarifas;
      let newDatosTarifas = dataTarifas.map((tarifa) => {
        return {
          ...tarifa,
          idKey: Math.random().toString(30).substring(2),
        };
      });
      return {
        ...state,
        datosTarifa: newDatosTarifas,
      };
    case ACTUALIZAR_TOTAL_ENTRADAS:
      return {
        ...state,
        subtotal: state.subtotal + action.payload.amount, //actualizamos subtotal de entradas
      };
    case AGREGAR_CODIGO_TARIFAS:
      return {
        ...state,
        codigosCanjeados: state.codigosCanjeados.concat(action.payload.data),
      };
    case TARIFAS_LIMPIAR_CUPONES:
      return {
        ...state,
        codigosCanjeados: [],
      };
    case RESET_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
};

export default tarifaReducer;
