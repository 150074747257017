import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  agregarCandyCarritoGeneral,
  agregarCodigo,
  candyResetReduxState,
  clearItemData,
  limpiarPromociones,
} from "../../../actions/candyActions";
import ItemCarrito from "./ItemCarrito";
import PromocionCarrito from "./PromocionCarrito";
import PreferenciasCarrito from "./PreferciasCarrito";
import { IoAlertCircle } from "react-icons/io5";

function CarritoContainer({
  handleCloseModal,
  codigosCanjeadosModal,
  setCodigosCanjeadosModal,
}) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.DatosCandy.item);
  const promociones = useSelector((state) => state.DatosCandy.promociones);
  const subTotal = useSelector((state) => state.DatosCandy.subtotalModal);
  const countState = useSelector((state) => state.DatosCandy.countItem);
  const lockSelectItem = useSelector(
    (state) => state.DatosCandy.lockSelectItem
  );
  const cantidadObligatorias = useSelector(
    (state) => state.DatosCandy.cantidadObligatorias
  );
  const sumatoriaObligatorias = useSelector(
    (state) => state.DatosCandy.sumatoriaObligatorias
  );
  const unidad = useSelector((state) => state.DatosCandy.unidad);

  const handleActive = () => {
    if (countState === 0) {
      return true;
    }
    if (!lockSelectItem) {
      return true;
    }
    if (cantidadObligatorias === sumatoriaObligatorias) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box>
      <Stack
        overflow="auto"
        maxHeight="550px"
        direction="column"
        spacing={0.5}
        sx={{
          padding: "1.5rem",
          border: "1px solid #dee2e6",
          bgcolor: "cards.gray",
          borderRadius: "0.3rem",
        }}
      >
        <Typography fontSize="1.5rem" fontWeight="600" paddingLeft=".2rem">
          Preferencias
        </Typography>
        <ItemCarrito></ItemCarrito>
        {promociones &&
          promociones.map((promocion) => {
            return (
              <PromocionCarrito
                key={promocion.cupon.id}
                promocion={promocion}
              />
            );
          })}
        <PreferenciasCarrito></PreferenciasCarrito>
        {handleActive() ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IoAlertCircle
              color="#ff0000"
              size={20}
              style={{ marginRight: "0.5rem" }}
            />
            <Typography fontSize="1rem" padding=".5rem">
              No se han seleccionado las opciones necesarias, por favor elija
              sus preferencias.
            </Typography>
          </div>
        ) : (
          <div></div>
        )}

        <Button
          id="CandyModal-btn-Continue"
          disabled={handleActive()}
          variant="contained"
          color="secondary"
          size="large"
          disableElevation
          fullWidth
          sx={{
            padding: "1.5rem",
            fontSize: "1.4rem",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
          onClick={() => {
            handleCloseModal();
            let subTotalConDescuento = subTotal;
            if (promociones.length > 0) {
              promociones.forEach(
                (promocion) =>
                  (subTotalConDescuento =
                    subTotalConDescuento - promocion.precioDescuento)
              );
            }
            dispatch(
              agregarCandyCarritoGeneral(item, subTotalConDescuento, unidad)
            );
            dispatch(agregarCodigo(codigosCanjeadosModal));
            setCodigosCanjeadosModal([]);
            dispatch(limpiarPromociones());
            dispatch(clearItemData());
            dispatch(candyResetReduxState());
          }}
        >
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          sx={{
            padding: "1.5rem",
            fontSize: "1.4rem",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
          onClick={() => {
            handleCloseModal();
            setCodigosCanjeadosModal([]);
            dispatch(limpiarPromociones());
            dispatch(clearItemData());
            dispatch(candyResetReduxState());
          }}
        >
          Cancelar
        </Button>
      </Stack>
    </Box>
  );
}

export default CarritoContainer;
