import {
  AGREGAR_BUTACA,
  CARGAR_BUTACAS,
  ELIMINAR_BUTACA,
  RESET_REDUX_STATE,
  SET_ERROR_WS,
} from "../actionTypes/actionTypes";

const cargarButacas = (url, cine, funcion) => {
  return async function (dispatch) {
    await fetch(
      `${url}butaca/obtenerDisponiblesPorFuncion?complejo_id=${cine}&funcion_id=${funcion}`
    )
      .then((response) => response.json())
      .then((respuesta) => {
        return dispatch({
          type: CARGAR_BUTACAS,
          payload: {
            butaca: respuesta.butacas,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        return dispatch({ type: SET_ERROR_WS });
      });
  };
};

const agregarButaca = (butaca) => {
  return {
    type: AGREGAR_BUTACA,
    payload: {
      butaca: butaca,
    },
  };
};

const eliminarButaca = (butaca) => {
  return {
    type: ELIMINAR_BUTACA,
    payload: {
      butaca: butaca,
    },
  };
};

const butacasResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

export { cargarButacas, agregarButaca, eliminarButaca, butacasResetReduxState };
