import {
  CARGAR_CONFIGURACION_COMPLEJO,
  CARGAR_DATOS_EMPRESA,
  RESET_REDUX_STATE,
} from "../actionTypes/actionTypes";

const initialState = {
  url_api_complejo: "",
  url_boleteria: "",
  url_api_front: "",
  payment_gateways: {},
  datosEmpresa: false,
  datosComplejo: false,
};

const complejoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARGAR_CONFIGURACION_COMPLEJO:
      let urlBoleteria = action.payload.url_api_complejo.replace(
        "apicomplejo",
        "Boleteria"
      );
      return {
        ...state,
        url_api_complejo: action.payload.url_api_complejo,
        url_boleteria: urlBoleteria,
        url_api_front: action.payload.url_api_front,
        payment_gateways: action.payload.payment_gateways,
        datosComplejo: action.payload.datosComplejo.datos,
      };
    case CARGAR_DATOS_EMPRESA:
      return {
        ...state,
        datosEmpresa: action.payload,
      };
    case RESET_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
};

export default complejoReducer;
