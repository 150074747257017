import { Grid, Stack, Typography } from "@mui/material";
import CandyCard from "./CandyCard";

export default function Candy({ data }) {
  return (
    <Stack direction="column" width="auto" minWidth="40%" marginLeft={3}>
      <Typography fontSize={18} marginBottom={1}>
        Candy:
      </Typography>
      {data.candy ? (
        <Grid container overflow="auto" alignContent="center">
          <Grid item xs={3}>
            <Typography fontSize={16} fontWeight="bold">
              Cant.
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography fontSize={16} fontWeight="bold">
              Producto
            </Typography>
          </Grid>
          {data.detalleCandy &&
            data.detalleCandy.map((item, index) => {
              return <CandyCard item={item} index={index} />;
            })}
        </Grid>
      ) : (
        <Typography color="#b71c1c" fontSize={14}>
          No se adquirieron productos de Candy o éstos ya fueron canjeados.
          Comuníquese con el personal del cine por favor.
        </Typography>
      )}
    </Stack>
  );
}
