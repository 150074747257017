import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#25B9E7",
      light: "#DCF4FC",
    },
    secondary: {
      main: "#268C90",
      dark: "#1D696D",
    },
    cards: {
      gray: "#F2EFEF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
  input: {
    "&:disabled": {
      color: "black",
    },
  },
});

export default theme;
