import { Stack } from "@mui/material";
import React from "react";
import ItemCard from "./ItemCard";
import ProductoCard from "./Producto/ProductoCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItemData } from "../../../actions/candyActions";
import SelectComboDinamico from "./ProductoComboDinamico/SelectComboDinamico";
import ComboCard from "./ProductoComboDinamico/ComboCard";
import apihost from "../../../config/apiHost.json";

function ItemContainer({ item }) {
  const itemData = useSelector((state) => state.DatosCandy.itemData);
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const [productosCombo, setProductosCombo] = useState([]);
  const [seleccionProdCombo, setSeleccionProdCombo] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setItemData(item));
  }, [item, dispatch]);

  const fetchProductosCombo = async (cine, id) => {
    await fetch(
      `${apihost.REACT_APP_URL_CINEXO}complejo/${cine}/candy/${id}/productos`
    )
      .then((response) => response.json())
      .then((res) => setProductosCombo(res.productos));
  };

  useEffect(() => {
    if (item.tipo === "combo" && item.formato === "dinamico") {
      fetchProductosCombo(datosFuncion.cine_id, item.id);
    }
  }, [item, datosFuncion, dispatch]);

  const renderizarProductos = () => {
    if (itemData.productos && itemData.productos.length > 0) {
      return itemData.productos.map((p) => {
        return <ProductoCard producto={p} key={p.id}></ProductoCard>;
      });
    }
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      marginX="auto"
      maxHeight={{ xs: "550px", md: "600px" }}
      width="100%"
    >
      {seleccionProdCombo &&
      item.tipo === "combo" &&
      item.formato === "dinamico" ? (
        <>
          <ComboCard item={item} />
          <SelectComboDinamico
            setSeleccionProdCombo={setSeleccionProdCombo}
            productos={productosCombo}
            itemData={itemData}
          />
        </>
      ) : (
        <>
          <ItemCard key={item.id} item={item} />
          <Stack overflow="auto" width="inherit">
            {renderizarProductos()}
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default ItemContainer;
