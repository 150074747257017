import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agregarCodigosCanjeadosTarifas } from "../../../actions/tarifasAction";
import Keyboard from "react-simple-keyboard";
import apihost from "../../../config/apiHost.json";

function FormEntidad({
  entidad,
  handleAgregarTarifas,
  setEntidad,
  tarifa,
  cine,
  funcion,
  setOpen,
  setShowForm,
}) {
  const entradas = JSON.parse(entidad.inputs);
  const codigosCanjeados = useSelector(
    (state) => state.DatosTarifa.codigosCanjeados
  );
  const dispatch = useDispatch();
  const [buttonStatus, setButtonStatus] = useState(false);

  const [formError, setFormError] = useState({
    status: false,
    mensaje: "",
  });

  const keyboard = useRef();
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [inputs, setInputs] = useState({
    funcion_id: funcion,
    tipo: "entrada",
  });
  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState(null);

  const onChangeAll = (inputs) => {
    setInputs({ ...inputs });
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    setFormError({
      status: false,
      mensaje: "",
    });
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;

    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (inputName) => {
    return inputs[inputName] || "";
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setButtonStatus(true);
    setKeyboardVisibility(false);
    let datosEnviar = {
      partner_code: entidad.id,
      partner_name: entidad.nombre,
      complejo_id: cine,
      promotion_code: entidad.idPromocion,
      data: {
        funcion_id: funcion,
        tipo: "entrada",
        ...inputs,
      },
    };
    await fetch(`${apihost.REACT_APP_URL_CINEXO}promocion/validar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datosEnviar),
    })
      .then((response) => response.json())
      .then((respuesta) => {
        if (respuesta.valido === true) {
          if (
            codigosCanjeados.some(
              (data) => data.data.codigo === datosEnviar.data.codigo
            )
          ) {
            setFormError({
              status: true,
              mensaje: "El cupón ya fue canjeado",
            });
            setButtonStatus(false);
          } else {
            let promotion = {
              ...datosEnviar,
              estadoPromo: respuesta.datos.estado,
              configEntidad: respuesta.datos.configEntidad,
              importe: tarifa.precio,
              descuento: respuesta.datos.cupon.descuento,
              tipoDescuento: respuesta.datos.cupon.tipoDescuento,
            };
            console.log(promotion);
            handleAgregarTarifas(tarifa, JSON.stringify(promotion));
            dispatch(agregarCodigosCanjeadosTarifas(datosEnviar));
            setShowForm(false);
            setTimeout(() => {
              setOpen(false);
            }, 2000);
          }
        } else {
          setFormError({
            status: true,
            mensaje: respuesta.mensaje,
          });
          setButtonStatus(false);
        }
      });
  };

  return (
    <FormControl sx={{ marginY: 2 }}>
      <Stack direction="column" spacing={2} marginX={3} sx={{ marginY: 3 }}>
        {entradas?.map((entrada, index) => {
          return (
            <Stack direction="column" gap={3}>
              {entrada.validaciones?.length > 0 ? (
                <TextField
                  id={entrada.nombre}
                  error={formError.status}
                  variant="outlined"
                  inputProps={{
                    name: entrada.nombre,
                    type: entrada.tipo,
                    style: {
                      fontSize: "1.6rem",
                    },
                  }}
                  label={
                    entrada.placeholder ? entrada.placeholder : entrada.nombre
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: "1.4rem",
                    },
                  }}
                  max={Number(entrada.validaciones.max)}
                  min={Number(entrada.validaciones.min)}
                  onChange={onChangeInput}
                  key={entrada.nombre}
                  value={getInputValue(entrada.nombre)}
                  onFocus={() => {
                    setKeyboardVisibility(true);
                    setInputName(entrada.nombre);
                  }}
                  className="input"
                />
              ) : (
                <TextField
                  id={entrada.nombre}
                  error={formError.status}
                  variant="standard"
                  inputProps={{
                    name: entrada.nombre,
                    type: entrada.tipo,
                    style: {
                      fontSize: "1.6rem",
                    },
                  }}
                  label={
                    entrada.placeholder ? entrada.placeholder : entrada.nombre
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: "1.4rem",
                    },
                  }}
                  onChange={onChangeInput}
                  key={entrada.nombre}
                  autoFocus={true}
                  value={getInputValue(entrada.nombre)}
                  onFocus={() => {
                    setKeyboardVisibility(true);
                    setInputName(entrada.nombre);
                  }}
                  className="input"
                />
              )}
            </Stack>
          );
        })}
        {formError.status ? (
          <Typography fontSize="1.2rem" color="error.main">
            {formError.mensaje}
          </Typography>
        ) : (
          ""
        )}
        {keyboardVisibility && (
          <Keyboard
            className="keyboard"
            keyboardRef={(r) => (keyboard.current = r)}
            inputName={inputName}
            layoutName={layoutName}
            onChangeAll={onChangeAll}
            onKeyPress={onKeyPress}
          />
        )}
      </Stack>
      <Stack
        direction="row-reverse"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        marginX={3}
      >
        <Button
          id="btn-entity-aceptar"
          variant="contained"
          color="secondary"
          type="submit"
          size="large"
          sx={{
            padding: "1.4rem",
            fontSize: "1.4rem",
            fontWeight: "700",
            width: "100%",
          }}
          disabled={buttonStatus ? true : false}
          onClick={handleSubmit}
        >
          Aceptar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => {
            setOpen(false);
            setEntidad(null);
          }}
          sx={{
            padding: "1.4rem",
            fontSize: "1.4rem",
            fontWeight: "700",
            width: "100%",
          }}
        >
          Cancelar
        </Button>
      </Stack>
    </FormControl>
  );
}

export default FormEntidad;
