import {
  BORRAR_DATOS_FUNCION,
  CARGAR_DATOS_PELICULA,
  CARGAR_INFO_PELICULA,
  RESET_REDUX_STATE,
} from "../actionTypes/actionTypes";

const initialState = {
  datos: [],
  infoPelicula: false,
  vendeCandy: 0,
  vendeNumeradas: 0,
};

const peliculaReducer = (state = initialState, action) => {
  // ** const peliculaReducer = ( state = initial...)
  switch (action.type) {
    case CARGAR_DATOS_PELICULA:
      return {
        ...state, //hacemos copia del state (no necesario en este caso,
        //necesario si tuviesemos alguna variable fuera del array de peliculas que tengamos que cambiar)
        datos: action.payload, //agregamos los datos de la pelicula
        vendeCandy: action.payload.vende_candy,
        vendeNumeradas: action.payload.vendeNumeradas,
      };

    case CARGAR_INFO_PELICULA:
      return {
        ...state,
        infoPelicula: action.payload.data,
      };

    case BORRAR_DATOS_FUNCION:
      let newPosts = state.datosPelicula.filter((pelicula) => {
        return action.funcion !== pelicula.funcion; //filtramos por el id de pelicula que recibe
      });
      return {
        ...state,
        pelicula: newPosts,
      };
    case RESET_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
};

export default peliculaReducer;
