import { Box, Divider, Stack, Typography } from "@mui/material";
import FormCanje from "../Components/Canje/FormCanje";
import Numpad from "../Components/Canje/Numpad";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import ModalCanje from "../Components/Canje/ModalCanje";
import { useSelector } from "react-redux";
import TimerCanje from "../Components/Canje/TimerCanje";
import useCurrentWindow from "../hooks/useCurrentWindow";
import apihost from "../config/apiHost.json";

export default function Canje() {
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const location = useLocation();
  const windowSize = useCurrentWindow();
  const urlParams = new URLSearchParams(location.search);
  const complejo_id = urlParams.get("cid");
  const device_id = urlParams.get("did");
  const [codigo, setCodigo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    estado: false,
    mensaje: "",
  });
  const [respuestaCanje, setRespuestaCanje] = useState(null);
  const [backDisabled, setBackDisabled] = useState(true);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  useEffect(() => {
    if (datosComplejo?.ip_privada !== undefined) setBackDisabled(false);
  }, [datosComplejo]);

  const handleClick = (value) => {
    if (error.estado)
      setError({
        estado: false,
        mensaje: "",
      });
    if (respuestaCanje !== null) setRespuestaCanje(null);
    if (codigo === "") {
      if (value !== "Borrar") setCodigo(value);
      else if (value === "Borrar") console.log("No se puede borrar");
    }
    if (codigo !== "") {
      if (value !== "Borrar") setCodigo((codigo) => codigo + value);
      else if (value === "Borrar") setCodigo((codigo) => codigo.slice(0, -1));
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError({
      estado: false,
      mensaje: "",
    });
    if (codigo === "") {
      setError({
        estado: true,
        mensaje: "Dato Requerido",
      });
      setIsLoading(false);
    } else {
      let datos = {
        txtNumeroReferencia: codigo,
        complejo_id,
        idATM: device_id,
      };

      await axios({
        method: "POST",
        url: `${apihost.REACT_APP_URL_CINEXO}impresiones/canje`,
        data: JSON.stringify(datos),
        responseType: "json",
      })
        .then((res) => {
          if (res.data === null) {
            setError({
              estado: true,
              mensaje: "Código inválido",
            });
          } else if (res.data.error) {
            setError({
              estado: true,
              mensaje: "Código inválido",
            });
          } else {
            setRespuestaCanje(res.data.datos.data.venta);
            setOpen(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <Box
      marginTop={12}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "5px", md: "20px" },
      }}
    >
      <Typography variant="h2" marginBottom={2}>
        Canjear Entrada Web
      </Typography>
      <Stack
        direction={isVertical ? "column" : "row"}
        margin="auto"
        gap="2rem"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={isVertical ? "cards.gray" : ""}
        paddingY={3}
      >
        <Stack bgcolor="cards.gray" padding="1.2rem" borderRadius="6px">
          <TimerCanje />
          <Box marginY={2}>
            <Divider />
          </Box>
          <FormCanje
            codigo={codigo}
            error={error}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            setCodigo={setCodigo}
            datosComplejo={datosComplejo}
            deviceId={device_id}
            backDisabled={backDisabled}
          />
        </Stack>
        <Numpad setCodigo={setCodigo} handleClick={handleClick} />
      </Stack>
      {respuestaCanje ? (
        <ModalCanje
          open={open}
          setOpen={setOpen}
          data={respuestaCanje}
          setRespuestaCanje={setRespuestaCanje}
          complejoId={complejo_id}
          setCodigo={setCodigo}
          datosComplejo={datosComplejo}
          deviceId={device_id}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
