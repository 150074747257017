import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Carrito from "../Components/Carrito/Carrito";
import PeliculaSimple from "../Components/Entradas/Pelicula/PeliculaSimple";
import Headline from "../Components/HeadlineTimer/Headline";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useCurrentWindow from "../hooks/useCurrentWindow";
import FidelizacionContainer from "../Components/Fidelizacion/FidelizacionContainer";
import { Link } from "react-router-dom";

function Fidelizacion() {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useCurrentWindow();
  const titleHeading = "Completa tu compra";
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPage, setNextPage] = useState(`${location.search}`);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [dispatch, isLoading, datosFuncion]);

  useEffect(() => {
    setNextPage(`/payment-methods${location.search}`);
  }, [location]);

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  return isLoading ? (
    ""
  ) : (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "10px", md: "5px" },
      }}
    >
      <Grid container spacing="2rem">
        <Grid
          item
          xs={isVertical ? 12 : 8}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <PeliculaSimple />
          <Headline title={titleHeading} />
          <FidelizacionContainer />
        </Grid>
        <Grid
          item
          xs={isVertical ? 12 : 4}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <Carrito
            nextPage={nextPage}
            isVertical={isVertical}
            disabledCancel={false}
            step={"fidelizacion"}
            to={`/candy${location.search}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Fidelizacion;
