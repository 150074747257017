import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  CircularProgress,
  Typography,
  Dialog,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useCurrentWindow from "../hooks/useCurrentWindow";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import Headline from "../Components/HeadlineTimer/Headline";
import Pelicula from "../Components/Entradas/Pelicula/Pelicula";
import Carrito from "../Components/Carrito/Carrito";
import { setOnlyCandy, unsetOnlyCandy } from "../actions/carritoActions";

import qz from "qz-tray";
import { redirect } from "../utils/helpers";
import { LoadingButton } from "@mui/lab";

export default function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useCurrentWindow();
  const [isVertical, setIsVertical] = useState(false);
  const titleHeadline = "Seleccione el tipo de compra";
  const pelicula = useSelector((state) => state.DatosPelicula.datos.pelicula);
  const device_id = useSelector((state) => state.DatosPayment.deviceId);
  const ip_privada = useSelector(
    (state) => state.DatosComplejo.datosComplejo.ip_privada
  );
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );

  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState("");
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [disableEntradas, setDisableEntradas] = useState(false);
  const [disableCandy, setDisableCandy] = useState(false);
  const [nextPage, setNextPage] = useState("");
  const [printerStatus, setPrinterStatus] = useState(null);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  useEffect(() => {
    if (pelicula) {
      setIsLoading(false);
      // if (pelicula.vendeNumeradas === "0") setDisableEntradas(true);
      if (pelicula.vende_candy === "0") setDisableCandy(true);
    }
  }, [pelicula]);

  useEffect(() => {
    if (selected !== "") {
      setDisabledContinue(false);
      if (selected === "tarifas") {
        setNextPage(`/tarifas${location.search}`);
      } else if (selected === "candy") {
        setNextPage(`/candy${location.search}`);
      }
    } else setDisabledContinue(true);
  }, [selected, dispatch, location]);

  useEffect(() => {
    qz.printers.setPrinterCallbacks((evt) => {
      console.log(evt.statusText);
      setPrinterStatus(evt.statusText);
    });
  }, []);

  const handleSelect = (type) => {
    if (selected === type) {
      if (type === "candy") dispatch(unsetOnlyCandy());
      setSelected("");
    } else if (selected !== type) {
      if (type === "candy") {
        dispatch(setOnlyCandy());
      } else {
        dispatch(unsetOnlyCandy());
      }
      setSelected(type);
    }
  };

  return (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "10px", md: "5px" },
      }}
    >
      {printerStatus !== null && printerStatus !== "OK" ? (
        <Dialog open={modalOpen} maxWidth="xs" fullWidth>
          <Box bgcolor="error.main" textAlign="center" color="white">
            <Stack
              margin={3}
              marginTop={10}
              direction="column"
              alignItems="center"
              spacing={2}
            >
              <CancelOutlinedIcon sx={{ fontSize: "10rem" }} />
              <Typography fontSize="1.8rem">¡Ups!</Typography>
            </Stack>
          </Box>
          <Box textAlign="center">
            <Stack
              direction="column"
              margin={3}
              spacing={2}
              alignItems="center"
            >
              <Stack direction="row" gap={0.5}>
                <Typography fontSize="1.6rem">Error en impresora: </Typography>
                <Typography
                  fontSize="1.6rem"
                  color="error.light"
                  fontWeight="bold"
                >
                  {printerStatus}
                </Typography>
              </Stack>
              <Typography fontSize="1.3rem">
                Por favor, comuníquese con caja
              </Typography>
              <Stack direction="row" gap={1}>
                <LoadingButton
                  loading={!datosComplejo.ip_privada}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  marginTop={2}
                  fullWidth
                  sx={{
                    padding: "1rem",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                  onClick={() => {
                    redirect(
                      datosComplejo.ip_privada,
                      datosComplejo.version_boleteria,
                      device_id
                    );
                  }}
                >
                  VOLVER AL INICIO
                </LoadingButton>
                <Button
                  disabled={process.env.NODE_ENV === "desarrollo"}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  fullWidth
                  sx={{
                    padding: "1rem",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  Continuar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Dialog>
      ) : (
        ""
      )}
      <Grid container spacing="2rem">
        <Grid item xs={windowSize.width < windowSize.height ? 12 : 8}>
          <Pelicula />
          <Headline title={titleHeadline} />
          {isLoading ? (
            <Stack alignItems="center" margin="auto" marginY={5}>
              <CircularProgress color="secondary" />
            </Stack>
          ) : (
            <Grid container marginTop="4rem" marginX={0}>
              <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
                <Button
                  disabled={disableEntradas}
                  variant={selected === "tarifas" ? "contained" : "outlined"}
                  color="secondary"
                  size="large"
                  sx={{
                    padding: "1.5rem",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                  disableElevation
                  onClick={() => handleSelect("tarifas")}
                >
                  Entrada/Candy
                </Button>
              </Grid>
              <Grid item xs={6} alignContent={"center"} textAlign={"center"}>
                <Button
                  disabled={disableCandy}
                  variant={selected === "candy" ? "contained" : "outlined"}
                  color="secondary"
                  size="large"
                  sx={{
                    padding: "1.5rem",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                  disableElevation
                  onClick={() => handleSelect("candy")}
                >
                  Solo Candy
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={isVertical ? 12 : 4}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <Carrito
            nextPage={nextPage}
            isVertical={isVertical}
            disabledCancel={false}
            disabledContinue={disabledContinue}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
