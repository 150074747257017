import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agregarDataUsuario } from "../../actions/fidelizacionActions";
import apihost from "../../config/apiHost.json";

function BuscadorUsuario({ setUserData, setDisabledInput }) {
  const dispatch = useDispatch();
  const complejo_id = useSelector((state) => state.DatosFuncion.cine_id);
  const [search, setSearch] = useState("");
  const [formError, setFormError] = useState({
    status: false,
    mensaje: "",
  });

  const handleChange = (evt) => {
    setFormError({
      status: false,
      mensaje: "",
    });
    const { value } = evt.target;
    setSearch(value);
  };

  const handleSubmit = async () => {
    await fetch(
      `${apihost.REACT_APP_URL_CINEXO}clientes/obtenerPorEmail?email=${search}&complejo_id=${complejo_id}`
    )
      .then((res) => res.json())
      .then((respuesta) => {
        console.log(respuesta);
        if (!respuesta.error) {
          let data = {
            nombre: respuesta.datos.nombre,
            apellido: respuesta.datos.apellido,
            email: respuesta.datos.email,
            documento: respuesta.datos.dni_cuit_cuil,
          };
          setUserData(data);
          dispatch(agregarDataUsuario(data));
          setDisabledInput(true);
        } else {
          setFormError({
            status: true,
            mensaje: respuesta.error.message,
          });
        }
      });
  };

  return (
    <Stack
      direction="column"
      bgcolor="cards.gray"
      paddingY="0.8rem"
      paddingX="1.2rem"
      marginY={3}
      borderRadius={1}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <TextField
          color="secondary"
          placeholder="Ingrese su correo electrónico o escanee el código de cliente"
          fullWidth
          size="small"
          inputProps={{
            style: {
              fontSize: "1.2rem",
              backgroundColor: "white",
            },
          }}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: "100%",
            paddingX: "25px",
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
          disableElevation
          onClick={handleSubmit}
        >
          Buscar
        </Button>
      </Stack>
      {formError.status ? (
        <Stack
          width="auto"
          bgcolor="#ffcdd2"
          borderRadius={0.5}
          border="1px solid #d32f2f"
          padding={0.5}
          marginTop={1}
        >
          <Typography fontSize="1.2rem" color="#b71c1c">
            {formError.mensaje}
          </Typography>
        </Stack>
      ) : (
        ""
      )}
    </Stack>
  );
}

export default BuscadorUsuario;
