import {
  PAYMENT_CANCEL_INTENTION,
  PAYMENT_ERROR_VENTAS_TEMPORALES,
  PAYMENT_ERROR,
  PAYMENT_GENERAR_INTENCION_DE_PAGO,
  PAYMENT_INCREMENT_RETRY,
  PAYMENT_POINT_RETRY,
  PAYMENT_RETRY_QR,
  PAYMENT_SET_DATA_TO_SEND,
  PAYMENT_SET_METHOD,
  PAYMENT_SET_QR_DATA,
  RESET_PAYMENT_ID,
  RESET_QR_DATA,
  RESET_REDUX_STATE,
  STATUS_PAYMENT,
  PAYMENT_DALBE_ERROR,
  PAYMENT_SET_RESPONSE_VT,
  SET_STATUS_SETP3,
} from "../actionTypes/actionTypes";
import {
  cancelPaymentIntention,
  getTramaPreVenta,
  newAmount,
  paymentWithCash,
  paymentWithPoint,
  paymentWithQR,
  ventasTemporales,
} from "../Components/Payment-Methods/utils/requests";
import { printCashPayment } from "../utils/impresion";

let setProducts = (products) => {
  let productos = products.map((prod) => {
    let preferencias = prod.preferences.map((pref) => {
      return {
        name: pref.name,
        id: pref.key ? pref.key : pref.id,
        product_id: pref.key_product ? pref.key_product : pref.product_id,
        quantity: pref.quantity,
        increment: pref.increment,
      };
    });
    return {
      code: prod.code,
      name: prod.name,
      quantity: prod.quantity,
      preferences: preferencias,
    };
  });
  return productos;
};

const setDataToSend = (
  cuponesVenta,
  descuentoVenta,
  dataUsuario,
  datosCandy,
  dataCandy,
  dataFuncion,
  dataTarifas,
  cashSelected,
  datosComplejo
) => {
  let dataUnidadesNegocio = [];

  if (datosCandy.tiene_unidades_negocios) {
    dataUnidadesNegocio = datosCandy.unidades_negocios.map((u) => {
      return {
        business_unit_code: u.id,
        total: 0,
        items: [],
      };
    });
  }

  let candy = dataUnidadesNegocio;
  let amountTicket = 0;
  let amountCandy = 0;

  if (dataTarifas && dataTarifas.length > 0) {
    for (let i = 0; i < dataTarifas.length; i++) {
      amountTicket = amountTicket + Number(dataTarifas[i].precio);
    }
  }

  let tickets = dataTarifas?.map((tarifa) => {
    return {
      tariff_code: tarifa._id,
      selected_seat: tarifa.butaca.butaca ? tarifa.butaca.butaca : "",
      promotion: tarifa.promotion,
      discount: 0,
    };
  });

  if (dataCandy && dataCandy.length > 0) {
    for (let i = 0; i < dataCandy.length; i++) {
      for (let j = 0; j < candy.length; j++) {
        if (candy[j].business_unit_code === dataCandy[i].unidad) {
          let obj = {
            type: dataCandy[i].type,
            price: dataCandy[i].price,
            name: dataCandy[i].name,
            quantity: dataCandy[i].quantity,
            code: dataCandy[i].code,
            promotion: dataCandy[i].promotion,
            tax: dataCandy[i].tax,
            item: i,
            products: setProducts(dataCandy[i].products),
          };
          candy[j].total =
            candy[j].total + Number(obj.price) * Number(obj.quantity);
          amountCandy = amountCandy + Number(obj.price) * Number(obj.quantity);
          candy[j].items.push(obj);
        }
      }
    }
  }

  let amount = {
    ticket: amountTicket,
    candy: amountCandy,
    total: Number(amountTicket) + Number(amountCandy),
    discount: 0,
    sale_discount: descuentoVenta,
  };

  candy = candy.filter((c) => c.items.length > 0);

  let dataToSend = {
    full_name: `${dataUsuario.nombre} ${dataUsuario.apellido}`,
    customer_email: dataUsuario.email,
    payer_email: dataUsuario.email,
    dni_number: dataUsuario.documento ? dataUsuario.documento : null,
    phone_number: dataUsuario.phone_number ? dataUsuario.phone_number : null,
    function_code: dataFuncion.funcion_id,
    complex_code: dataFuncion.cine_id,
    origin: "atm-cinexo",
    delivery: null,
    gateway: cashSelected
      ? "cinema"
      : datosComplejo.payment_gateways[0].gateway,
    process_type: "reserva",
    cash_on_delivery: cashSelected ? true : false,
    candy,
    amount,
    coupons: cuponesVenta,
    ticket: tickets,
  };

  console.log(dataToSend);

  return {
    type: PAYMENT_SET_DATA_TO_SEND,
    payload: {
      dataToSend,
    },
  };
};

const postVentasTemporales = (
  data,
  method,
  device_id,
  external_id,
  codigo_pais
) => {
  return async function (dispatch) {
    try {
      console.log(data, method);
      let responseStep1 = await ventasTemporales(data);
      console.log("step 1", responseStep1);
      if (method !== "CASH") {
        if (responseStep1 && responseStep1.data.success === true) {
          dispatch({
            type: PAYMENT_SET_RESPONSE_VT,
            payload: {
              data: responseStep1.data,
            },
          });
          let responseStep2 = await newAmount(
            responseStep1.data,
            responseStep1.data.data.mercadoPago.hash
          );
          console.log("step 2", responseStep2);
          if (responseStep2.data.success) {
            if (method === "POINT") {
              let responseStep3 = await paymentWithPoint(
                responseStep1.data,
                data,
                device_id,
                codigo_pais,
                responseStep1.data.data.mercadoPago.hash
              );
              console.log("step 3", responseStep3);
              if (
                responseStep3 &&
                responseStep3.data &&
                responseStep3.data.success
              ) {
                return dispatch({
                  type: PAYMENT_GENERAR_INTENCION_DE_PAGO,
                  payload: {
                    paymentID: responseStep3.data.data.body.id,
                    referenceID:
                      responseStep1.data.data.mercadoPago.referenceId,
                    responseStep1: responseStep1.data,
                    subtype: responseStep2.data.data.gatewayData.subtype,
                    hash: responseStep1.data.data.mercadoPago.hash,
                  },
                });
              } else {
                return dispatch({
                  type: PAYMENT_DALBE_ERROR,
                });
              }
            }
            if (method === "QR") {
              let responseStep3 = await paymentWithQR(
                responseStep1.data,
                data,
                external_id,
                responseStep1.data.data.mercadoPago.hash
              );
              console.log("step 3", responseStep3);
              if (
                responseStep3 &&
                responseStep3.data &&
                responseStep3.data.success
              ) {
                return dispatch({
                  type: PAYMENT_SET_QR_DATA,
                  payload: {
                    qrData: responseStep3.data.data.response.qr_data,
                    referenceID:
                      responseStep1.data.data.mercadoPago.referenceId,
                    responseStep1: responseStep1.data,
                    subtype: responseStep2.data.data.gatewayData.subtype,
                    hash: responseStep1.data.data.mercadoPago.hash,
                  },
                });
              } else {
                return dispatch({
                  type: PAYMENT_DALBE_ERROR,
                });
              }
            }
          }
        } else {
          console.log("errror");
          //Control de error
          return dispatch({
            type: PAYMENT_ERROR_VENTAS_TEMPORALES,
          });
        }
      } else {
        let responseStep2 = await paymentWithCash(responseStep1.data, data);
        console.log("step2", responseStep2);
        if (responseStep2.data.success === true) {
          let dataSend = {
            codigo: responseStep1.data.data.cinema.paymentCode,
            preventa: true,
            cliente: data.full_name,
            complejo_id: responseStep1.data.data.cinema.complexCode,
          };
          console.log("dataSend", dataSend);
          let responsePreVenta = await getTramaPreVenta(dataSend);
          if (responsePreVenta.error) {
            return dispatch({
              type: STATUS_PAYMENT,
              payload: {
                statusPaymentCash: {
                  status: "rejected",
                  data: null,
                  message: "Error al imprimir el código",
                },
              },
            });
          } else {
            printCashPayment(responsePreVenta.datos);
            return dispatch({
              type: STATUS_PAYMENT,
              payload: {
                statusPaymentCash: {
                  status: "approved",
                  data: responseStep1.data.data,
                },
              },
            });
          }
        } else {
          return dispatch({
            type: STATUS_PAYMENT,
            payload: {
              statusPaymentCash: {
                status: "rejected",
                data: {
                  message: "Error al realizar la compra",
                },
              },
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
      return dispatch({
        type: PAYMENT_ERROR,
        // payload: {
        //   statusPaymentCash: {
        //     status: "rejected",
        //     data: {
        //       message: error.response.data.description,
        //     },
        //   },
        // },
      });
    }
  };
};

const setPaymentMethod = (method) => {
  return {
    type: PAYMENT_SET_METHOD,
    payload: {
      method,
    },
  };
};

const clearStatusPayment = () => {
  return {
    type: STATUS_PAYMENT,
    payload: {
      statusPaymentCash: null,
    },
  };
};

const paymentResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

const retryPaymentPoint = (data, dataStep1, device_id, hash) => {
  return async function (dispatch) {
    let responseRetry = await paymentWithPoint(
      dataStep1,
      data,
      device_id,
      "AR",
      hash
    );
    console.log("Retry", responseRetry);
    if (responseRetry.data.success) {
      return dispatch({
        type: PAYMENT_POINT_RETRY,
        payload: {
          paymentID: responseRetry.data.data.body.id,
          referenceID: dataStep1.data.mercadoPago.referenceId,
          responseStep1: dataStep1,
        },
      });
    } else {
      //Control de error
    }
  };
};

const resetPaymentID = () => {
  return {
    type: RESET_PAYMENT_ID,
  };
};

const incrementRetry = () => {
  return {
    type: PAYMENT_INCREMENT_RETRY,
  };
};

const resetQRData = () => {
  return {
    type: RESET_QR_DATA,
  };
};

const retryPaymentQR = (dataStep1, data, external_id, hash) => {
  return async function (dispatch) {
    let responseRetry = await paymentWithQR(dataStep1, data, external_id, hash);
    console.log("Retry", responseRetry);
    return dispatch({
      type: PAYMENT_RETRY_QR,
      payload: {
        qrData: responseRetry.data.data.response.qr_data,
        referenceID: dataStep1.data.mercadoPago.referenceId,
      },
    });
  };
};

const cancelPayment = (deviceID, paymentID, complexCode) => {
  return async function (dispatch) {
    const id = await cancelPaymentIntention(deviceID, paymentID, complexCode);
    return dispatch({
      type: PAYMENT_CANCEL_INTENTION,
    });
  };
};

const setDataToSendConvertion = (dataToSend, tasaConversion) => {
  let dataToSendCopy = dataToSend;
  if (dataToSendCopy.amount.ticket > 0) {
    dataToSendCopy.amount.ticket = Number(
      dataToSendCopy.amount.ticket / tasaConversion
    ).toFixed(2);
  }
  if (dataToSendCopy.amount.candy > 0) {
    dataToSendCopy.amount.candy = Number(
      dataToSendCopy.amount.candy / tasaConversion
    ).toFixed(2);
  }
  if (dataToSendCopy.amount.total > 0) {
    dataToSendCopy.amount.total = Number(
      dataToSendCopy.amount.total / tasaConversion
    ).toFixed(2);
  }
  return {
    type: PAYMENT_SET_DATA_TO_SEND,
    payload: {
      dataToSend,
    },
  };
};

const setStatusStep3 = (status) => {
  return {
    type: SET_STATUS_SETP3,
    payload: {
      status: status,
    },
  };
};

export {
  setDataToSend,
  postVentasTemporales,
  setPaymentMethod,
  clearStatusPayment,
  paymentResetReduxState,
  retryPaymentPoint,
  resetPaymentID,
  incrementRetry,
  resetQRData,
  retryPaymentQR,
  cancelPayment,
  setDataToSendConvertion,
  setStatusStep3,
};
