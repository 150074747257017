import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sala from "./Sala";
import imgButacaDefault from "./images/icono-butaca-nexo.jpeg";
import {
  obtenerButacasSeleccionadasPorTipo,
  obtenerCantidadEntradasPorTipo,
  obtenerNombreTipoDeEntrada,
} from "./utils";
import ModalErrorWs from "../../ModalErrorWs";

function ButacasSeleccion({ butacas }) {
  const entradas = useSelector((state) => state.DatosCarrito.tarifas);
  const [isLoading, setIsLoading] = useState(true);
  const [disponibles, setDisponibles] = useState([]);
  const [butacasSeleccionadas, setButacasSeleccionadas] = useState([]);
  const datosCarrito = useSelector((state) => state.DatosCarrito.tarifas);

  const [filas, setFilas] = useState(0);
  const [tipoFila, setTipoFila] = useState("");
  const [columnas, setColumnas] = useState(0);
  const [tipoColumna, setTipoColumna] = useState("");

  const [tiposDeEntradas, setTiposDeEntradas] = useState([]);
  const [tiposSinRepetir, setTiposSinRepetir] = useState([]);

  const [entradasIndice, setEntradasIndice] = useState([]);

  useEffect(() => {
    let array = [];
    if (datosCarrito && datosCarrito.length > 0) {
      for (let i = 0; i < datosCarrito.length; i++) {
        if (datosCarrito[i].butaca !== "") {
          array.push(datosCarrito[i].butaca);
        }
      }
    }
    setButacasSeleccionadas(array);
  }, [datosCarrito]);

  useEffect(() => {
    if (butacas !== undefined && butacas !== [] && isLoading) {
      setIsLoading(false);
      setDisponibles(butacas.filter((butaca) => butaca.libre === "1"));
    }
  }, [butacas, isLoading]);

  useEffect(() => {
    if ((columnas === 0) & (butacas !== undefined)) {
      setFilas(butacas[0]?.filas);
      setTipoFila(butacas[0]?.tipoFila);
      setColumnas(butacas[0]?.columnas);
      setTipoColumna(butacas[0]?.tipoColumna);
    }
  }, [butacas, columnas]);

  useEffect(() => {
    obtenerTiposDeEntradas(entradas, setTiposDeEntradas);
  }, [entradas]);

  const obtenerTiposDeEntradas = (entradas, setTiposDeEntradas) => {
    let tipos = [];
    let tiposNoRepite = [];
    if (entradas.length > 0) {
      entradas.forEach((item) => {
        if (item.id) tipos.push(Number(item.id));
        if (item.id && !tiposNoRepite.includes(Number(item.id))) {
          tiposNoRepite.push(Number(item.id));
        } else if (!item.id) {
          tipos.push("no-id");
          if (!tiposNoRepite.includes("no-id")) {
            tiposNoRepite.push("no-id");
          }
        }
      });
    }
    setTiposDeEntradas(tipos);
    setTiposSinRepetir(tiposNoRepite);
  };

  const obtenerEntradasIndice = (entradas, butacas, setEntradasIndice) => {
    let hashEnt = {};
    let hashBut = {};
    if (entradas[0].id) {
      const entradasSinRepetir = entradas.filter((item) =>
        hashEnt[item.id] ? false : (hashEnt[item.id] = true)
      );
      const butacasSinRepetir = butacas.filter((item) =>
        hashBut[item.tipo_butaca_id]
          ? false
          : (hashBut[item.tipo_butaca_id] = true)
      );
      let entradasIndicePush = [];
      let colorBut = "";
      entradasSinRepetir.forEach((entrada) => {
        let but = butacasSinRepetir.filter(
          (but) => but.tipo_butaca_id === entrada.id
        );
        if (entrada.id === "4") colorBut = "lightblue";
        else colorBut = "transparent";
        entradasIndicePush.push({
          id: entrada.id,
          tipoTarifa: entrada.tipoTarifa,
          urlImagen: but ? but[0]?.urlImagen : "",
          color: colorBut,
        });
      });
      setEntradasIndice(entradasIndicePush);
    } else if (!entradas[0].id) {
      entradasIndice.push({
        id: "no-id",
        tipoTarifa: entradas[0]?.tipoTarifa,
        urlImagen: "",
        color: "transparent",
      });
    }
  };

  useEffect(() => {
    if (entradasIndice.length === 0 && butacas) {
      obtenerEntradasIndice(entradas, butacas, setEntradasIndice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entradas, entradasIndice, butacas]);

  const getImage = (url) => {
    if (url?.indexOf("https") === -1) {
      return imgButacaDefault;
    } else {
      return url;
    }
  };

  return isLoading ? (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        marginBottom: "4rem",
        padding: "0",
      }}
    >
      <Stack alignItems="center" margin="auto" marginTop={15}>
        <CircularProgress color="secondary" />
      </Stack>
    </Box>
  ) : (
    <>
      {butacas.length === 0 ? (
        <ModalErrorWs />
      ) : (
        <Stack direction="column" sx={{ marginY: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              {tiposSinRepetir.map((tipo) => {
                return (
                  <Typography
                    fontSize="1.2rem"
                    key={tipo}
                    id="but-selecccionadas"
                  >
                    Cantidad de butacas{" "}
                    {obtenerNombreTipoDeEntrada(entradas, tipo).toUpperCase()}{" "}
                    seleccionadas:{" "}
                    {obtenerButacasSeleccionadasPorTipo(
                      tipo,
                      butacasSeleccionadas
                    )}{" "}
                    de {obtenerCantidadEntradasPorTipo(tipo, tiposDeEntradas)}.
                  </Typography>
                );
              })}
            </Stack>
            <Typography fontSize="1.2rem" fontWeight="700">
              Butacas disponibles: {disponibles.length}
            </Typography>
          </Stack>

          <Box
            marginX="6rem"
            paddingY={0.3}
            border="darkgray solid 1px"
            fontSize="1.2rem"
            textAlign="center"
            borderRadius="0.3rem"
            bgcolor="cards.gray"
            sx={{ marginY: 2 }}
          >
            Pantalla
          </Box>

          <Box marginX="auto" overflow="auto" maxHeight="500px">
            <Sala
              butacas={butacas}
              filas={filas}
              columnas={columnas}
              butacasSeleccionadas={butacasSeleccionadas}
              setButacasSeleccionadas={setButacasSeleccionadas}
              tipoFila={tipoFila}
              tipoColumna={tipoColumna}
              tiposDeEntradas={tiposDeEntradas}
            />
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginY: 2 }}
          >
            {entradasIndice.map((entrada) => {
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  key={entrada.id}
                >
                  {entrada.urlImagen === "" ? (
                    <Box
                      border="darkgray solid 1px"
                      padding="0.8rem 0.8rem"
                      borderRadius="0.3rem"
                      bgcolor={entrada.color}
                    >
                      {" "}
                    </Box>
                  ) : (
                    <Box
                      component="img"
                      src={getImage(entrada.urlImagen)}
                      alt={entrada.tipoTarifa}
                      border="darkgray solid 1px"
                      width="18px"
                      height="auto"
                      borderRadius="0.3rem"
                      bgcolor={entrada.color}
                    />
                  )}
                  <Typography fontSize="1.2rem" fontWeight="500">
                    {entrada.tipoTarifa}
                  </Typography>
                </Stack>
              );
            })}
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                border="darkgray solid 1px"
                padding="0.8rem 0.8rem"
                borderRadius="0.3rem"
                bgcolor="red"
              >
                {" "}
              </Box>
              <Typography fontSize="1.2rem" fontWeight="500">
                Ocupada
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                border="darkgray solid 1px"
                padding="0.8rem 0.8rem"
                borderRadius="0.3rem"
                bgcolor="success.light"
              >
                {" "}
              </Box>
              <Typography fontSize="1.2rem" fontWeight="500">
                Seleccionada
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                border="darkgray solid 1px"
                padding="0.8rem 0.8rem"
                borderRadius="0.3rem"
                bgcolor="lightgray"
              >
                {" "}
              </Box>
              <Typography fontSize="1.2rem" fontWeight="500">
                No disponible
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}

export default ButacasSeleccion;
