import qz from "qz-tray";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";
import axios from "axios";
import apihost from "../config/apiHost.json";

let privateKey =
  "-----BEGIN PRIVATE KEY-----\n" +
  "MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDLhsNS4b0Vvx9n\n" +
  "NFPlB7aNVzUiPLygNZ43h5/Zkp62ixwIbtks++N9ciS5CWLY0q0FdOs8YoVRto/Q\n" +
  "sbwK4BwZgJQy8DdcgTthpuABXm5NP0NOhuADhfkUGmAVZUirlTIuJwZsc1Wz357L\n" +
  "GNv0HWA6b8Wjtjo47IWydLJjgczHuQBw+hWiJC5Pm3EURoTba/FAM4nEUzCXzfYy\n" +
  "8uDzGI6E09XmPbsdBgMAYmXM4q9AJANWomGYHgoSeStDWuUyiZkoIDqxCznNd0g/\n" +
  "pUNAqNl7lwioGQ7GRwWfl4zH5xvUta6YahREDvam+2rigMWIYQdGtXWfxl/jfalZ\n" +
  "GYFw2btjAgMBAAECggEAeleI5DKD+iWIF/6xi5QmXTIV433FGT0B3XluUlg7hZl7\n" +
  "gWo4rDzCE+9gOcZ1n8aMcSJGIWdLosHuE1Izqgnrejz7WN9jN/0LF/k64DfGcDLe\n" +
  "H78du6B2CGqOZW4bz+CAaJPW3aw60URT7NwFo+lgpd/4Wx+4Qe2TtKFHdl9ZHJh6\n" +
  "uBF0R8CTK0TrRdbolZJSWLXWWQerfBa6cjIVMoVfwujJBbNQ0+yiIEW6gqe/fzXx\n" +
  "tgSAFWn0YVKDTUEt2kJTO1Uha+jJmK77+KK2Z1VBIRSDXu8AS4topbHIqVzAqqwu\n" +
  "YrNcya/PRd9z4HQudA6Yfn6P0qRdoXcVxDMrq0VWkQKBgQDzdBaFTeHBZUyjxko8\n" +
  "Shypx3d1c0RbMYlzdXwmgWhn7NQaWl5fMil5TYTcCHMsc1m9lg+11FEnhalsfekd\n" +
  "uwPDtn6jwVZBfPrt5RiKlh6ytCcxHS0TPE69d/1ZwB/16m3rYMt3yYgHU9AqtVEY\n" +
  "UMukHv0NmeJeEExB4SiL2vlm+QKBgQDWA+mV3u8Q4YwsvXMMxhU3r+QVGpHXmp3O\n" +
  "grdA9GdmjfZI14r4B9w+wStHUDfzGNzaASAjU88BNrJoasOk1azg97Hr4A0aWxml\n" +
  "bk55WLjRtTLR84QMKHO1hSsaMWc7s36HnaGd5RoCxrx7hqXUPolRAT5d0Bi7ghOj\n" +
  "+OMcwRcAOwKBgQDo/xd2qR0AvHRm4aW2oa9hBdfcfl7TcEt+Rth0+bD0lYJBXm+n\n" +
  "aBitUXVwKF4iEpu2iGGswfCRhKirfq2WmJ93tbbp/mUQ9A4rk/Cy4g2rNiO0K5oy\n" +
  "KKurRaUhOnDcKGretJrGoAgiCyLVXQap4Nmkz0ucssQlBjAVdOobP40l8QKBgQCS\n" +
  "xv4llk7ZggbUDF5ibVvR5GUGAEHz34vJPScXekeMM2Ks1H7v2tw3gd3/u8mThQK6\n" +
  "1TjIYcs/5tZ2/HoPi6hUZix1PCVgZQhyx/M0ECA5Ww9upJd0iy3IWXuVLDrD3De0\n" +
  "JOzZ0G2FZ+2Ovmru5LDiiHrQdk7wEqcLtz9z0g3HTwKBgQCBrxV+qXfgLADql4Xp\n" +
  "XLF5vEFbmjvHBKv8Ijfkh8oNZ2h5OnQhk7TbXQDyOPUYz7fKomtj8hN0tHruoPAi\n" +
  "LV38vVN2ICcvAbrngP4nW5P44jEAsOEKbaZRP498T9VsZ25PAK0vyA06UerYt9uh\n" +
  "oqUXG7GaCzqCFyEwjEWhJd8AaA==\n" +
  "-----END PRIVATE KEY-----";

qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      let pk = KEYUTIL.getKey(privateKey);
      let sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
      sig.init(pk);
      sig.updateString(toSign);
      let hex = sig.sign();
      resolve(stob64(hextorstr(hex)));
    } catch (err) {
      reject(err);
    }
  };
});

export const connectWithPrinter = () => {
  qz.security.setCertificatePromise((resolve, reject) => {
    resolve(
      `-----BEGIN CERTIFICATE-----
      MIIFNDCCAxygAwIBAgIQNzkyMDI0MDQyMjIyMzM1MDANBgkqhkiG9w0BAQsFADCB
      mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry
      aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ
      cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz
      dHJpZXMuY29tMB4XDTI0MDQyMjIyMzM1MFoXDTI2MDUyNjA0MDAwMFowge8xCzAJ
      BgNVBAYMAkFSMREwDwYDVQQIDAhDw7NyZG9iYTEWMBQGA1UEBwwNU2FuIEZyYW5j
      aXNjbzEYMBYGA1UECgwPTmV4byBTb2x1Y2lvbmVzMRgwFgYDVQQLDA9OZXhvIFNv
      bHVjaW9uZXMxGDAWBgNVBAMMD05leG8gU29sdWNpb25lczEpMCcGCSqGSIb3DQEJ
      AQwabmV4b0BuZXhvc29sdWNpb25lcy5jb20uYXIxPDA6BgNVBA0MM3JlbmV3YWwt
      b2YtODhjYjRlMmIzYWM3Y2Y1NWRjYjJhNzYwM2RmYTk3MDYwNTQ2ZTQ5NzCCASAw
      CwYJKoZIhvcNAQEBA4IBDwAwggEKAoIBAQDLhsNS4b0Vvx9nNFPlB7aNVzUiPLyg
      NZ43h5/Zkp62ixwIbtks++N9ciS5CWLY0q0FdOs8YoVRto/QsbwK4BwZgJQy8Ddc
      gTthpuABXm5NP0NOhuADhfkUGmAVZUirlTIuJwZsc1Wz357LGNv0HWA6b8Wjtjo4
      7IWydLJjgczHuQBw+hWiJC5Pm3EURoTba/FAM4nEUzCXzfYy8uDzGI6E09XmPbsd
      BgMAYmXM4q9AJANWomGYHgoSeStDWuUyiZkoIDqxCznNd0g/pUNAqNl7lwioGQ7G
      RwWfl4zH5xvUta6YahREDvam+2rigMWIYQdGtXWfxl/jfalZGYFw2btjAgMBAAGj
      IzAhMB8GA1UdIwQYMBaAFJCmULeE1LnqX/IFhBN4ReipdVRcMA0GCSqGSIb3DQEB
      CwUAA4ICAQCq+mSPTQAI/fqTLR+n+mYo7v74Q24LkDsjBzf6Yj1fdqtZn2w0H56Y
      I+7e1kt/c4F34Lr2fIy0KmeN7iWs5D+xpuzHN2Gbs3uVFVe9TNOqSlyQNZB3mIUU
      h7uTkOQt2NPy2Q9QGa1WcBMBKFB9NWN72McOdyxrzxT4Hsc4DFoVA6ET1eskK6ro
      9v8n3G1MGRkwXrTIhwEBOs6E86itK+AcvYh1kh8b97fqx9qzy0xl3SDH5FeKWUje
      TSVrF8QX2UbznREDjsRSD5avhg4aTXLgyYZzVozYyYzJ053Y+H6d6+dx3FBoXqgx
      f+K6EXWFiRUIzOfi/4aLkiM7Zq7hIweS8nvJ1h8NSqqjmFQJi+9tuFPLWeFtl0O8
      giyVAWCE7R/uzaXvQYxPSQjZ/NZ4mL3hXIxZpEZOwG9LfnMEOniFQlbMJcRGWmcB
      EHot0Y79f+rl2+6wCnri6/W1sE9XKrhBjJ1cVwidKqT95hmPqQEDXetwvsV4y8Na
      s6wT8b2wqluN0mXa2SuyW7mb5duuFv8xIuA59fi3KMAXCDHzWhr/P1sWACJgBGUI
      sm2muizESzv9vyVpl99HP6arlJ3jmu7fWofMZv75SEvmZQs5spRJM98YLyRJrgGU
      zwO4REFv+CSnI1E46lirkMsqfHpbeZ5PPkseo7jnGS/88WooeL9T4A==
      -----END CERTIFICATE-----
      --START INTERMEDIATE CERT--
      -----BEGIN CERTIFICATE-----
      MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
      MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
      bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
      A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
      emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
      gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
      cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
      EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
      c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
      iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
      YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
      GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
      EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
      0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
      xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
      9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
      ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
      2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
      6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
      5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
      plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
      BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
      eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
      gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
      x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
      pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
      2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
      rbO7BnjW
      -----END CERTIFICATE-----`
    );
  });

  if (!qz.websocket.isActive()) {
    qz.websocket
      .connect({ usingSecure: true, secure: 8181 })
      .then(() =>
        qz.printers.find().then((printers) => {
          console.log(printers);
          getPrintersStatus();
        })
      )
      .catch((error) => console.log(error));
  } else {
    console.log("qz websocket connection", qz.websocket.getConnectionInfo());
  }
};

// qz.printers.setPrinterCallbacks((evt) => {
//   qz.printers.find().then((printers) => {
//     printers.forEach((printer) => {
//       console.log(printer, evt);
//     });
//   });
// });

export const getPrintersStatus = () => {
  qz.printers
    // .find()
    // .then((printers) => {
    //   printers.forEach((printer) => {
    //     qz.printers.startListening(printer).then(() => {
    //       return qz.printers.getStatus();
    //     });
    //   });
    // })
    .find("atm")
    .then((printer) => {
      qz.printers.startListening(printer).then(() => {
        return qz.printers.getStatus();
      });
    })
    .catch(function (e) {
      console.error(e);
    });
};

export const printCashPayment = (data) => {
  let config = qz.configs.create("atm");

  let dataPrint = [
    {
      type: "raw",
      format: "base64",
      data: data,
    },
  ];

  qz.print(config, dataPrint)
    .then(() => {
      console.log("Sent data to printer");
    })
    .catch((err) => console.log(err));
};

export const printQrPointPayment = (data) => {
  if (data.datos && !data.datos.error) {
    if (!data.datos.error) {
      let boleteriaConfig = qz.configs.create("atm");

      let dataBoleteria = [
        {
          type: "raw",
          format: "base64",
          data: data.datos.tramaBoleteria,
        },
      ];

      qz.print(boleteriaConfig, dataBoleteria)
        .then(() => {
          console.log("Printing tickets");
        })
        .catch((error) => console.log(error));
    }

    if (data.datos.tramaCandy) {
      if (data.datos.tramaCandy !== []) {
        data.datos.tramaCandy
          ?.forEach((item) => {
            let config = qz.configs.create(item.impresora);

            let dataItem = [
              {
                type: "raw",
                format: "base64",
                data: item.trama,
              },
            ];

            qz.print(config, dataItem)
              .then(() => console.log(`Printing on ${item.impresora}`))
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
  }
};

export const getImpresionTicketsQRPoint = async (
  numeroReferencia,
  funcion_id,
  cine_id,
  pasarelaPago,
  onlyCandy
) => {
  let data = {};

  if (onlyCandy) {
    data = {
      txtNumeroReferencia: numeroReferencia,
      txtPasarelaPago: pasarelaPago,
      complejo_id: cine_id,
    };
  } else {
    data = {
      txtNumeroReferencia: numeroReferencia,
      txtIdFuncion: funcion_id,
      txtPasarelaPago: pasarelaPago,
      complejo_id: cine_id,
    };
  }

  console.log(data);
  await axios({
    method: "POST",
    url: `${apihost.REACT_APP_URL_CINEXO}impresiones/canje`,
    data: JSON.stringify(data),
    responseType: "json",
  })
    .then(async (res) => {
      console.log(res);
      if (!res.data.error) {
        let dataTramaCanje = {};

        if (onlyCandy) {
          dataTramaCanje = {
            complejo_id: cine_id,
            collectionId: res.data.datos.data.venta.collectionId,
          };
        } else {
          dataTramaCanje = {
            idFuncion: funcion_id,
            complejo_id: cine_id,
            collectionId: res.data.datos.data.venta.collectionId,
          };
        }

        await axios({
          method: "POST",
          url: `${apihost.REACT_APP_URL_CINEXO}impresiones/TramaCanje`,
          data: JSON.stringify(dataTramaCanje),
          responseType: "json",
        })
          .then((res) => {
            console.log(res.data);
            if (!res.data.error) {
              printQrPointPayment(res.data);
            } else {
              console.log("Error al obtener la trama");
            }
          })
          .catch((error) => console.log(error));
      } else {
        console.log("Error al realizar el canje");
      }
    })
    .catch((error) => console.log(error));
};

export const getImpresionFacturaQRPoint = async (
  collectionId,
  cliente,
  complejo_id
) => {
  const data = {
    collection_id: collectionId.toString(),
    regionalizacion: "genericos",
    cliente,
    complejo_id,
  };

  console.log(data);

  await axios({
    method: "POST",
    url: `${apihost.REACT_APP_URL_CINEXO}impresiones/ticketFactura`,
    data: JSON.stringify(data),
    responseType: "json",
  })
    .then((res) => {
      if (res.data.estado) printFactura(res.data.datos);
      else console.log(`Error al obtener factura`);
    })
    .catch((error) => console.log(error));
};

// Print Factura
export const printFactura = (data) => {
  let config = qz.configs.create("atm");

  let dataPrint = [
    {
      type: "raw",
      format: "base64",
      data: data,
    },
  ];

  qz.print(config, dataPrint)
    .then(() => {
      console.log("Printing receipt");
    })
    .catch((err) => console.log(err));
};
