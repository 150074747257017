import React, { useState } from "react";
import { Grid, Typography, Stack, Button, ButtonGroup } from "@mui/material";

function ProductoCard({
  producto,
  productosSelec,
  setProductosSelec,
  countTotal,
  setCountTotal,
}) {
  const [count, setCount] = useState(0);

  const handleAddProd = () => {
    const prodAgregar = producto;
    if (countTotal < 3) {
      let findProd = productosSelec.find((prod) => prod.id === prodAgregar.id);
      if (findProd) {
        let filterOldProds = productosSelec.filter(
          (product) => product.id !== prodAgregar.id
        );
        let prod = findProd;
        prod.cantidad = Number(prod.cantidad) + 1;
        let newArrayProds = filterOldProds.concat(prod);
        setProductosSelec(newArrayProds);
      } else {
        setProductosSelec((state) => state.concat(prodAgregar));
      }
      setCount((state) => state + 1);
      setCountTotal((state) => state + 1);
    }
  };

  const handleRemoveProd = () => {
    if (count > 0 && productosSelec?.length > 0) {
      let filterProds = productosSelec.filter(
        (prod) => prod.id === producto.id
      );
      let filterOldProds = productosSelec.filter(
        (product) => product.id !== producto.id
      );
      if (filterProds.length !== 0) {
        let prod = filterProds[0];
        if (prod.cantidad > 1) {
          prod.cantidad = Number(prod.cantidad) - 1;
          let newArrayProds = filterOldProds.concat(prod);
          setProductosSelec(newArrayProds);
        } else {
          setProductosSelec(filterOldProds);
        }
      } else {
        setProductosSelec(filterOldProds);
      }
      setCount((state) => state - 1);
      setCountTotal((state) => state - 1);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        padding: "1rem",
        width: 550,
        border: "1px solid #dee2e6",
      }}
    >
      <Grid item xs={9}>
        <Typography fontSize="1rem" textTransform="uppercase" fontWeight="700">
          {producto.nombre}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <ButtonGroup>
          <Button
            disabled={countTotal === 0 ? true : false}
            variant="contained"
            color="secondary"
            border="1px transparent"
            size="small"
            margin={0}
            disableElevation
            sx={{ padding: 0, width: "auto" }}
            onClick={handleRemoveProd}
          >
            <Typography
              fontSize="1.6rem"
              fontWeight="500"
              textTransform="uppercase"
              paddingX="-1rem"
            >
              -
            </Typography>
          </Button>
          <Stack
            component="span"
            borderRadius={0}
            border="1px solid #ced4da"
            width="3rem"
            bgcolor="white"
          >
            <Typography fontSize="1rem" textAlign="center" margin="auto">
              {count}
            </Typography>
          </Stack>
          <Button
            id={`add-${producto.id}`}
            disabled={countTotal >= 3 ? true : false}
            variant="contained"
            color="secondary"
            border="1px transparent"
            size="small"
            margin={0}
            width="0.25rem"
            disableElevation
            sx={{ padding: 0, width: "auto" }}
            onClick={handleAddProd}
          >
            <Typography
              fontSize="1.6rem"
              fontWeight="500"
              textTransform="uppercase"
            >
              +
            </Typography>
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

export default ProductoCard;
