import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import imgButacaDefault from "./images/icono-butaca-nexo.jpeg";

function Butaca({
  butaca,
  estadoButaca,
  tiposDeEntradas,
  handleSeleccionarButaca,
}) {
  const [bgColor, setBgColor] = useState("transparent");
  const [isSSL, setIsSSL] = useState(false);

  useEffect(() => {
    if (butaca.urlImagen && butaca.urlImagen !== "") {
      if (butaca.urlImagen.indexOf("https") === -1) {
        setIsSSL(false);
      }
    }
  }, [butaca.urlImagen]);

  useEffect(() => {
    if (estadoButaca === "libre") {
      if (butaca.tipo_butaca_id === "4") setBgColor("lightblue");
      else setBgColor("transparent");
    } else if (estadoButaca === "seleccionada") setBgColor("success.light");
    else if (estadoButaca === "ocupada") setBgColor("red");
    else if (estadoButaca === "no disponible") setBgColor("lightgray");
  }, [estadoButaca, butaca]);

  return (
    <>
      {butaca.pasillo ? (
        <Grid item xs={1} key={butaca.butaca}>
          <Box
            paddingY="0.5rem"
            textAlign="center"
            border="none"
            borderRadius="0.3rem"
            bgcolor="transparent"
            margin="auto"
          ></Box>
        </Grid>
      ) : (
        <Grid item xs={1} key={butaca.butaca}>
          {butaca.urlImagen === "" ? (
            <Box
              id={butaca.butaca}
              component="button"
              width="100%"
              height="100%"
              paddingY="0.5rem"
              textAlign="center"
              border={"darkgray solid 1px"}
              borderRadius="0.3rem"
              bgcolor={bgColor}
              margin="auto"
              onClick={() => {
                handleSeleccionarButaca(butaca, tiposDeEntradas, estadoButaca);
              }}
            >
              <Typography
                fontSize="1.1rem"
                fontWeight="500"
                color="transparent"
              >
                {butaca.nombre_butaca}
              </Typography>
            </Box>
          ) : (
            <Box
              id={butaca.butaca}
              component="button"
              width="100%"
              height="100%"
              paddingY="0rem"
              textAlign="center"
              border={"darkgray solid 1px"}
              borderRadius="0.3rem"
              bgcolor={bgColor}
              margin="auto"
              onClick={() => {
                handleSeleccionarButaca(butaca, tiposDeEntradas, estadoButaca);
              }}
            >
              <Box
                component="img"
                alt={`img-but-${butaca.butaca}`}
                src={isSSL ? butaca.urlImagen : imgButacaDefault}
                textAlign="center"
                border={"darkgray solid 1px"}
                borderRadius="0.3rem"
                marginTop="1px"
                sx={{
                  height: "2rem",
                  width: "auto",
                }}
              />
            </Box>
          )}
        </Grid>
      )}
    </>
  );
}

export default Butaca;
