import { Grid, Box, Typography, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCountItem,
  resetSubtotalCandyModal,
  resetLockItem,
  resetObligatorias,
  clearPref,
  clearComboDinamicoProds,
  resetPromocionesCandyModal,
} from "../../actions/candyActions";
import CandyModal from "./CandyModal";
import defaultImg from "./assets/images/img_combo.png";

function CandyCard({ bgColor = "white", item }) {
  const dispatch = useDispatch();
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(resetCountItem());
    dispatch(resetSubtotalCandyModal());
    dispatch(resetLockItem());
    dispatch(resetObligatorias());
    dispatch(clearPref());
    dispatch(clearComboDinamicoProds());
    dispatch(resetPromocionesCandyModal(false));
  };

  const [border, setBorder] = useState("1px solid #dee2e6");

  useEffect(() => {
    if (item.es_promocional === 1) setBorder("1px solid #4caf50");
  }, [item]);

  const handleImageError = (e) => {
    e.target.src = defaultImg;
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        padding: "1rem",
        marginY: "0.25rem",
        border: border,
        bgcolor: bgColor,
        borderRadius: "0.3rem",
      }}
      key={item.id}
    >
      <Grid item xs={2} padding="0px 7.5px">
        <Box
          component="img"
          src={item.imagen}
          onError={handleImageError}
          width="80px"
          height="80px"
        />
      </Grid>
      <Grid item xs={5} padding="0px 7.5px">
        <Stack direction="column">
          <Typography
            fontSize="1.3rem"
            fontWeight="700"
            textTransform="uppercase"
          >
            {item.nombre}
          </Typography>
          {item.descripcion.length > 1 ? (
            <Typography fontSize="1.1rem" fontWeight="500">
              Contenido: {item.descripcion}
            </Typography>
          ) : (
            ""
          )}
        </Stack>
      </Grid>
      <Grid item xs={2} textAlign="center" padding="0px 7.5px">
        <>
          {datosComplejo.tasaConversion ? (
            <Typography
              fontSize="1.3rem"
              fontWeight="500"
              textTransform="uppercase"
            >
              Bs. {Number(item.precio)}
            </Typography>
          ) : (
            <Typography
              fontSize="1.3rem"
              fontWeight="500"
              textTransform="uppercase"
            >
              ${Number(item.precio)}
            </Typography>
          )}
        </>
      </Grid>
      <Grid item xs={3} textAlign="center" padding="0px 7.5px">
        <Button
          id={`agregar-${item.id}`}
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleOpen}
          style={{
            fontSize: "1.2rem",
            fontWeight: "600",
            textTransform: "uppercase",
            padding: "10px",
          }}
        >
          Agregar
        </Button>
      </Grid>

      <CandyModal
        item={item}
        open={open}
        handleClose={handleClose}
      ></CandyModal>
    </Grid>
  );
}

export default CandyCard;
