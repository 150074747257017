import React, { useEffect } from "react";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postVentasTemporales } from "../../actions/paymentActions";
import PaymentCash from "./PaymentCash";
import PaymentPoint from "./PaymentPoint";
import PaymentQR from "./PaymentQR";
import ModalError from "../Carrito/ModalError";
import PaymentSitef from "./PaymentSitef";
import PaymentSitefC2P from "./PaymentSitefC2P";

function PaymentContainer() {
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const paymentID = useSelector((state) => state.DatosPayment.paymentID);
  const methodSelected = useSelector(
    (state) => state.DatosPayment.methodSelected
  );
  const qrData = useSelector((state) => state.DatosPayment.qrData);
  const deviceId = useSelector((state) => state.DatosPayment.deviceId);
  const external_id = useSelector((state) => state.DatosPayment.external_id);
  const statusStep1 = useSelector((state) => state.DatosPayment.statusStep1);
  const statusStep3 = useSelector((state) => state.DatosPayment.statusStep3);
  const codigo_pais = useSelector((state) => state.DatosPayment.codigo_pais);

  useEffect(() => {
    if (methodSelected !== "CASH" && methodSelected !== "SITEF")
      dispatch(
        postVentasTemporales(
          dataToSend,
          methodSelected,
          deviceId,
          external_id,
          codigo_pais
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoader = () => {
    return (
      <div>
        <Typography align="center" fontSize={"2rem"}>
          Estamos procesando la información de su pago, le avisaremos cuando
          este listo para ser efectuado.
        </Typography>
        <Grid item xs={12} alignContent={"center"} textAlign={"center"}></Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
        >
          <CircularProgress></CircularProgress>
        </Box>
      </div>
    );
  };

  const renderMethod = () => {
    if (methodSelected === "POINT") {
      if (statusStep3 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago. Verifique que no haya ningun pago pendiente en la terminal de pagos."
            }
          ></ModalError>
        );
      }
      if (statusStep1 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
            errorType={"ventasTemporales"}
          />
        );
      }
      if (paymentID === "") {
        return handleLoader();
      } else {
        return <PaymentPoint />;
      }
    } else if (methodSelected === "QR") {
      if (statusStep3 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
          ></ModalError>
        );
      }
      if (statusStep1 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
            errorType={"ventasTemporales"}
          />
        );
      }
      if (qrData === "") {
        return handleLoader();
      } else {
        return <PaymentQR />;
      }
    } else if (methodSelected === "SITEF") {
      if (statusStep3 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
          ></ModalError>
        );
      }
      if (statusStep1 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
            errorType={"ventasTemporales"}
          />
        );
      } else {
        return <PaymentSitef />;
      }
    } else if (methodSelected === "SITEF_C2P") {
      if (statusStep3 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
          ></ModalError>
        );
      }
      if (statusStep1 === "failed") {
        return (
          <ModalError
            error={
              "Lo sentimos. Debido a un error en el sistema no se pudo generar el pago."
            }
            errorType={"ventasTemporales"}
          />
        );
      }
      return <PaymentSitefC2P />;
    } else if (methodSelected === "CASH") {
      return <PaymentCash />;
    }
  };
  return (
    <Box
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        marginTop: "1rem",
      }}
    >
      {renderMethod()}
    </Box>
  );
}

export default PaymentContainer;
