import { Dialog, Box, Stack, Typography, Button } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPaymentID,
  resetQRData,
  retryPaymentPoint,
  retryPaymentQR,
  setStatusStep3,
} from "../../actions/paymentActions";
import { useLocation } from "react-router-dom";
import { clearAllReduxState } from "../../utils/resetReduxState";
import { limpiarCarrito } from "../../actions/tarifasAction";
import { TimerContext } from "../HeadlineTimer/Timer";
import TimerModal from "../HeadlineTimer/TimerModal";
import { redirect } from "../../utils/helpers";

function ModalError({ error, idOperation, callback, errorType }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const device_id = urlParams.get("did");
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const dispatch = useDispatch();
  const methodSelected = useSelector(
    (state) => state.DatosPayment.methodSelected
  );
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const deviceId = useSelector((state) => state.DatosPayment.deviceId);
  const responseStep1 = useSelector(
    (state) => state.DatosPayment.responseStep1
  );
  const external_id = useSelector((state) => state.DatosPayment.external_id);
  const [open, setOpen] = useState(true);
  const { resetContador } = useContext(TimerContext);

  const handleRetry = () => {
    if (methodSelected === "POINT") {
      dispatch(setStatusStep3(null));
      dispatch(resetPaymentID());
      dispatch(
        retryPaymentPoint(
          dataToSend,
          responseStep1,
          deviceId,
          responseStep1.data.mercadoPago.hash
        )
      );
    }
    if (methodSelected === "QR") {
      dispatch(resetQRData());
      dispatch(setStatusStep3(null));
      dispatch(resetPaymentID());
      dispatch(
        retryPaymentQR(
          responseStep1,
          dataToSend,
          external_id,
          responseStep1.data.mercadoPago.hash
        )
      );
    }
    setOpen(false);
  };
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <Box bgcolor="error.main" textAlign="center" color="white">
        <Stack
          margin={3}
          marginTop={10}
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <CancelOutlinedIcon sx={{ fontSize: "10rem" }} />
          <Typography fontSize="1.8rem">¡Ups!</Typography>
        </Stack>
      </Box>
      <Box textAlign="center">
        <Stack direction="column" margin={3} spacing={3} alignItems="center">
          <Stack direction="column" spacing={2}>
            {errorType && errorType === "checkout" ? (
              <Typography fontSize="1.3rem">
                Lo sentimos. Debido a un error en el sistema la venta no pudo
                ser confirmada. Le sugerimos tomarle una foto a este mensaje y a
                su número de operación para reclamar en boletería.
              </Typography>
            ) : (
              <Typography fontSize="1.3rem">
                La compra no se pudo efectuar debido a un error en el proceso de
                pago.
              </Typography>
            )}
            {errorType && errorType === "checkout" ? (
              <Typography
                fontSize="1.3rem"
                color="error.light"
                fontWeight="bold"
              >
                {error}
              </Typography>
            ) : (
              <Typography
                fontSize="1.3rem"
                color="error.light"
                fontWeight="bold"
              >
                {`Error detectado: ${error}`}
              </Typography>
            )}

            {idOperation !== undefined ? (
              <Typography fontSize="1.3rem" fontWeight="bold">
                {`Numero de operación: ${idOperation}`}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          {errorType ||
          errorType === "checkout" ||
          errorType === "ventasTemporales" ? (
            ""
          ) : (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disableElevation
              marginTop={2}
              sx={{
                padding: "1rem",
                width: "70%",
                fontSize: "1.3rem",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
              onClick={() => {
                if (callback) {
                  callback(false);
                }
                handleRetry();
              }}
            >
              Reintentar pago
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            marginTop={2}
            sx={{
              padding: "1rem",
              width: "70%",
              fontSize: "1.3rem",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            onClick={() => {
              redirect(
                datosComplejo.ip_privada,
                datosComplejo.version_boleteria,
                device_id
              );
              dispatch(limpiarCarrito());
              clearAllReduxState(dispatch);
              resetContador();
            }}
          >
            VOLVER AL INICIO
          </Button>
        </Stack>
        {errorType && errorType === "checkout" ? (
          <TimerModal time={180} />
        ) : (
          <TimerModal time={120} />
        )}
      </Box>
    </Dialog>
  );
}

export default ModalError;
