import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormCanje from "./FormCanje";
import FormEntidad from "./FormEntidad";

function FormContainer({
  item,
  codigosCanjeadosModal,
  setCodigosCanjeadosModal,
}) {
  const itemModal = useSelector((state) => state.DatosCandy.item);

  const [nombreEntidad, setNombreEntidad] = useState("");
  const [entidad, setEntidad] = useState(null);

  useEffect(() => {
    if (nombreEntidad !== "") {
      setEntidad(item.entidades.find((e) => e.nombre === nombreEntidad));
    }
  }, [item, nombreEntidad]);

  const [openForm, setOpenForm] = useState(false);

  const [formError, setFormError] = useState({
    status: false,
    mensaje: "",
  });

  const [formSuccess, setFormSuccess] = useState({
    status: false,
    mensaje: "",
  });

  return (
    <Stack direction="column">
      <Typography fontSize="1.2rem">Promociones</Typography>
      <Box
        alignItems="center"
        sx={{
          padding: "1rem",
          marginY: "0.25rem",
          border: "1px solid #dee2e6",
          bgcolor: "white",
        }}
      >
        {!openForm ? (
          <Stack alignItems="center" width="inherit" marginTop={1}>
            <Button
              id="btn-add-promociones"
              variant="contained"
              color="secondary"
              type="submit"
              size="small"
              sx={{
                padding: "0.5rem",
                fontSize: "1.2rem",
                fontWeight: "700",
                width: "35%",
              }}
              disabled={
                itemModal.quantity > codigosCanjeadosModal.length ? false : true
              }
              onClick={() => setOpenForm(true)}
            >
              Agregar promoción
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-around"
            marginTop={1}
            width="inherit"
            marginX="auto"
          >
            <FormEntidad
              entidades={item.entidades}
              entidadSelec={nombreEntidad}
              setEntidadSelec={setNombreEntidad}
              setFormError={setFormError}
              setFormSuccess={setFormSuccess}
            />
            {entidad && entidad !== null ? (
              <FormCanje
                item={item}
                entidad={entidad}
                setNombreEntidad={setNombreEntidad}
                formError={formError}
                setFormError={setFormError}
                formSuccess={formSuccess}
                setFormSuccess={setFormSuccess}
                setOpenForm={setOpenForm}
                codigosCanjeadosModal={codigosCanjeadosModal}
                setCodigosCanjeadosModal={setCodigosCanjeadosModal}
              />
            ) : (
              ""
            )}
          </Stack>
        )}
        {formError.status ? (
          <Stack
            width="auto"
            bgcolor="#ffcdd2"
            borderRadius={0.5}
            border="1px solid #d32f2f"
            padding={1}
            marginTop={1}
          >
            <Typography fontSize="1.2rem" color="#b71c1c">
              {formError.mensaje}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
        {formSuccess.status ? (
          <Stack
            width="auto"
            bgcolor="#E9FBF0"
            borderRadius={0.5}
            border="1px solid #9AEFBC"
            padding={1}
            marginTop={1}
          >
            <Typography fontSize="1.2rem" color="#0F5C2E">
              {formSuccess.mensaje}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
}

export default FormContainer;
