import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { cancelPaymentIntention } from "../Payment-Methods/utils/requests";
import { useSelector } from "react-redux";

function TimerPayment({ data }) {
  const [segundosRestantes, setSegundosRestantes] = useState(180);
  const [pausado, setPausado] = useState(false);
  const hash = useSelector((state) => state.DatosPayment.hash);
  const subtype = useSelector((state) => state.DatosPayment.subtype);

  useEffect(() => {
    let intervalId = null;
    if (!pausado) {
      intervalId = setInterval(() => {
        setSegundosRestantes((segundosRestantes) => segundosRestantes - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [pausado]);

  useEffect(() => {
    if (segundosRestantes === 0) {
      handlePausar();
      console.log("¡Tiempo terminado!");
      if (data.point) {
        const cancelPayment = async () => {
          const id = await cancelPaymentIntention(
            data.deviceID,
            data.paymentID,
            data.complexCode,
            hash,
            subtype
          );
          console.log("Cancel payment", id);
        };
        cancelPayment();
        data.setpaymentProcessStatus("time-limit");
        data.setStatusPoint("CANCELED");
      }
      if (data.QR) {
        // dispatch(setStateProcessQr("CANCELED"));
        data.setStatusQR("CANCELED");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segundosRestantes]);

  const minutos = String(Math.floor(segundosRestantes / 60)).padStart(2, "0");
  const segundos = String(segundosRestantes % 60).padStart(2, "0");
  const tiempoRestante = `${minutos}:${segundos}`;

  const handlePausar = () => {
    setPausado(true);
  };

  // const handleReanudar = () => {
  //   setPausado(false);
  // };

  return (
    <Typography align="center" fontSize={"2rem"} padding={2}>
      Tiempo restante: {tiempoRestante}
    </Typography>
  );
}

export default TimerPayment;
