import { Button, Stack, TextField, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { redirect } from "../../utils/helpers";

export default function FormCanje({
  codigo,
  error,
  handleSubmit,
  isLoading,
  setCodigo,
  datosComplejo,
  deviceId,
  backDisabled,
}) {
  const handleChange = (e) => {
    const { value } = e.target;
    setCodigo(value);
  };

  return (
    <Stack direction="column" justifyContent="flex-start">
      <Typography variant="subtitle1" fontSize={12} marginBottom={1}>
        Ingrese el código recibido y obtenga su ticket
      </Typography>
      <Stack direction="column" spacing="1rem">
        <TextField
          autoFocus
          color="secondary"
          error={error.estado}
          placeholder="Ingrese su código"
          fullWidth
          size="small"
          inputProps={{
            style: {
              fontSize: "1.2rem",
              backgroundColor: "white",
            },
          }}
          value={codigo}
          onChange={handleChange}
        />
        {error.estado ? (
          <Stack
            width="auto"
            bgcolor="#ffcdd2"
            borderRadius={0.5}
            border="1px solid #d32f2f"
            padding={0.5}
            marginTop={1}
          >
            <Typography fontSize="1.2rem" color="#b71c1c">
              {error.mensaje}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
        <Stack direction="row-reverse" gap={2}>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            color="secondary"
            sx={{
              height: "100%",
              paddingX: "25px",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
            disableElevation
            startIcon={<PrintIcon />}
            onClick={handleSubmit}
          >
            Consultar Entrada Válida
          </LoadingButton>
          <Button
            disabled={backDisabled}
            variant="outlined"
            color="secondary"
            sx={{
              height: "100%",
              paddingX: "25px",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
            disableElevation
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              redirect(
                datosComplejo.ip_privada,
                datosComplejo.version_boleteria,
                deviceId
              );
            }}
          >
            Volver
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
