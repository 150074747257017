import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agregarCodigoCanjeadoVenta } from "../../actions/fidelizacionActions";
import apihost from "../../config/apiHost.json";

function FormCuponCompra() {
  const dispatch = useDispatch();
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const codigosCanjeados = useSelector(
    (state) => state.DatosCarrito.codigosCanjeadosVenta
  );
  const subTotalCompra = useSelector((state) => state.DatosCarrito.subtotal);
  const [codigo, setCodigo] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);

  const [dataCupon, setDataCupon] = useState({
    partner_code: 14,
    partner_name: "Cupon",
    complejo_id: datosFuncion.cine_id,
    tipo: "venta",
    data: {
      funcion_id: datosFuncion.funcion_id,
      tipo: "venta",
    },
  });

  const [data, setData] = useState({
    funcion_id: datosFuncion.funcion_id,
    tipo: "venta",
  });

  const [formError, setFormError] = useState({
    status: false,
    mensaje: "",
  });

  const [formSuccess, setFormSuccess] = useState({
    status: false,
    mensaje: "",
  });

  const handleChange = (evt) => {
    let { value, name } = evt.target;
    setCodigo(value);
    setData((state) => {
      return { ...state, [name]: value };
    });
    setFormError({
      status: false,
      mensaje: "",
    });
    setFormSuccess({
      status: false,
      mensaje: "",
    });
  };

  const handleBlur = () => {
    setDataCupon((dataCupon) => {
      return {
        ...dataCupon,
        data,
      };
    });
  };

  const handleResetInputs = () => {
    setCodigo("");
    setData({
      funcion_id: datosFuncion.funcion_id,
      tipo: "venta",
    });
  };

  const handleSubmit = async () => {
    setButtonStatus(true);
    await fetch(`${apihost.REACT_APP_URL_CINEXO}promocion/validar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataCupon),
    })
      .then((response) => response.json())
      .then((respuesta) => {
        if (respuesta.valido === true) {
          if (
            codigosCanjeados.some(
              (data) => data.codigo === dataCupon.data.codigo
            )
          ) {
            setFormError({
              status: true,
              mensaje: "El cupón ya fue canjeado",
            });
            handleResetInputs();
          } else {
            let precioDescuento = 0;
            if (
              respuesta.cupon.tipoDescuento.includes("porcentaje") &&
              respuesta.cupon.descuento !== null
            ) {
              precioDescuento =
                subTotalCompra * (Number(respuesta.cupon.descuento) / 100);
            }
            if (
              respuesta.cupon.tipoDescuento.includes("moneda") &&
              respuesta.cupon.descuento !== null
            ) {
              precioDescuento = Number(respuesta.cupon.descuento);
            }
            let cupon = {
              ...respuesta.datos.cupon,
              precioDescuento,
            };
            dispatch(agregarCodigoCanjeadoVenta(cupon));
            setFormSuccess({
              status: true,
              mensaje: respuesta.datos.mensaje,
            });
            setTimeout(() => {
              setButtonStatus(false);
              setCodigo("");
              setFormSuccess({
                status: false,
                mensaje: "",
              });
              handleResetInputs();
            }, 4000);
          }
        } else {
          setFormError({
            status: true,
            mensaje: respuesta.mensaje,
          });
          setButtonStatus(false);
          handleResetInputs();
        }
      });
  };

  return (
    <Stack
      direction="column"
      bgcolor="cards.gray"
      paddingY="0.8rem"
      paddingX="1.2rem"
      marginY={3}
      borderRadius={1}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize="1.3rem" fontStyle="bold" fontWeight="600">
          ¿Tiene un cupón de compra?
        </Typography>
        <FormControl>
          <Stack direction="row" gap={1}>
            <TextField
              value={codigo}
              color="secondary"
              placeholder="Ingrese su cupón"
              size="small"
              inputProps={{
                style: {
                  fontSize: "1.2rem",
                  backgroundColor: "white",
                  width: "250px",
                },
              }}
              name="codigo"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                height: "100%",
                paddingX: "25px",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
              disabled={buttonStatus ? true : false}
              disableElevation
              onClick={handleSubmit}
            >
              Aplicar
            </Button>
          </Stack>
        </FormControl>
      </Stack>
      {formError.status ? (
        <Stack
          width="auto"
          bgcolor="#ffcdd2"
          borderRadius={0.5}
          border="1px solid #d32f2f"
          padding={1}
          marginTop={1}
        >
          <Typography fontSize="1.2rem" color="#b71c1c">
            {formError.mensaje}
          </Typography>
        </Stack>
      ) : (
        ""
      )}
      {formSuccess.status ? (
        <Stack
          width="auto"
          bgcolor="#E9FBF0"
          borderRadius={0.5}
          border="1px solid #9AEFBC"
          padding={1}
          marginTop={1}
        >
          <Typography fontSize="1.2rem" color="#0F5C2E">
            {formSuccess.mensaje}
          </Typography>
        </Stack>
      ) : (
        ""
      )}
    </Stack>
  );
}

export default FormCuponCompra;
