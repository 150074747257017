import React from "react";
import { IconButton, Stack, Typography, Grid } from "@mui/material";
import { Cancel, ConfirmationNumberOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { eliminarCodigoCanjeadoVenta } from "../../actions/fidelizacionActions";

function PromocionCarritoCard({ descuento }) {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      border="1px solid #dee2e6"
      bgcolor="white"
      padding="0.8rem"
      borderRadius="0.3rem"
    >
      <Grid item xs={8} justifyItems="center">
        <Stack
          direction="row"
          spacing="0.5rem"
          paddingLeft="8px"
          alignItems="center"
        >
          <ConfirmationNumberOutlined />
          <Stack direction="column">
            <Typography
              fontSize="1.1rem"
              fontWeight="700"
              textTransform="uppercase"
            >
              Descuento
            </Typography>
            <Typography fontSize="1.1rem">
              Motivo: {descuento.motivo}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Typography
          fontSize="1.2rem"
          fontWeight="700"
          textTransform="uppercase"
        >
          ${descuento.precioDescuento}
        </Typography>
      </Grid>
      <Grid item xs={1} textAlign="center">
        <IconButton
          sx={{
            padding: 0,
          }}
          color="warning"
          onClick={() => dispatch(eliminarCodigoCanjeadoVenta(descuento))}
        >
          <Cancel fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default PromocionCarritoCard;
