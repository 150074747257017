export const validarPromocionProducto = (
  producto_id,
  promocion_id,
  setFormError
) => {
  if (promocion_id === null) return true;
  else if (promocion_id === producto_id) return true;
  else {
    setFormError({
      status: true,
      mensaje: "El código no corresponde a este tipo de producto",
    });
    return false;
  }
};

export const validarPromocionRubro = (categorias, rubro_id, setFormError) => {
  if (rubro_id === null) return true;
  else if (rubro_id !== null) {
    let existeRubro = categorias.some(
      (cat) => Number(cat.id) === Number(rubro_id)
    );
    if (existeRubro) return true;
    else {
      setFormError({
        status: true,
        mensaje: "El código no corresponde a este rubro",
      });
      return false;
    }
  }
};

export const validarPromocionCombo = (item_id, combo_id, setFormError) => {
  if (combo_id === null) {
    return true;
  } else {
    if (item_id === combo_id) {
      return true;
    } else {
      setFormError({
        status: true,
        mensaje: "El código no corresponde a este tipo de producto",
      });
      return false;
    }
  }
};
