import {
  CARGAR_DATOS_FUNCION,
  BORRAR_DATOS_FUNCION,
  RESET_REDUX_STATE,
} from "../actionTypes/actionTypes";

const cargarDatosFuncion = (cine, funcion) => {
  return {
    type: CARGAR_DATOS_FUNCION,
    payload: {
      cine,
      funcion,
    },
  };
};

const borrarDatosFuncion = () => {
  return {
    type: BORRAR_DATOS_FUNCION,
  };
};

const funcionResetReduxState = () => {
  return {
    type: RESET_REDUX_STATE,
  };
};

export { cargarDatosFuncion, borrarDatosFuncion, funcionResetReduxState };
