import { Box, Grid, Typography, Stack, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TarifaCard from "./TarifaCard";

const TarifasContainer = ({ state }) => {
  const dataPelicula = useSelector((state) => state.DatosPelicula);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTarifas, setDataTarifas] = useState(null);
  const [totalDisponibles, setTotalDisponibles] = useState(0);
  const [cantidadDisponibles, setCantidadDisponibles] = useState(0);
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );

  useEffect(() => {
    if (
      state.datosTarifa.length > 0 &&
      dataPelicula.datos.pelicula &&
      isLoading === true
    ) {
      setDataTarifas(state.datosTarifa);
      setTotalDisponibles(
        Number(dataPelicula.datos.pelicula.entradasDisponibles)
      );
      setIsLoading(false);
    }
  }, [state, isLoading, dataPelicula]);

  useEffect(() => {
    // let cantidadPorTarifa = {};
    // dataTarifas !== null &&
    //   dataTarifas.forEach((tarifa) => {
    //     if (tarifa.id !== undefined) {
    //       if (cantidadPorTarifa[tarifa.id] === undefined) {
    //         cantidadPorTarifa[tarifa.id] = Number(tarifa.tot);
    //       }
    //     } else {
    //       cantidadPorTarifa = Number(totalDisponibles);
    //     }
    //   });
    setCantidadDisponibles(totalDisponibles);
  }, [dataTarifas, totalDisponibles]);

  if (isLoading) {
    return (
      <Box
        marginTop={1}
        sx={{
          maxWidth: "1024px",
          height: "auto",
          marginX: "auto",
          marginBottom: "4rem",
          padding: "0",
        }}
      >
        <Stack alignItems="center" margin="auto" marginTop={15}>
          <CircularProgress color="secondary" />
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box
        marginTop={1}
        sx={{
          maxWidth: "1024px",
          height: "auto",
          marginX: "auto",
          padding: "0",
        }}
        overflow="auto"
        maxHeight="480px"
      >
        <Grid container padding="1rem" width="100%">
          <Grid item xs={4}>
            <Typography fontSize="1.2rem">Entradas</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign="center" fontSize="1.2rem">
              Disponibles
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {datosComplejo.tasaConversion ? (
              <Typography textAlign="center" fontSize="1.2rem">
                Precio unitario (Bs.)
              </Typography>
            ) : (
              <Typography textAlign="center" fontSize="1.2rem">
                Precio unitario
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography></Typography>
          </Grid>
        </Grid>
        {dataTarifas.map((tarifa, index) => {
          if (tarifa.esPromocional === "0") {
            return (
              <TarifaCard
                tarifa={tarifa}
                index={index}
                key={tarifa.idKey}
                cantidadDisponibles={cantidadDisponibles}
                setCantidadDisponibles={setCantidadDisponibles}
              />
            );
          } else {
            return "";
          }
        })}
        {dataTarifas.find((item) => item.esPromocional === "1") ? (
          <>
            <Grid container padding="1rem" width="100%" margin="1rem 0 0">
              <Grid item xs={12}>
                <Typography fontSize="1.2rem">
                  Entradas promocionales
                </Typography>
              </Grid>
            </Grid>
            {dataTarifas.map((tarifa, index) => {
              if (tarifa.esPromocional === "1") {
                return (
                  <TarifaCard
                    tarifa={tarifa}
                    index={index}
                    key={tarifa.idKey}
                    cantidadDisponibles={cantidadDisponibles}
                    setCantidadDisponibles={setCantidadDisponibles}
                  />
                );
              } else {
                return "";
              }
            })}
          </>
        ) : (
          ""
        )}
      </Box>
    );
  }
};

export default TarifasContainer;
