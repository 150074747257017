import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatusPayment,
  postVentasTemporales,
  setDataToSend,
} from "../../actions/paymentActions";
import { LoadingButton } from "@mui/lab";
import ModalSuccess from "../Carrito/ModalSuccess";
import ModalError from "../Carrito/ModalError";

function PaymentCash() {
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.DatosPayment.dataToSend);
  const methodSelected = useSelector(
    (state) => state.DatosPayment.methodSelected
  );
  const statusPaymentCash = useSelector(
    (state) => state.DatosPayment.statusPaymentCash
  );
  const dataCandy = useSelector((state) => state.DatosCarrito.candy);
  const dataFuncion = useSelector((state) => state.DatosFuncion);
  const datosCandy = useSelector((state) => state.DatosCandy.datosCandy);
  const dataUsuario = useSelector((state) => state.DatosCarrito.dataUsuario);
  const dataTarifas = useSelector((state) => state.DatosCarrito.tarifas);
  const cuponesVenta = useSelector(
    (state) => state.DatosCarrito.codigosCanjeadosVenta
  );
  const descuentoVenta = useSelector(
    (state) => state.DatosCarrito.descuentoVenta
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      setDataToSend(
        cuponesVenta,
        descuentoVenta,
        dataUsuario,
        datosCandy,
        dataCandy,
        dataFuncion,
        dataTarifas,
        true
      )
    );
    dispatch(clearStatusPayment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [statusPaymentCash]);

  return (
    <Box>
      <Grid container marginTop="4rem" margin={0}>
        <Grid item xs={12} alignContent={"center"} textAlign={"center"}>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            loading={isLoading}
            sx={{
              padding: "1.5rem",
              fontSize: "1.4rem",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            onClick={() => {
              dispatch(postVentasTemporales(dataToSend, methodSelected));
              setIsLoading(true);
            }}
          >
            Confirmar compra
          </LoadingButton>
        </Grid>
      </Grid>
      {statusPaymentCash !== undefined ? (
        <>
          {statusPaymentCash !== null &&
          statusPaymentCash.status === "approved" ? (
            <ModalSuccess
              totalAmount={statusPaymentCash.data.cinema.transactionAmount}
              idOperation={statusPaymentCash.data.cinema.paymentCode}
            />
          ) : (
            ""
          )}
          {statusPaymentCash !== null &&
          statusPaymentCash.status === "rejected" ? (
            <ModalError callback={setIsLoading} error="Ha ocurrido un error" />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </Box>
  );
}

export default PaymentCash;
