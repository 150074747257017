import {
  Dialog,
  DialogContentText,
  DialogTitle,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import { useState } from "react";
import FormEntidad from "./FormEntidad";
import { useLocation } from "react-router-dom";

function DialogPromocionales({
  open,
  setOpen,
  tarifa,
  handleAgregarTarifas,
  showForm,
  setShowForm,
}) {
  const [entidad, setEntidad] = useState(null);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const cine = urlParams.get("cid");
  const funcion = urlParams.get("fid");

  const handleChange = (evt) => {
    if (evt.target.value !== null) {
      const entidadSelect = tarifa.entidades.find(
        (e) => e.id === evt.target.value
      );
      setEntidad(entidadSelect);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEntidad(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Stack
        direction="column"
        justifyContent="center"
        textAlign="center"
        sx={
          showForm
            ? {
                paddingX: "2rem",
                paddingBottom: "2rem",
              }
            : null
        }
      >
        {showForm ? (
          <>
            <DialogTitle
              sx={{
                fontSize: "2rem",
                fontWeight: "700",
                margin: "auto",
              }}
            >
              <Typography
                id="title-tarifa"
                sx={{
                  fontSize: "2rem",
                  fontWeight: "700",
                  margin: "auto",
                }}
              >
                Formulario {tarifa.descripcion}
              </Typography>
            </DialogTitle>

            <DialogContentText
              paddingBottom="2rem"
              sx={{
                fontSize: "1.2rem",
                margin: "auto",
              }}
            >
              Por favor, complete los datos para validar la promoci&oacute;n.
            </DialogContentText>
            <Stack fullWidth marginX={3}>
              <FormControl>
                <InputLabel
                  style={{
                    fontSize: "1.4rem",
                  }}
                  variant="standard"
                  id="entidadSelect"
                >
                  Entidad
                </InputLabel>
                <Select
                  id="select-entity"
                  style={{
                    fontSize: "1.6rem",
                    textAlign: "left",
                  }}
                  label="Seleccione una entidad"
                  labelId="entidadSelect"
                  name="entidadSelect"
                  variant="standard"
                  onChange={handleChange}
                  defaultValue=""
                >
                  {tarifa.entidades?.map((e) => {
                    return (
                      <MenuItem
                        id={`select-${e.idPromocion}`}
                        style={{
                          fontSize: "1.4rem",
                        }}
                        key={e.idPromocion}
                        value={e.id}
                      >
                        {e.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            {entidad !== null ? (
              <FormEntidad
                key={entidad.idPromocion}
                entidad={entidad}
                handleAgregarTarifas={handleAgregarTarifas}
                setEntidad={setEntidad}
                tarifa={tarifa}
                cine={cine}
                funcion={funcion}
                setOpen={setOpen}
                setShowForm={setShowForm}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Stack direction="column" alignItems="center" fontSize="12rem">
              <Box
                bgcolor="success.light"
                textAlign="center"
                color="white"
                width="100%"
              >
                <Stack
                  margin={3}
                  direction="column"
                  alignItems="center"
                  spacing={2}
                >
                  <CheckCircleOutlineTwoToneIcon sx={{ fontSize: "10rem" }} />
                  <Typography fontSize="1.8rem">
                    ¡El canje fue un éxito!
                  </Typography>
                </Stack>
              </Box>
              <DialogTitle
                sx={{
                  fontSize: "2rem",
                  fontWeight: "500",
                }}
              >
                Cupon canjeado correctamente
              </DialogTitle>
            </Stack>
          </>
        )}
      </Stack>
    </Dialog>
  );
}

export default DialogPromocionales;
