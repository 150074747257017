import React, { useEffect } from "react";
import Tarifas from "./pages/Tarifas";
import { Route, Routes, useLocation } from "react-router-dom";
import Butacas from "./pages/Butacas";
import Candy from "./pages/Candy";
import MetodosPago from "./pages/MetodosPago";
import { useDispatch, useSelector } from "react-redux";
import { cargarDatosFuncion } from "./actions/funcionActions";
import Fidelizacion from "./pages/Fidelizacion";
import Payment from "./pages/Payment";
import { connectWithPrinter } from "./utils/impresion";
import Home from "./pages/Home";
import {
  cargarConfiguracionComplejo,
  cargarDatosEmpresa,
} from "./actions/complejoActions";
import { TimerProvider } from "./Components/HeadlineTimer/Timer";
import Canje from "./pages/Canje";
import apihost from "./config/apiHost.json";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const cine = urlParams.get("cid");
  const funcion = urlParams.get("fid");
  const deviceId = urlParams.get("did");
  const url_api_complejo = useSelector(
    (state) => state.DatosComplejo.url_api_complejo
  );
  const datosEmpresa = useSelector((state) => state.DatosComplejo.datosEmpresa);
  console.log(apihost.REACT_APP_URL_CINEXO);

  useEffect(() => {
    connectWithPrinter();
  }, []);

  useEffect(() => {
    dispatch(cargarDatosFuncion(cine, funcion));
    if (deviceId && url_api_complejo !== "" && !datosEmpresa) {
      dispatch(cargarDatosEmpresa(cine, deviceId));
    }
  }, [deviceId, url_api_complejo, datosEmpresa, dispatch, cine, funcion]);

  useEffect(() => {
    if (cine && url_api_complejo === "") {
      dispatch(cargarConfiguracionComplejo(cine));
    }
  }, [cine, url_api_complejo, dispatch]);

  return (
    <TimerProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="tarifas" element={<Tarifas />} />
        <Route path="butacas" element={<Butacas />} />
        <Route path="candy" element={<Candy />} />
        <Route path="fidelizacion" element={<Fidelizacion />} />
        <Route path="payment" element={<Payment />} />
        <Route path="payment-methods" element={<MetodosPago />} />
        <Route path="canje" element={<Canje />} />
      </Routes>
    </TimerProvider>
  );
}

export default App;
