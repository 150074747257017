import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import CandyCard from "./CandyCard";
import SelectCandy from "./SelectCandy";
import { useDispatch, useSelector } from "react-redux";
import {
  agregarCategorias,
  borrarCategorias,
  setUnidadNegocio,
} from "../../actions/candyActions";

function CandyContainer() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [empresa, setEmpresa] = useState("");
  const [listaCategorias, setListaCategorias] = useState([]);
  const [categoria, setCategoria] = useState("");
  const [productos, setProductos] = useState([]);
  const datosCandy = useSelector((state) => state.DatosCandy.datosCandy);

  useEffect(() => {
    if (datosCandy !== undefined && empresa === "") {
      const primerEmpresa = datosCandy?.unidades_negocios?.at(0);
      primerEmpresa !== undefined
        ? setEmpresa(primerEmpresa?.nombre_empresa)
        : setEmpresa("");
    }
    if (empresa !== "") {
      setCategoria("TODOS");
    }
  }, [datosCandy, empresa]);

  useEffect(() => {
    if (empresa !== "") {
      if (datosCandy && datosCandy.unidades_negocios) {
        const empresaSeleccionada = datosCandy.unidades_negocios.find(
          (e) => e.nombre_empresa === empresa
        );
        dispatch(setUnidadNegocio(empresaSeleccionada.id));
        setListaCategorias(empresaSeleccionada.categorias);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCandy, empresa]);

  useEffect(() => {
    if (categoria !== "" && categoria !== "TODOS") {
      dispatch(borrarCategorias());
      const categoriaSeleccionada = listaCategorias.find(
        (e) => e.nombre === categoria
      );
      dispatch(
        agregarCategorias(
          categoriaSeleccionada.nombre.toLowerCase(),
          categoriaSeleccionada.id
        )
      );
      const items = categoriaSeleccionada?.items;
      setProductos(items);
      setIsLoading(false);
    }
    if (categoria === "TODOS") {
      let listaProductos = [];
      dispatch(borrarCategorias());
      listaCategorias.forEach((cat) => {
        dispatch(agregarCategorias(cat.nombre.toLowerCase(), cat.id));
        cat.items.forEach((item) => {
          listaProductos.push(item);
        });
      });
      setProductos(listaProductos);
      setIsLoading(false);
    }
  }, [categoria, listaCategorias, dispatch]);

  if (isLoading) {
    return (
      <Box
        marginTop={1}
        sx={{
          maxWidth: "1024px",
          height: "auto",
          marginX: "auto",
          marginBottom: "4rem",
          padding: "0",
        }}
      >
        <Stack alignItems="center" margin="auto" marginTop={15}>
          <CircularProgress color="secondary" />
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box
        marginTop={1}
        sx={{
          maxWidth: "1024px",
          height: "auto",
          marginX: "auto",
          padding: "0",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 2, marginBottom: 1 }}
        >
          <SelectCandy
            label="Empresa"
            data={datosCandy.unidades_negocios}
            estado={empresa}
            setEstado={setEmpresa}
          />
          <SelectCandy
            label="Categoría"
            data={listaCategorias}
            estado={categoria}
            setEstado={setCategoria}
          />
        </Stack>
        <Box marginX="auto" overflow="auto" maxHeight="550px">
          {productos &&
            productos.map((producto, index) => {
              let bgColor = "cards.gray";
              if (index % 2 === 0) {
                bgColor = "white";
              }
              return (
                <CandyCard
                  key={`${producto.id}-${producto.nombre}`}
                  item={producto}
                  bgColor={bgColor}
                />
              );
            })}
        </Box>
      </Box>
    );
  }
}
export default CandyContainer;
