import { Divider, Stack, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import VideocamIcon from "@mui/icons-material/Videocam";
import { cargarDatosPelicula } from "../../../actions/peliculaAction";
import apihost from "../../../config/apiHost.json";

function PeliculaSimple() {
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const pelicula = useSelector((state) => state.DatosPelicula.datos.pelicula);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading && datosFuncion.length < 1) {
      dispatch(
        cargarDatosPelicula(
          apihost.REACT_APP_URL_CINEXO,
          datosFuncion.cine_id,
          datosFuncion.funcion_id
        )
      );
    }
    setIsLoading(false);
  }, [dispatch, isLoading, datosFuncion]);

  if (isLoading) {
    return (
      <Stack alignItems="center" margin="auto" sx={{ marginY: 3 }}>
        <CircularProgress color="secondary" />
      </Stack>
    );
  } else {
    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        marginBottom="1.5rem"
      >
        <Stack
          bgcolor="primary.main"
          padding="0.95rem 1.6rem"
          borderRadius="0.3rem"
        >
          <VideocamIcon
            sx={{
              fontSize: "3.3rem",
              color: "white",
              padding: "0",
              margin: "0",
            }}
          />
        </Stack>
        <Stack
          direction="column"
          bgcolor="primary.light"
          width="100%"
          padding="0.8rem"
          borderRadius="0.3rem"
        >
          <Typography fontSize="1.2rem">
            <b>Película:</b> {pelicula.pelicula}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            divider={
              <Divider orientation="vertical" flexItem variant="middle" />
            }
          >
            <Typography fontSize="1.2rem">
              <b>Fecha y hora:</b> {pelicula.fecha}{" "}
            </Typography>

            <Typography fontSize="1.2rem">
              <b>Formato:</b> {pelicula.is3d}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

export default PeliculaSimple;
