import { butacasResetReduxState } from "../actions/butacasActions";
import { carritoResetReduxState } from "../actions/carritoActions";
import { complejoResetReduxState } from "../actions/complejoActions";
import { fidelizacionResetReduxState } from "../actions/fidelizacionActions";
import { funcionResetReduxState } from "../actions/funcionActions";
import { paymentResetReduxState } from "../actions/paymentActions";
import { peliculaResetReduxState } from "../actions/peliculaAction";

export const clearAllReduxState = (dispatch) => {
  dispatch(butacasResetReduxState());
  dispatch(fidelizacionResetReduxState());
  dispatch(carritoResetReduxState());
  dispatch(peliculaResetReduxState());
  dispatch(funcionResetReduxState());
  dispatch(paymentResetReduxState());
  dispatch(complejoResetReduxState());
};
