import {
  Grid,
  FormControl,
  Stack,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  agregarDataUsuario,
  borrarDataUsuario,
} from "../../actions/fidelizacionActions";
import apihost from "../../config/apiHost.json";

function FormUsuario({
  userData,
  setUserData,
  disabledInput,
  setDisabledInput,
}) {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState({
    status: false,
    mensaje: "",
  });

  const [emailError, setEmailError] = useState({
    status: false,
    mensaje: "",
  });

  const [formSuccess, setFormSuccess] = useState({
    status: false,
    mensaje: "",
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setFormError({
      status: false,
      mensaje: "",
    });
    setEmailError({
      status: false,
      mensaje: "",
    });
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const emailErrorHandler = () => {
    const email = userData.email;
    if (/\S+@\S+\.\S+/.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (emailErrorHandler()) {
      setEmailError({
        status: true,
        mensaje: "Formato de email inválido",
      });
    } else if (userData.nombre === "" || userData.nombre === "") {
      setFormError({ status: true, mensaje: "Campos incompletos" });
    } else {
      await fetch(`${apihost.REACT_APP_URL_CINEXO}clientes/nuevoEmail`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(userData),
      })
        .then((res) => res.json())
        .then((respuesta) => {
          if (!respuesta.error) {
            dispatch(agregarDataUsuario(userData));
            console.log(respuesta);
            setFormSuccess({
              status: true,
              mensaje: "Cliente creado con éxito",
            });
            setDisabledInput(true);
            setTimeout(() => {
              setFormSuccess({
                status: false,
                mensaje: "",
              });
            }, 4000);
          } else {
            setFormError({
              status: true,
              mensaje: respuesta.error.message,
            });
            setTimeout(() => {
              setFormError({
                status: false,
                mensaje: "",
              });
            }, 2000);
          }
        });
    }
  };

  const handleClear = () => {
    setUserData({
      nombre: "",
      apellido: "",
      email: "",
      documento: "",
    });
    setFormSuccess({
      status: false,
      mensaje: "",
    });
    setFormError({
      status: false,
      mensaje: "",
    });
    setEmailError({
      status: false,
      mensaje: "",
    });
    dispatch(borrarDataUsuario());
    setDisabledInput(false);
  };

  return (
    <Stack direction="column" gap={1}>
      {formError.status ? (
        <Stack
          width="auto"
          bgcolor="#ffcdd2"
          borderRadius={0.5}
          border="1px solid #d32f2f"
          padding={0.5}
        >
          <Typography fontSize="1.2rem" color="#b71c1c">
            {formError.mensaje}
          </Typography>
        </Stack>
      ) : (
        ""
      )}
      {formSuccess.status ? (
        <Stack
          width="auto"
          bgcolor="#E9FBF0"
          borderRadius={0.5}
          border="1px solid #9AEFBC"
          padding={0.5}
        >
          <Typography fontSize="1.2rem" color="#0F5C2E">
            {formSuccess.mensaje}
          </Typography>
        </Stack>
      ) : (
        ""
      )}
      <FormControl fullWidth>
        <Grid container width="auto" spacing={1} padding={0}>
          <Grid item xs={6}>
            <Stack direction="column" gap={0.5}>
              <Typography fontSize="1.3rem">Nombre</Typography>
              <TextField
                color="secondary"
                placeholder="Mariano Andrés"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "1.3rem",
                    backgroundColor: "white",
                  },
                }}
                name="nombre"
                value={userData.nombre}
                onChange={handleInputChange}
                disabled={disabledInput}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="column" gap={0.5}>
              <Typography fontSize="1.3rem">Apellido</Typography>
              <TextField
                color="secondary"
                placeholder="Bruno"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "1.3rem",
                    backgroundColor: "white",
                  },
                }}
                name="apellido"
                value={userData.apellido}
                onChange={handleInputChange}
                disabled={disabledInput}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} marginTop={2}>
            <Stack direction="column" gap={0.5}>
              <Typography fontSize="1.3rem">Correo Electrónico</Typography>
              <TextField
                error={emailError.status ? true : false}
                helperText={emailError.status ? emailError.mensaje : " "}
                color="secondary"
                placeholder="marianobruno@gmail.com"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "1.3rem",
                    backgroundColor: "white",
                  },
                }}
                name="email"
                type="email"
                value={userData.email}
                onChange={handleInputChange}
                disabled={disabledInput}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "1rem",
                    marginX: "0px",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} marginTop={2}>
            <Stack direction="column" gap={0.5}>
              <Typography fontSize="1.3rem">DNI</Typography>
              <TextField
                color="secondary"
                placeholder="12345678"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "1.3rem",
                    backgroundColor: "white",
                  },
                  inputMode: "numeric",
                }}
                name="documento"
                value={userData.documento}
                onChange={handleInputChange}
                disabled={disabledInput}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </FormControl>

      <Stack direction="row" alignSelf="flex-end" spacing={1} marginTop={0}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            height: "100%",
            paddingX: "25px",
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
          disableElevation
          onClick={handleClear}
        >
          Borrar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: "100%",
            paddingX: "25px",
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
          disableElevation
          onClick={handleSubmit}
        >
          Guardar datos
        </Button>
      </Stack>
    </Stack>
  );
}

export default FormUsuario;
