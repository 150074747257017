import { Box } from "@mui/material";
import { useState } from "react";
import BuscadorUsuario from "./BuscadorUsuario";
import FormCuponCompra from "./FormCuponCompra";
import FormUsuario from "./FormUsuario";
import { useSelector } from "react-redux";

function FidelizacionContainer() {
  const complejo_id = useSelector((state) => state.DatosFuncion.cine_id);
  const [userData, setUserData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    documento: "",
    complejo_id: complejo_id,
  });
  const [disabledInput, setDisabledInput] = useState(false);

  return (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        marginBottom: "4rem",
        padding: "0",
      }}
    >
      <BuscadorUsuario
        setUserData={setUserData}
        setDisabledInput={setDisabledInput}
      />
      <FormUsuario
        userData={userData}
        setUserData={setUserData}
        disabledInput={disabledInput}
        setDisabledInput={setDisabledInput}
      />
      <FormCuponCompra />
    </Box>
  );
}

export default FidelizacionContainer;
