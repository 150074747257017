import { Grid } from "@mui/material";
import NumpadButton from "./NumpadButton";

export default function Numpad({ handleClick }) {
  return (
    <Grid
      container
      bgcolor="cards.gray"
      width="35%"
      alignContent="center"
      justifyContent="center"
      paddingRight={1}
      paddingBottom={1}
      spacing={1}
      borderRadius="6px"
    >
      {items.map((item) => {
        return (
          <Grid item xs={item.xs} key={item.value}>
            <NumpadButton handleClick={handleClick}>{item.value}</NumpadButton>
          </Grid>
        );
      })}
    </Grid>
  );
}

const items = [
  {
    value: "1",
    xs: 4,
  },
  {
    value: "2",
    xs: 4,
  },
  {
    value: "3",
    xs: 4,
  },
  {
    value: "4",
    xs: 4,
  },
  {
    value: "5",
    xs: 4,
  },
  {
    value: "6",
    xs: 4,
  },
  {
    value: "7",
    xs: 4,
  },
  {
    value: "8",
    xs: 4,
  },
  {
    value: "9",
    xs: 4,
  },
  {
    value: "0",
    xs: 8,
  },
  {
    value: "Borrar",
    xs: 4,
  },
];
