import {
  CARGAR_TARIFAS,
  ACTUALIZAR_TOTAL_ENTRADAS,
  AGREGAR_TARIFAS_CARRITO,
  ELIMINAR_TARIFAS_CARRITO,
  LIMPIAR_CARRITO,
  AGREGAR_CODIGO_TARIFAS,
  TARIFAS_LIMPIAR_CUPONES,
} from "../actionTypes/actionTypes";

const cargarTarifas = (url, cine, funcion) => {
  return async function (dispatch) {
    await fetch(
      `${url}funcion/obtenerDatosPorId?complejo_id=${cine}&funcion_id=${funcion}`
    )
      .then((response) => response.json())
      .then((respuesta) => {
        return dispatch({
          type: CARGAR_TARIFAS,
          payload: {
            tarifas: respuesta.tarifas,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const agregarTarifasCarrito = (tarifa, cantidad, promotion) => {
  let tarifaAdd = { ...tarifa };

  if (promotion !== "") {
    promotion = JSON.parse(promotion);
    if (
      promotion.tipoDescuento === "porcentaje_tarifa" &&
      promotion.descuento !== null &&
      Number(tarifa.precio) !== 0
    ) {
      tarifaAdd.precio =
        Number(tarifa.precio) -
        Number(tarifa.precio) * (Number(promotion.descuento) / 100);
    } else if (
      promotion.tipoDescuento === "moneda_tarifa" &&
      promotion.descuento !== null &&
      Number(tarifa.precio) !== 0
    ) {
      tarifaAdd.precio = Number(tarifa.precio) - Number(promotion.descuento);
    }
  }

  if (cantidad > 1) {
    tarifaAdd.precio = Number(tarifa.precio);
  }

  return {
    type: AGREGAR_TARIFAS_CARRITO,
    payload: {
      tarifa: {
        ...tarifaAdd,
        idCarrito: Math.random().toString(30).substring(2),
        butaca: "",
        promotion,
      },
      cantidad: cantidad,
    },
  };
};

const eliminarTarifasCarrito = (id, cantidad) => {
  return {
    type: ELIMINAR_TARIFAS_CARRITO,
    payload: {
      idTarifa: id,
      cantidad: cantidad,
    },
  };
};

const actualizarTotal = (subtotal) => {
  return {
    type: ACTUALIZAR_TOTAL_ENTRADAS,
    payload: {
      amount: subtotal,
    },
  };
};

const agregarCodigosCanjeadosTarifas = (data) => {
  return {
    type: AGREGAR_CODIGO_TARIFAS,
    payload: {
      data,
    },
  };
};

const limpiarCarrito = () => {
  return {
    type: LIMPIAR_CARRITO,
  };
};

const limpiarCupones = () => {
  return {
    type: TARIFAS_LIMPIAR_CUPONES,
  };
};

export {
  cargarTarifas,
  agregarTarifasCarrito,
  eliminarTarifasCarrito,
  actualizarTotal,
  limpiarCarrito,
  agregarCodigosCanjeadosTarifas,
  limpiarCupones,
};
