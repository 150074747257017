import { Grid, Typography, Stack, Button, ButtonGroup } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemCarritoModal,
  addSubtotal,
  clearPref,
  decrementItem,
  incrementItem,
  initCarritoModal,
  lockSelectItem,
  removerItemCarritoModal,
  resetPreferencias,
  resetPromocionesCandyModal,
  setSubtotalCandyModal,
} from "../../../actions/candyActions";

function ItemCard({ item, bgColor = "white" }) {
  const counter = useSelector((state) => state.DatosCandy.countItem);
  const itemCarrito = useSelector((state) => state.DatosCandy.item);
  const lock = useSelector((state) => state.DatosCandy.lockSelectItem);
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  const prodComboDinamico = useSelector(
    (state) => state.DatosCandy.prodComboDinamico
  );
  const [message, setMessage] = useState("Confirmar");
  const dispatch = useDispatch();

  useEffect(() => {
    if (prodComboDinamico && prodComboDinamico.length > 0) {
      dispatch(initCarritoModal(item, prodComboDinamico));
    } else {
      dispatch(initCarritoModal(item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lock) {
      setMessage("Modificar");
    } else {
      setMessage("Confirmar");
    }
  }, [lock]);

  const handleActive = () => {
    if (counter === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleLockSelectItem = () => {
    dispatch(lockSelectItem());
    if (lock) {
      dispatch(resetPreferencias(true));
      dispatch(clearPref());
      dispatch(resetPromocionesCandyModal(true));
    } else {
      dispatch(resetPreferencias(false));
      dispatch(resetPromocionesCandyModal(false));
    }
  };

  const handleAgregarItem = () => {
    dispatch(incrementItem());
    dispatch(resetPreferencias(false));
    dispatch(addItemCarritoModal(itemCarrito, counter));
    dispatch(addSubtotal(item.precio));
  };

  const handleEliminarItem = () => {
    dispatch(decrementItem());
    dispatch(resetPreferencias(true));
    dispatch(removerItemCarritoModal(itemCarrito, counter));
    dispatch(clearPref());
    dispatch(setSubtotalCandyModal(item.precio));
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        paddingY: "1rem",
        paddingX: "0.5rem",
        border: "1px solid #dee2e6",
        bgcolor: bgColor,
      }}
    >
      <Grid item xs={6} md={7}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            fontSize="1.2rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            {item.nombre}
          </Typography>
          <>
            {datosComplejo.tasaConversion ? (
              <Typography
                fontSize="1.3rem"
                textTransform="uppercase"
                fontWeight="500"
              >
                Bs. {item.precio}
              </Typography>
            ) : (
              <Typography
                fontSize="1.3rem"
                textTransform="uppercase"
                fontWeight="500"
              >
                ${item.precio}
              </Typography>
            )}
          </>
        </Stack>
      </Grid>
      <Grid item xs={6} md={5}>
        <Stack direction="row" justifyContent="space-evenly">
          <ButtonGroup>
            <Button
              disabled={lock}
              variant="contained"
              color="secondary"
              border="1px transparent"
              size="small"
              margin={0}
              disableElevation
              sx={{ padding: 0, width: "auto" }}
              onClick={() => handleEliminarItem()}
            >
              <Typography
                fontSize="1.6rem"
                fontWeight="500"
                textTransform="uppercase"
                paddingX="-1rem"
              >
                -
              </Typography>
            </Button>
            <Stack
              component="span"
              borderRadius={0}
              border="1px solid #ced4da"
              width="3rem"
              bgcolor="white"
            >
              <Typography fontSize="1rem" textAlign="center" margin="auto">
                {counter}
              </Typography>
            </Stack>
            <Button
              id={`add-${item.id}`}
              disabled={lock}
              variant="contained"
              color="secondary"
              border="1px transparent"
              size="small"
              margin={0}
              disableElevation
              sx={{ padding: 0, width: "auto" }}
              onClick={() => {
                handleAgregarItem();
              }}
            >
              <Typography
                fontSize="1.6rem"
                fontWeight="500"
                textTransform="uppercase"
              >
                +
              </Typography>
            </Button>
          </ButtonGroup>
          <Button
            id="Confirmar"
            disabled={handleActive()}
            variant="contained"
            color="secondary"
            border="1px transparent"
            size="small"
            disableElevation
            onClick={() => handleLockSelectItem()}
          >
            <Typography fontSize="1.4rem" fontWeight="500" textTransform="none">
              {message}
            </Typography>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ItemCard;
