import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Pelicula from "../Components/Entradas/Pelicula/Pelicula";
import TarifasContainer from "../Components/Entradas/Tarifas/TarifasContainer";
import Carrito from "../Components/Carrito/Carrito";
import { cargarTarifas } from "../actions/tarifasAction";
import { Box, Grid } from "@mui/material";
import Headline from "../Components/HeadlineTimer/Headline";
import useCurrentWindow from "../hooks/useCurrentWindow";
import { setNextPage } from "../actions/carritoActions";
import apihost from "../config/apiHost.json";

function Tarifas() {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useCurrentWindow();
  const titleHeadline = "Entradas disponibles";
  const datosTarifa = useSelector((state) => state.DatosTarifa);
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const datosCarrito = useSelector((state) => state.DatosCarrito.tarifas);
  const [isLoading, setIsLoading] = useState(true);
  const nextPage = useSelector((state) => state.DatosCarrito.nextPage);
  const [isVertical, setIsVertical] = useState(false);
  const [disabledContinue, setDisabledContinue] = useState(true);

  const datosPelicula = useSelector((state) => state.DatosPelicula.datos);

  useEffect(() => {
    if (isLoading) {
      dispatch(
        cargarTarifas(
          apihost.REACT_APP_URL_CINEXO,
          datosFuncion.cine_id,
          datosFuncion.funcion_id
        )
      );
      setIsLoading(false);
    }
  }, [dispatch, isLoading, datosFuncion]);

  useEffect(() => {
    if (datosCarrito.length > 0) {
      setDisabledContinue(false);
      if (
        datosPelicula.pelicula.vende_candy === "0" &&
        datosPelicula.pelicula.vendeNumeradas === "0"
      ) {
        dispatch(setNextPage(`/fidelizacion${location.search}`));
      } else if (datosPelicula.pelicula.vendeNumeradas === "0") {
        dispatch(setNextPage(`/candy${location.search}`));
      }
    } else {
      setDisabledContinue(true);
      dispatch(setNextPage(`/butacas${location.search}`));
    }
  }, [datosCarrito, location, dispatch]);

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  // useEffect(() => {
  //   return setIsLoading(true);
  // }, []);

  return isLoading ? (
    ""
  ) : (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "10px", md: "5px" },
      }}
    >
      <Grid container spacing="2rem">
        <Grid item xs={windowSize.width < windowSize.height ? 12 : 8}>
          <Pelicula />
          <Headline title={titleHeadline} />
          <TarifasContainer state={datosTarifa} isVertical={isVertical} />
        </Grid>
        <Grid
          item
          xs={isVertical ? 12 : 4}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <Carrito
            nextPage={nextPage}
            isVertical={isVertical}
            disabledCancel={false}
            disabledContinue={disabledContinue}
            step="tarifas"
            to={`/${location.search}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Tarifas;
