import { Box, Grid } from "@mui/material";
import Carrito from "../Components/Carrito/Carrito";
import PeliculaSimple from "../Components/Entradas/Pelicula/PeliculaSimple";
import Headline from "../Components/HeadlineTimer/Headline";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaymentMethods from "../Components/Payment-Methods/PaymentsMethods";
import useCurrentWindow from "../hooks/useCurrentWindow";
import { Link } from "react-router-dom";

function MetodosPago() {
  const location = useLocation();
  const [isVertical, setIsVertical] = useState(false);
  const titleHeading = "Seleccione el método de pago";
  const windowSize = useCurrentWindow();

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  return (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "10px", md: "5px" },
      }}
    >
      <Grid container spacing="2rem">
        <Grid item xs={windowSize.width < windowSize.height ? 12 : 8}>
          <PeliculaSimple />
          <Headline title={titleHeading} />
          <PaymentMethods />
        </Grid>
        <Grid
          item
          xs={isVertical ? 12 : 4}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <Carrito
            nextPage={`/payment${location.search}`}
            isVertical={isVertical}
            disabledCancel={false}
            step={"payment-methods"}
            to={`/fidelizacion${location.search}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MetodosPago;
