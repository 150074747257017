import React from "react";
import { Grid, Typography, Box } from "@mui/material";

function PromocionCarrito({ bgColor = "white", promocion }) {
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          padding: "0.5rem",
          border: "1px solid #dee2e6",
          bgcolor: bgColor,
        }}
      >
        <Grid item xs={9}>
          <Typography
            fontSize="1rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            Descuento
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            fontSize="1rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            -${promocion.precioDescuento}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PromocionCarrito;
