import { Stack, Typography, Grid } from "@mui/material";
import React from "react";
import { ConfirmationNumberOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

function TarifaCarritoCard({ tarifa }) {
  const datosComplejo = useSelector(
    (state) => state.DatosComplejo.datosComplejo
  );
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      border="1px solid #dee2e6"
      bgcolor="white"
      padding="0.8rem"
      borderRadius="0.3rem"
      key={tarifa.idCarrito}
    >
      <Grid item xs={7} justifyItems="center">
        <Stack
          direction="row"
          spacing="0.5rem"
          paddingLeft="8px"
          alignItems="center"
        >
          <ConfirmationNumberOutlined />
          {tarifa.esPromocional === "1" ? (
            <Stack direction="column">
              <Typography
                fontSize="1.1rem"
                fontWeight="700"
                textTransform="uppercase"
              >
                {tarifa.descripcion}
              </Typography>
              <Typography fontSize="0.8rem" fontWeight="700">
                Entrada promocional
              </Typography>
            </Stack>
          ) : (
            <Typography
              fontSize="1.2rem"
              fontWeight="700"
              textTransform="uppercase"
            >
              {tarifa.descripcion}
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Typography fontSize="1.2rem" textTransform="uppercase">
          BUT: {tarifa.butaca !== "" ? tarifa.butaca.nombre_butaca : "-"}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="center">
        {datosComplejo.tasaConversion ? (
          <Typography
            fontSize="1.2rem"
            fontWeight="700"
            textTransform="uppercase"
          >
            Bs. {tarifa.precio}
          </Typography>
        ) : (
          <Typography
            fontSize="1.2rem"
            fontWeight="700"
            textTransform="uppercase"
          >
            ${tarifa.precio}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default TarifaCarritoCard;
